<template>
    <div class="caixa">
        <div class="acoesTopo">             
            <b-button variant="primary" class="botoesAcao" v-if="((mode === 'save') || (mode === 'remove')) && !showFluxoCaixa && !show" v-b-tooltip.hover.bottomleft="'Voltar'" 
                @click="reset">
                    <i class="fa fa-arrow-left button-icons-acoes"></i>
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save' && !showFluxoCaixa && caixa.status == 'A' && !show" v-b-tooltip.hover.bottomleft="'Fechar Caixa'" 
                v-b-modal.modal-fechar-caixa>
                    <i class="fa fa-money button-icons-acoes"></i>
            </b-button> 
            <b-button variant="warning" class="botoesAcao" v-if="mode === 'save' && !showFluxoCaixa && caixa.status == 'F' && !show" v-b-tooltip.hover.bottomleft="'Reabrir Caixa'"
                v-b-modal.modal-reabrir-caixa>
                    <i class="fa fa-money button-icons-acoes"></i>
            </b-button> 
            <!-- <b-button variant="success" class="botoesAcao" v-if="!showFluxoCaixa" v-b-tooltip.hover.bottomleft="'Fluxo de Caixa'" 
                @click="showFluxoCaixa=true">
                    <i class="fa fa-line-chart button-icons-acoes"></i>
            </b-button>  -->
            <!-- <b-button variant="success" class="botoesAcao" v-if="showFluxoCaixa" v-b-tooltip.hover.bottomleft="'Voltar'" 
                @click="showFluxoCaixa=false">
                    <i class="fa fa-arrow-left button-icons-acoes"></i>
            </b-button>  -->
            <!-- <b-button variant="success" class="botoesAcao" v-if="mode === 'new' && !showFluxoCaixa" v-b-tooltip.hover.bottomleft="'Opções de Caixa'" 
                @click="opcoesCaixas">
                    <i class="fa fa-cloud-download button-icons-acoes"></i>                    
            </b-button>             -->
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new' && !showFluxoCaixa" v-b-tooltip.hover.bottomleft="'Abrir Novo Caixa'" 
                @click="novoCaixa">
                    <i class="fa fa-plus button-icons-acoes"></i>
            </b-button> 
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show && !showFluxoCaixa">            
            <b-row align-v="center">

                <b-col md="3" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-group label-for="data-inicial">
                        Usuário
                        <b-form-select id="cadastros-pesquisa" v-model="idUsuario" :options="usuarios" />  
                    </b-form-group>               
                </b-col> 

                <b-col md="2" sm="12">
                    <b-form-group label-for="data-inicial">
                        Data inicial
                        <b-form-input id="data-inicial" type="date"
                            v-model="dataIni"/>
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group label-for="data-final">
                        Data final
                        <b-form-input id="data-final" type="date"
                            v-model="dataFim"/>
                    </b-form-group>
                </b-col>

                <b-col md="3" sm="12" class="radio-consulta mt-2">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="status"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="A">Abertos</b-form-radio>
                            <b-form-radio value="F">Fechados</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>

                <b-col md="2" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="cadastros-card-fluxo" v-if="showFluxoCaixa">            
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Filial">
                                <b-form-select style="width: 200px; margin-right: 10px;" id="conta-bancaria" v-model="fluxoFilial" :options="filiais"/>                                
                            </b-input-group>
                            <b-input-group prepend="Período">
                                <b-form-input type="date" v-model="fluxoDataInicial"></b-form-input>
                                <b-form-input type="date" v-model="fluxoDataFinal"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="fluxoCaixa()" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-button-group> 
                        <b-button-group class="mx-1">                                                    
                            <b-button @click="salvarPdf('FLUXO_DE_CAIXA_MENSAL','fluxo-render-pdf')" variant="primary" >
                                <i class="fa fa-cloud-download button-icons"></i>PDF
                            </b-button>
                        </b-button-group>  
                    </b-button-toolbar>
                </b-col>    
            </b-row>
            <hr>

            <b-row align-h="center" >
                <div style="width: 775px" id="fluxo-render-pdf">
                    <b-col 
                        md="12" 
                        sm="12" 
                        v-for="(f, id) in dadosFluxoCaixa" v-bind:key="id"
                        :v-if="dadosFluxoCaixa.length > 0"
                    >
                        <b-card title="Fluxo de Caixa (Mensal)" style="border-color: #fff" v-if="f.id == 1">
                            <b-row>
                                <b-col>
                                    <h5>Período: {{fluxoDataInicialF}} até {{fluxoDataInicialF}}</h5>
                                </b-col>
                            </b-row>
                        </b-card>
                        
                        <b-card class="p-2 mb-3" text-variant="light" bg-variant="info" no-body><h4>{{f.data}}</h4></b-card>

                        <b-row>
                            <b-col md="5" sm="5">
                                <b-card title="Receitas por Numerário">
                                    <b-table
                                        bordered
                                        borderless
                                        small
                                        stacked
                                        responsive
                                        :items="f.receitasNumerarios"
                                        :fields="fluxoNumer"
                                        head-variant="light"
                                    ></b-table>
                                </b-card>
                            </b-col>

                            <b-col md="7" sm="7">
                                <Doughnut
                                    :chart-options="chartOptions"
                                    :chart-data="f.receitasNumerarios[0].chartData"
                                    chart-id="graf-rosca"
                                    :height="300"
                                />
                            </b-col>

                            
                        </b-row> 

                        <!-- RECEITAS -->
                        <b-row> 
                            <b-col md="12" sm="12"> 
                                
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.receitasPlanoContas.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Receitas</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.receitasPlanoContas.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.receitasPlanoContas.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>

                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- CUSTOS VARIÁVEIS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.custosVariaveis.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Custos variáveis</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosVariaveis.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosVariaveis.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>

                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- CUSTOS FIXOS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.custosFixos.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Custos Fixos</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosFixos.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.custosFixos.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- MARGEM DE CONTRIBUIÇÃO -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <!-- {{f}} -->
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Margem de Contribuição</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.margemContribuicao.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.margemContribuicao.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- RECEITAS NÃO OPERACIONAIs -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.entradasNaoOperacionais.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Receitas Não Operacionais</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.entradasNaoOperacionais.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.entradasNaoOperacionais.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- DESPESAS NÃO OPERACIONAIS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.saidasNaoOperacionais.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Despesas Não Operacionais</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.saidasNaoOperacionais.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.saidasNaoOperacionais.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- LUCRO OPERACIONAL PRÉ-INVESTIMENTOS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <!-- {{f}} -->
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Lucro operacional pré-investimentos</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacionalAntesInvetimentos.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacionalAntesInvetimentos.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- INVESTIMENTOS -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="f.investimentos.lista"
                                    :fields="fluxoCustosVar"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Investimentos</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.investimentos.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.investimentos.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                    <template #cell(valor)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                    <template #cell(porcentagem)="data">
                                        <div class="d-flex justify-content-end">
                                            {{data.value}}
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- LUCRO OPERACIONAL -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <!-- {{f}} -->
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Lucro Operacional</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacional.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.lucroOperacional.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <!-- RESULTADO LÍQUIDO -->
                        <b-row>
                            <b-col md="12" sm="12">
                                <b-table
                                    borderless
                                    small                                        
                                    fixed
                                    sort-icon-left
                                    :items="[]"
                                    :fields="fluxoMargem"
                                    head-variant="light"
                                >
                                    <template #head(plano_contas)>
                                        <h5 class="text-info m-0"><strong>Resultado Líquido</strong></h5> 
                                    </template>
                                    <template #head(valor)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.resultadoLiquido.valor.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                                            </strong>
                                        </h5> 
                                        </div>
                                    </template>
                                    <template #head(porcentagem)>
                                        <div class="d-flex justify-content-end">
                                        <h5 class="text-info m-0">
                                            <strong>
                                                {{f.resultadoLiquido.porcentagem.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}%
                                            </strong>
                                        </h5>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        
                    </b-col> 
                    <b-col md="12" sm="12" v-if="dadosFluxoCaixa.length < 1">
                        <h4>Nenhuma informação encontrada...</h4>
                    </b-col>
                </div>
                                   
            </b-row>
        </b-card>

        <b-card header-tag="header" v-if="!show && !showFluxoCaixa">  

            <template #header>
                <div style="margin-top: 2px" class="mb-0 float-left">
                    <h5 class="mb-0 mr-3 float-left"><strong>Caixa nº: {{caixa.id_caixa}} - {{dataCaixa}} - {{caixa.nome}} 
                        <div class="mb-0 mr-3 float-left" v-if="caixa.data_fechamento">(Fechado: {{caixa.data_fechamento}})</div></strong></h5>                    
                </div> 
            </template>                      

            <b-form>
                <input id="plano-conta-id" type="hidden" v-model="caixa.id_caixa" />

                <b-row>
                    <b-col md="4" sm="12">
                        <b-list-group class="saldos-conc">
                            <b-list-group-item>
                                <p>Saldo de Abertura</p>
                                <h4>{{caixa.valor_abertura.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>

                            <b-list-group-item>
                                <p>Saldo de Fechamento</p>
                                <h4>{{caixa.valor_fechamento.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>
                            <b-list-group-item variant="warning" class="saldos-conc">
                                <p>Diferença <i style="font-size: 1rem" class="fa fa-question-circle-o" v-b-tooltip.hover.bottomright="'A diferença entre o saldo do sistema e o valor físico é calculada ao fechar o caixa.'"></i></p>
                                <h4 style="margin-bottom: -5px">{{(caixa.diferenca).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}} </h4>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col> 

                    <b-col md="8" sm="12">
                        <b-list-group class="saldos-conc-mov" horizontal>
                            <b-list-group-item variant="success" class="saldos-conc-mov-li">
                                <p>Entradas</p>
                                <h4>{{caixa.valor_entradas.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>

                            <b-list-group-item variant="danger" class="saldos-conc-mov-li">
                                <p>Saídas</p>
                                <h4>{{caixa.valor_saidas.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>

                            <b-list-group-item variant="primary" class="saldos-conc-mov-li">
                                <p>Saldo Atual <i style="font-size: 1rem" class="fa fa-question-circle-o" v-b-tooltip.hover.bottomright="'Saldo atual é composto pelo Saldo de Abertura + Saldo em Dinheiro + Saldo em Cheques.'"></i></p>
                                <h4>{{(caixa.valor_abertura + caixa.valor_dinheiro + caixa.valor_cheques).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}} </h4>
                            </b-list-group-item>
                        </b-list-group>

                        <b-list-group class="saldos-conc-mov" horizontal>
                            <b-list-group-item class="saldos-conc-mov-li">
                                <i class="fa fa-money"></i><p> Dinheiro</p>
                                <h4>{{caixa.valor_dinheiro.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>

                            <b-list-group-item class="saldos-conc-mov-li">
                                <i class="fa fa-list-alt"></i><p>Cheque</p>
                                <h4>{{caixa.valor_cheques.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>

                            <b-list-group-item class="saldos-conc-mov-li">
                                <i class="fa fa-credit-card"></i><p>Cartão</p>
                                <h4>{{caixa.valor_cartoes.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>

                            <b-list-group-item class="saldos-conc-mov-li">
                                <i class="fa fa-university"></i><p>Banco</p>
                                <h4>{{caixa.valor_bancos.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h4>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>                     
                </b-row>                 
                <br>

                <b-tabs content-class="mt-3" card>

                    <b-tab title="Liquidação" v-if="caixa.status === 'A'" active>
                        <b-row >                

                            <b-col md="4" sm="12" class="radio-consulta">
                                <!-- <b-form-group label-for="conta-receber-pessoa">
                                    <p class="entre-datas" style="margin: 0 0 -8px 0">Pessoa</p> 
                                    <Autocomplete                                        
                                        id="conta-receber-pessoa"
                                        :options="pessoas"
                                        label-key="razao_social"
                                        value-key="idpessoa"
                                        v-model="parcial"
                                        placeholder=""
                                        @shouldSearch="loadPessoas"
                                        @select="onSelect"
                                    />
                                </b-form-group> -->

                                <b-form-group label-for="conta-receber-pessoa">
                                    <p class="entre-datas" style="margin: 0 0 0 0">Pessoa</p> 
                                    <vue-single-select
                                        id="conta-receber-pessoa"
                                        placeholder="Procurar"
                                        v-model="parcial"
                                        :options="pessoas"
                                        option-key="value"
                                        option-label="text"
                                    ></vue-single-select>
                                </b-form-group>

                                <div>
                                    <b-form-radio-group               
                                        id="radio-status-1"
                                        v-model="fatStatus"
                                        :options="optionsStatus"
                                        name="radio-op-status-1"
                                    ></b-form-radio-group>  
                                </div>
                            </b-col> 

                            <b-col md="2" sm="12" class="radio-consulta">
                                <b-form-group>
                                    <p class="entre-datas">Vencimento de</p> 
                                    <b-form-input size="sm" id="data-inicial" type="date" v-model="fatDataIni"/>
                                    <p class="entre-datas">até</p> 
                                    <b-form-input size="sm" id="data-final" type="date" v-model="fatDataFim"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="3" sm="12" class="radio-consulta">
                                <b-form-group label-for="conta-bancaria" style="width: 100%">
                                    <p class="entre-datas">Filial</p> 
                                    <b-form-select size="sm" id="conta-bancaria" v-model="fatFilial" :options="filiais"/>
                                </b-form-group>
                                <b-form-radio-group                   
                                    id="radio-tipo-1"
                                    v-model="fatPagarReceber"
                                    :options="optionsPagarReceber"
                                    name="radio-op-tipo-1"
                                ></b-form-radio-group>
                            </b-col>    

                            <b-col md="2" sm="12" class="radio-consulta d-flex justify-content-end">
                                <b-button 
                                    class="botao-consulta" 
                                    variant="outline-info" 
                                    size="lg"
                                    @click="novaConsultaFaturas"
                                >
                                    <i class="fa fa-search button-icons"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-if="faturas.length > 0">                            
                            <b-row>
                                <b-col style="margin: 10px 0 20px 0" class="d-flex justify-content-end">
                                    <b-button-toolbar>                                       
                                        <b-button-group class="mx-1">
                                            <b-input-group prepend="Data de Liquidação">
                                                <b-form-input type="date" v-model="caixaMovimento.data_movimento"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button @click="liquidarFaturas()" variant="primary">Liquidar</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-button-group> 
                                    </b-button-toolbar>
                                </b-col>
                            </b-row>
                            
                             
                            <b-table 
                                :items="faturas" 
                                :fields="fieldsMovFaturas" 
                                :per-page="limitFatura" 
                                :current-page="pageFaturas"

                                @row-clicked="rowClicked"
                                :tbody-tr-class="tbodyRowClass"
                                primary-key="seq"

                                ref="selectableTableFaturas"  
                                hover 
                                sort-icon-left 
                                small 
                                striped 
                                borderless                                
                                responsive
                                
                            >  
                                <template #head(selected) v-if="faturas.length > 0">                                    
                                    <template v-if="faturas.length === selectedsFaturas.length">
                                        <div style="margin-bottom: -5px">
                                            <span  aria-hidden="true" @click="clearSelectedFaturas"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                                        </div>
                                    </template>
                                    <template v-if="faturas.length !== selectedsFaturas.length"> 
                                        <div style="margin-bottom: -5px">
                                            <span aria-hidden="true" @click="selectAllRowsFaturas"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                                        </div>
                                    </template>
                                </template>

                                <template v-slot:cell(selected)="{ item, field: { key } }" >
                                    <span v-if="item[key]"><i style="font-size: 23px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                                    <span v-else><i style="font-size: 23px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                                </template>

                                <template #cell(liquidar)="data">    
                                    <div style="d-flex justify-content-end">
                                        <money style="width: 100%; margin-right: -90px" type="text" v-model="data.item.valor_liquido" v-bind="money" class="form-control" 
                                        @keyup.native="()=>{
                                            data.item.liquidar = data.item.valor_liquido
                                            data.item.desconto = 0
                                            data.item.acrescimo = 0
                                            fatura.porcDesconto = 0
                                            fatura.porcAcrescimo = 0
                                        }"/>
                                    </div>                        
                                </template>

                                <template v-slot:cell(actions)="data">
                                    <div class="d-flex justify-content-end">
                                        <b-button variant="info" @click="informacaoFatura(data.item)" class="mr-2" v-b-modal.modal-lg-detalhes>
                                            <i class="fa fa-info"></i>
                                        </b-button>
                                        <b-button variant="warning" @click="carregaJuroDesconto(data.item)" class="mr-2" v-b-modal.modal-descontos-acrescimos>
                                            <i class="fa fa-percent"></i>
                                        </b-button>
                                    </div>
                                </template>

                            </b-table>

                            <b-pagination 
                                align="center" 
                                size="sm" 
                                :per-page="limitFatura" 
                                v-model="pageFaturas"  
                                :total-rows="countFatura" 
                                first-number 
                                last-number>
                            </b-pagination> 
                        </div>

                    </b-tab> 

                    <b-tab title="Movimentos deste Caixa" >
                        <b-table 
                            responsive 
                            hover 
                            sort-icon-left 
                            small 
                            striped 
                            borderless 
                            :items="caixa.movimentos" 
                            :fields="fieldsCaixaMovimentos" 
                            v-if="caixa.movimentos.length > 0"
                        >  
                            <template v-slot:cell(actions)="data">
                                <div class="d-flex justify-content-end">
                                    <b-button variant="info" @click="infoMovimento(data.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Detalhes'">
                                        <i class="fa fa-info"></i>
                                    </b-button>
                                    <b-button v-if="caixa.status === 'A'" variant="danger" @click="showModalExc(data.item)" v-b-tooltip.hover.bottomleft="'Excluir movimento'">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <h5 v-else class="ml-2">Este caixa ainda não possui movimentos...</h5>
                    </b-tab>                    
                </b-tabs>
            </b-form>
        </b-card> 

        <b-card class="cadastros-card-corpo" v-if="show && !showFluxoCaixa">
            <b-table 
                hover 
                striped 
                :items="caixas" 
                :fields="fieldsCaixas" 
                small 
                borderless
                responsive
            >  
                <template v-slot:cell(actions)="data">
                    <div class="d-flex justify-content-end">
                        <b-button v-if="data.item.status === 'A'" size="sm" variant="success" @click="logarCaixa(data.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Logar'">
                            <i class="fa fa-sign-in"></i>
                        </b-button>
                        <b-button v-else size="sm" variant="success" @click="loadCaixa(data.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Visualizar'">
                            <i class="fa fa-eye"></i>
                        </b-button>
                        <b-button size="sm" variant="danger" @click="desejaExcluirCaixa(data.item.id_caixa)" v-b-tooltip.hover.bottomleft="'Excluir'">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>

        <b-modal id="modal-abrir-caixa" title="Abrir novo Caixa" v-model="showModalAbrirCx">

            <b-form-group label-for="data-novo-caixa">
                Data Caixa
                <b-form-input id="data-novo-caixa" type="date" v-model="dataNovoCaixa"/>                    
            </b-form-group>

            <b-form-group label-for="data-inicial">
                Filial
                <b-form-select id="cadastros-pesquisa" v-model="filialNovoCaixa" :options="filiais"/>  
            </b-form-group> 

            <template #modal-footer>
                <div class="w-100">  
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right ml-3"
                        @click="$bvModal.hide('modal-abrir-caixa')"
                    >
                        Cancelar
                    </b-button>                  
                    <b-button
                        variant="success"
                        size="md"
                        class="float-right"
                        @click="abrirCaixa()"                            
                    >
                        Abrir Caixa
                    </b-button>                    
                </div>
            </template>

        </b-modal> 

        <b-modal id="modal-cancela-caixa" title="Excluir caixa" v-model="moldalDesejaExcluirCaixa"   >
            <p class="my-4">Deseja realmente <strong>Excluir</strong> este caixa?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="removeCaixa()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-cancela-caixa')"
                    >
                        Não
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal 
            v-model="showModalLiquidar"
            id="modal-lg-liquidar" 
            centered size="lg" 
            ok-only 
            title="Liquidação de Faturas"
        >
            <div style="min-height: 550px">
                <b-tabs > 
                    <b-tab title="Resumo" class="pt-3">  
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-card header="Total Receber" header-bg-variant="success" header-text-variant="white">
                                    <h4><strong>{{liquidacao.totalReceber.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong></h4>
                                </b-card>
                                <br>
                                <b-card header="Total Pagar" header-bg-variant="danger" header-text-variant="white">
                                    <h4><strong>{{liquidacao.totalPagar.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong></h4>
                                </b-card>
                                <br>
                                <b-card>
                                    <span><strong>{{ liquidacao.tipo }}</strong></span> <br>
                                    <h4 style="font-size: 2rem"><strong>{{liquidacao.totalLiquidacaoAbs.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong></h4>
                                </b-card>                        
                            </b-col>

                            <b-col md="6" sm="6">
                                <b-card header="Numerários" style="margin-bottom: 15px">
                                    <b-form-group label="Cartão" label-for="liquidar-cartoes">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-cartoes" type="text" v-model="caixaMovimento.totalCartoes" v-bind="money" readonly class="form-control" />
                                    </b-form-group>

                                    <b-form-group label="Cheque" label-for="liquidar-cheques">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-cheques" type="text" v-model="caixaMovimento.totalCheques" v-bind="money" readonly class="form-control" />
                                    </b-form-group>

                                    <b-form-group label="Depósito Bancário" label-for="liquidar-deposito">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-deposito" type="text" v-model="caixaMovimento.totalDepositos" v-bind="money" readonly class="form-control" />
                                    </b-form-group>
                                    
                                    <b-form-group label="Dinheiro" label-for="liquidar-dinheiro" style="margin-bottom: 0px">
                                        <money style="font-size: 1.3rem; margin: -5px 0 -5px 0;" id="liquidar-dinheiro" type="text" v-model="caixaMovimento.totalDinheiro" v-bind="money" readonly class="form-control" />
                                    </b-form-group>
                                </b-card>
                                <b-form-textarea
                                    placeholder="Informe uma observação..."
                                    v-model="liquidacao.obs"
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-tab>

                    <!-- Para contas a receber -->
                    <b-tab class="pt-3" title="Cartão" v-if="liquidacao.totalLiquidacao > 0">
                        <b-row>
                            <b-col md="5" sm="5">
                                <b-form-group label="Admin" label-for="liquidacao-adm-cartao">
                                    <b-form-select id="liquidacao-adm-cartao" v-model="cartao.id" :options="administradoras" />
                                </b-form-group>
                                <b-form-group label="Condição" label-for="liquidacao-parc-cartao">
                                    <b-form-select 
                                        id="liquidacao-parc-cartao" 
                                        v-model="cartao.id_cartao_bandeira" 
                                        :options="cartaoCondicoes" 
                                        :disabled="cartaoCondicoes.length < 1 "
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="3">
                                <b-form-group label="Autorização" label-for="liquidacao-auto-cartao">
                                    <b-form-input id="liquidacao-auto-cartao" type="text" v-model="cartao.autorizacao"/>
                                </b-form-group>
                                <b-form-group label="Qtde. Parcelas" label-for="liquidacao-auto-qtde">
                                    <b-form-input id="liquidacao-auto-qtde" type="number" v-model="cartao.qtde_parcelas"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="3">
                                <b-form-group label="Valor" label-for="liquidacao-valor-cartao">
                                    <money id="liquidacao-valor-cartao" type="text" v-model="cartao.valor" v-bind="money" class="form-control" />
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="1">
                                <b-button style="margin-top: 30px" variant="success" @click="gravaCartao()" class="botao-checkar">
                                            <i class="fa fa-check"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table 
                                    small 
                                    striped 
                                    :items="caixaMovimento.cartoes" 
                                    :fields="fieldsCartao" 
                                    borderless 
                                    responsive 
                                    sort-icon-left
                                >   
                                    <template v-slot:cell(actions)="data">
                                        <div class="d-flex justify-content-end">
                                            <b-button variant="danger" @click="excluirCartao(data.item)">
                                                <i class="fa fa-times"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                    </b-tab>

                    <!-- Para contas a receber -->
                    <b-tab class="pt-3" title="Cheque" v-if="liquidacao.totalLiquidacao > 0">
                        <b-row>
                            <b-col md="4" sm="4">
                                <b-form-group label="Nº Banco" label-for="liquidacao-cheque-banco">
                                    <b-form-input id="liquidacao-cheque-banco" type="number" v-model="cheque.num_banco"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Agência" label-for="liquidacao-cheque-agencia">
                                    <b-form-input id="liquidacao-cheque-agencia" type="number" v-model="cheque.agencia"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Conta" label-for="liquidacao-cheque-conta">
                                    <b-form-input id="liquidacao-cheque-conta" type="number" v-model="cheque.conta"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4" sm="4">
                                <b-form-group label="Nº Cheque" label-for="liquidacao-cheque-num">
                                    <b-form-input id="liquidacao-cheque-num" type="number" v-model="cheque.num_cheque"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Vencimento" label-for="liquidacao-cheque-vencimento">
                                    <b-form-input id="liquidacao-cheque-vencimento" type="date" v-model="cheque.vencimento"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="Valor" label-for="liquidacao-cheque-valor">
                                    <money id="liquidacao-cheque-valor" type="text" v-model="cheque.valor" v-bind="money" class="form-control"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>                           
                            <b-col md="7" sm="8">
                                <b-form-group label="Nome do Emissor" label-for="liquidacao-cheque-nome-em">
                                    <b-form-input id="liquidacao-cheque-nome-em" type="text" v-model="cheque.nome_emissor"/>
                                </b-form-group>                                
                            </b-col>

                            <b-col md="4" sm="4">
                                <b-form-group label="CPF/CNPJ do Emissor" label-for="liquidacao-cheque-cpf-cnpj-emissor">
                                    <b-form-input id="liquidacao-cheque-cpf-cnpj-emissor" type="number" v-model="cheque.cpf_cnpj_emissor"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="1" sm="1">
                                <b-button style="margin-top: 30px" variant="success" @click="gravaCheque()" class="botao-checkar">
                                            <i class="fa fa-check"></i>
                                </b-button>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-table 
                                    small 
                                    striped 
                                    :items="caixaMovimento.cheques" 
                                    :fields="fieldsCheque" 
                                    borderless 
                                    responsive 
                                    sort-icon-left
                                >   
                                    <template v-slot:cell(actions)="data">
                                        <div class="d-flex justify-content-end">
                                            <b-button variant="danger" @click="excluirCheque(data.item)">
                                                <i class="fa fa-times"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                    </b-tab>
                    
                    <!-- Para contas a receber/pagar -->
                    <b-tab class="pt-3" title="Depósito bancário">
                        <b-row>
                            <b-col md="8" sm="3">
                                <b-form-group label="Conta" label-for="liquidacao-dep-conta">
                                    <b-form-select id="liquidacao-dep-cont" v-model="depositoBancario.id_conta_bancaria" :options="contasBancarias" />
                                </b-form-group>
                            </b-col>                    
                            <b-col md="3" sm="3">
                                <b-form-group label="Valor" label-for="liquidacao-dep-valor">
                                    <money id="liquidacao-dep-valor" type="text" v-model="depositoBancario.valor" v-bind="money" class="form-control" />
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="1">
                                <b-button style="margin-top: 30px" variant="success" @click="gravarDeposito()" class="botao-checkar">
                                            <i class="fa fa-check"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table 
                                    small 
                                    striped 
                                    :items="caixaMovimento.depositosBancario" 
                                    :fields="fieldsDeposito" 
                                    borderless 
                                    responsive
                                    sort-icon-left
                                >  
                                    <template v-slot:cell(id_conta_bancaria)="data">
                                        <b-form-select id="liquidacao-dep-cont" v-model="data.item.id_conta_bancaria" :options="contasBancarias" disabled/>
                                        {{data}}
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <div class="d-flex justify-content-end"> 
                                            <b-button variant="danger" @click="excluirDeposito(data.item)">
                                                <i class="fa fa-trash-o"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-tab>
                    
                </b-tabs>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="primary"
                        size="lg"
                        class="float-right"
                        @click="salvarLiquidacao"
                    >
                        Liquidar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal 
            v-model="showModalExcluir"
            id="modal-lg-liquidar" 
            centered  
            title="CUIDADO!"
            header-bg-variant="danger"
            header-text-variant="light"
        >
            <span>O procedimento de exclusão não pode ser desfeito!</span> <br>
            <span><strong>Deseja excluir a liquidação?</strong></span>

            <template #modal-footer>
                <div class="w-100">
                    <b-button 
                        variant="danger" 
                        size="lg"
                        class="float-right"
                        @click="showModalExcluir = false"
                    >
                        Não
                    </b-button>

                    <b-button
                        style="margin-right: 10px"
                        variant="success"
                        size="lg"
                        class="float-right"
                        @click="removeMovimento"
                    >
                        Sim
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal                 
            id="modal-descontos-acrescimos" 
            centered 
            title="Descontos e Acréscimos"
            header-bg-variant="primary"
            header-text-variant="light"
            ok-only
        >                   
            <b-row>
                <b-col md="12" sm="12">
                    <b-card>
                        <b-row>
                            <b-col md="6" sm="6">
                                Saldo a liquidar
                                <money type="text" v-model="fatura.liquidar" v-bind="money" readonly class="moeda-tabela-liquidar"/>
                            </b-col>
                            <b-col md="6" sm="6">
                                Valor líquido
                                <money type="text" v-model="fatura.valor_liquido" v-bind="money" readonly class="moeda-tabela-liquidar"/>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>              
            <br>

            <b-row>
                <b-col md="6" sm="6">
                    <b-card header="Desconto" header-bg-variant="success" header-text-variant="white">
                        <money id="liquidacao-desconto" type="text" v-model="fatura.desconto" v-bind="money" class="form-control" @keyup.native="calculaDescAcres('vd')"/><br>
                        <money id="liquidacao-porc-desconto" type="text" v-model="fatura.porcDesconto" v-bind="moneyPercent" class="form-control" @keyup.native="calculaDescAcres('pd')"/>                            
                    </b-card>
                </b-col>
                <b-col md="6" sm="6">
                    <b-card header="Acréscimo" header-bg-variant="danger" header-text-variant="white">
                        <money id="liquidacao-acrescimo" type="text" v-model="fatura.acrescimo" v-bind="money" class="form-control" @keyup.native="calculaDescAcres('va')"/><br>
                        <money id="liquidacao-porc-acrescimo" type="text" v-model="fatura.porcAcrescimo" v-bind="moneyPercent"  class="form-control" @keyup.native="calculaDescAcres('pa')"/>
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal 
            id="modal-lg-detalhes" 
            centered size="lg" 
            ok-only 
            title="Detalhes da Fatura"
            :header-bg-variant="(faturaDetalhe.tipo ==='Pagar') ? 'danger' : 'success'"
            header-text-variant="light"
        >
            <b-form>
                <b-row>
                    <b-col md="10" sm="12" style="margin-bottom: 5px">
                        <h4>#{{ faturaDetalhe.id_pessoa }} - {{ faturaDetalhe.razao_social }}</h4>
                    </b-col>

                    <b-col md="2" sm="2" class="d-flex justify-content-end">                        

                        <i id="modal-popover-fatura" class="fa fa-info-circle info-alteracoes"></i>

                        <b-popover target="modal-popover-fatura" triggers="hover" placement="left">
                            <template #title>Alterações</template>
                            <b>Usuário Cadastro:</b> {{faturaDetalhe.usu_cad}} <br>
                            <b>Data de Cadastro:</b> {{faturaDetalhe.data_cad}} <br>
                            <b>Usuário Alteração:</b> {{faturaDetalhe.usu_alt}} <br>
                            <b>Data de Alteração:</b> {{faturaDetalhe.data_alt}}
                        </b-popover>                        
                    </b-col>
                </b-row>

                <b-row style="margin-bottom: 25px">
                    <b-col md="12" sm="12">
                        <b-badge pill variant="danger" v-if="faturaDetalhe.id_conta_receber" class="pill-destalhe-fatura">Nº Fatura: {{ faturaDetalhe.id_conta_receber }} </b-badge>
                        <b-badge pill variant="danger" v-if="faturaDetalhe.id_conta_pagar" class="pill-destalhe-fatura">Nº Fatura: {{ faturaDetalhe.id_conta_pagar }} </b-badge>
                        <b-badge pill variant="warning" v-if="faturaDetalhe.id_venda" class="pill-destalhe-fatura">Nº Venda: {{ faturaDetalhe.id_venda }} </b-badge>
                        <b-badge pill variant="primary" v-if="faturaDetalhe.num_docto" class="pill-destalhe-fatura">Nº Docto: {{ faturaDetalhe.num_docto }} </b-badge>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="3" sm="12">
                        <b-form-group label="Valor" label-for="modal-valor">
                            <b-form-input id="modal-valor" type="text" v-model="faturaDetalhe.valor" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Saldo" label-for="modal-saldo">
                            <b-form-input id="modal-saldo" type="text" v-model="faturaDetalhe.saldo" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Competência" label-for="modal-data-competencia">
                            <b-form-input id="modal-data-competencia" type="date" v-model="faturaDetalhe.data_competencia" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Data Documento" label-for="modal-data-competencia">
                            <b-form-input id="modal-data-documento" type="date" v-model="faturaDetalhe.data_documento" readonly/>
                        </b-form-group>
                    </b-col>                             
                </b-row>

                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group label="Plano de Contas" label-for="modal-plano-conta">
                            <b-form-input id="modal-plano-conta" type="text" v-model="faturaDetalhe.desc_plano_conta" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="4" sm="12">
                        <b-form-group label="Forma" label-for="modal-forma">
                            <b-form-input id="modal-forma" type="text" v-model="faturaDetalhe.desc_forma" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="4" sm="12">
                        <b-form-group label="Condição" label-for="modal-condicao">
                            <b-form-input id="modal-condicao" type="text" v-model="faturaDetalhe.desc_condicao" readonly/>
                        </b-form-group>
                    </b-col>                           
                </b-row>

                <b-row>
                    <b-col md="3" sm="12">
                        <b-form-group label="Nº Docto" label-for="modal-num-docto">
                            <b-form-input id="modal-num-docto" type="text" v-model="faturaDetalhe.num_docto" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Modelo" label-for="modal-modelo">
                            <b-form-input id="modal-modelo" type="text" v-model="faturaDetalhe.modelo" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12">
                        <b-form-group label="Série" label-for="modal-serie">
                            <b-form-input id="modal-serie" type="text" v-model="faturaDetalhe.serie" readonly/>
                        </b-form-group>
                    </b-col>    
                    <b-col md="3" sm="12">
                        <b-form-group label="CFOP" label-for="modal-cfop">
                            <b-form-input id="modal-cfop" type="text" v-model="faturaDetalhe.cfop" v-mask="mascaras.cfop" readonly/>
                        </b-form-group>
                    </b-col>                         
                </b-row>

                <b-row>
                    <b-col md="8" sm="12">
                        <b-form-group label="Chave NF-e" label-for="modal-chave-nfe">
                            <b-form-input id="modal-chave-nfe" type="text" v-model="faturaDetalhe.chave_nf" v-mask="mascaras.nf" readonly/>
                        </b-form-group>
                    </b-col> 
                    <b-col md="4" sm="12">
                        <b-form-group label="Protocolo" label-for="modal-protocolo">
                            <b-form-input id="modal-protocolo" type="text" v-model="faturaDetalhe.protocolo" readonly/>
                        </b-form-group>
                    </b-col>                        
                </b-row>

                <b-row>
                    <b-col md="12" sm="12">
                        <b-form-group label="Descrição" label-for="modal-descricao-conta-receber">
                            <b-form-textarea
                                id="modal-descricao-conta-receber"
                                v-model="faturaDetalhe.descricao"
                                rows="3"
                                readonly
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col> 
                </b-row>
            </b-form>
        </b-modal>

        <!-- MODAL FATURAS E NUMERARIOS -->
        <b-modal 
            v-model="showInfoMovimento"
            id="modal-lg-liquidar" 
            centered size="lg" 
            ok-only 
            title="Faturas e Numerários"
        >
            <div class="mb-2">
                <div >
                    <h6 style="font-size: 1.7rem" class="mb-0 mr-3 float-left"><strong>#{{ caixaMovimento.id_caixa_movimento }} - {{ caixaMovimento.data_movimento }}</strong></h6>                           
                </div>  
                <br><br>
                <b-card style="height: 80px; min-width: 200px" class="mr-1 float-left" bg-variant="success" text-variant="white">
                    <blockquote class="card-blockquote">
                        <p style="margin: -15px 0 -5px 0; font-size: 1.8rem;">
                            <strong>{{ caixaMovimento.valor_recebido.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  }}</strong>
                        </p>
                        
                        <footer>
                            <small>Valor Recebido</small>
                        </footer>
                    </blockquote>
                </b-card>                       
                <b-card style="height: 80px; min-width: 200px" class="mr-1 float-left" bg-variant="danger" text-variant="white">
                    <blockquote class="card-blockquote">
                        <p style="margin: -15px 0 -5px 0; font-size: 1.8rem;">
                            <strong>{{ caixaMovimento.valor_pago.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  }}</strong>
                        </p>
                        
                        <footer>
                            <small>Valor Pago</small>
                        </footer>
                    </blockquote>
                </b-card>                       
                                    
            </div> <br><br><br><br>

            <div>                

                <b-card header="Faturas" header-bg-variant="default" header-text-variant="dark" style="padding: 0" class="mb-2">
                    <b-table 
                        hover
                        responsive 
                        borderless
                        sort-icon-left
                        :items="movFaturas" 
                        :fields="fieldsMovFaturasNumerarios" 
                        sticky-header="200px" 
                        small 
                        class="faturas-numerarios"
                    >
                    </b-table>
                </b-card>

                <b-card header="Numerários" header-bg-variant="default" header-text-variant="dark" style="padding: 0" class="mb-2" >
                    <b-table 
                        hover 
                        sort-icon-left
                        responsive 
                        borderless
                        :items="movNumerarios" 
                        :fields="fieldsMovNumerarios" 
                        sticky-header="200px" 
                        small 
                        class="faturas-numerarios"
                    >
                    </b-table>
                </b-card>
            </div>                
        </b-modal>

        <b-modal 
            v-model="showModalFecharCx"
            id="modal-fechar-caixa" 
            centered  
            title="Fechar Caixa"
            header-bg-variant="danger"
            header-text-variant="light"
        >
            <span><strong>Informe os numerários conforme saldo físico.</strong></span> <br><br>
            
            <b-row>
                <b-col md="6" sm="12">
                    <b-form-group label="Dinheiro Sistema" label-for="fechar-dinheiro-sistema">
                        <money id="fechar-dinheiro-sistema" type="text" v-model="caixa.valor_dinheiro" v-bind="money" class="form-control" readonly/>
                    </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                    <b-form-group label="Dinheiro Físico" label-for="fechar-dinheiro-fisico">
                        <money id="fechar-dinheiro-fisico" type="text" v-model="fecharCx.dinheiro" v-bind="money" class="form-control"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" sm="12">
                    <b-form-group label="Cheques Sistema" label-for="fechar-chq-sistema">
                        <money id="fechar-chq-sistema" type="text" v-model="caixa.valor_cheques" v-bind="money" class="form-control" readonly/>
                    </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                    <b-form-group label="Cheques Físico" label-for="fechar-chq-fisico">
                        <money id="fechar-chq-fisico" type="text" v-model="fecharCx.cheques" v-bind="money" class="form-control"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <template #modal-footer>
                <div class="w-100">
                    <b-button 
                        variant="danger" 
                        size="md"
                        class="float-right"
                        @click="showModalFecharCx = !showModalFecharCx"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        style="margin-right: 10px"
                        variant="success"
                        size="md"
                        class="float-right"
                        @click="fecharCaixa"
                    >
                        Fechar Caixa
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal 
            v-model="showModalReabrirCx"
            id="modal-reabrir-caixa" 
            centered  
            title="Reabrir Caixa"
            header-bg-variant="danger"
            header-text-variant="light"
        >
            <span>As alterações feitas em um caixa fechado pode alterar o valor de Abertura, Fechamento e Diferença de caixas posteriores.</span> <br><br>
            <span><strong>Deseja reabrir o caixa mesmo assim?</strong></span> <br><br>   

            <template #modal-footer>
                <div class="w-100">
                    <b-button 
                        variant="danger" 
                        size="md"
                        class="float-right"
                        @click="showModalReabrirCx = !showModalReabrirCx"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        style="margin-right: 10px"
                        variant="success"
                        size="md"
                        class="float-right"
                        @click="reabrirCaixa"
                    >
                        Reabrir Caixa
                    </b-button>
                </div>
            </template>
        </b-modal>

         
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
// import Autocomplete from '../../autocomplete/Autocomplete.vue'
import VueSingleSelect from "vue-single-select";
import {VMoney} from 'v-money'
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"   
import { Doughnut } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'NovoCaixa',
    components: { Doughnut, VueSingleSelect },
    computed: {
        selectedRows() {
            return this.faturas.filter(item => item.selected)
        }
    },
    data: function() {
        return {  
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },        

            showModalAbrirCx: false,
            showModalFecharCx: false,
            showModalReabrirCx: false,
            dataNovoCaixa: moment().format('yyyy-MM-DD'), 
            filialNovoCaixa: 0, 

            caixa: {
                movimentos: [],
                valor_dinheiro: 0, 
                valor_cheques: 0,
                valor_cartoes: 0, 
                valor_bancos: 0,
            },
            caixas: [],
            usuarios: [],
            filiais: [],
            faturas: [],
            pessoas: [],
            movFaturas: [],
            movNumerarios: [],
            fecharCx:{
                dinheiro: 0,
                cheques: 0
            },

            // Fluxo de caixa
            showFluxoCaixa: false,
            fluxoFilial: 0,
            fluxoDataInicial: moment().add(-1, 'M').format('YYYY-MM-01'),
            fluxoDataFinal: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
            fluxoDataInicialF: '',
            fluxoDataFinalF: '',
            dadosFluxoCaixa: {},
            fluxoNumer: [
                {key: 'valor_recebido', label: 'Total', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_cheques', label: 'Cheques', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_cartoes', label: 'Cartões', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_bancos', label: 'Bancos', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'total_dinheiro', label: 'Dinheiro', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
            ],
            fluxoRecPlano: [
                {key: 'plano_contas', label: 'Plano de Contas', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
            ],
            fluxoCustosVar: [
                {key: 'plano_contas', label: 'Plano de Contas', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                }, 
                {key: 'porcentagem', label: '', sortable: false, 
                    formatter: value => { 
                        return `${value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`  
                    }
                }, 
            ],

            fluxoMargem: [
                {key: 'plano_contas', label: 'Plano de Contas', sortable: false},
                {key: 'valor', label: 'Valor', sortable: false}, 
                {key: 'porcentagem', label: '', sortable: false}, 
            ],

            show: true,
            mode: 'new',
            moldalDesejaExcluirCaixa: false,
            idCaixa: 0,
            
            // Pesquisa caixa
            dataCaixa: '',
            idUsuario: 0,
            dataIni: moment().add(-7, 'days').format('yyyy-MM-DD'),
            dataFim: moment().format('yyyy-MM-DD'),
            status: 'A',
            page: 1,
            count: 0,
            limit: 0,
            showModalExcluir: false,
            showInfoMovimento: false,

            // Pesquisa faturas
            idPessoa: 0,
            parcial: '',
            fatPagarReceber: 'T',
            fatDataIni: moment().add(-7, 'days').format('yyyy-MM-DD'),
            fatDataFim: moment('2400-12-31').format('yyyy-MM-DD'),
            fatStatus: 'T',
            fatFilial: 0,
            pageFaturas: 1,
            countFatura: 0,
            limitFatura: 0,
            selectedsFaturas: [],
            faturasSelecionadas: [],
            showModalLiquidar: false,
            faturaDetalhe: {},

            // Liquidar
            administradoras: [],
            cartaoCondicoes: [],
            contasBancarias: [],
            fatura: {
                liquidar: 0,
                desconto: 0,
                acrescimo: 0,
                porcDesconto: 0,
                porcAcrescimo: 0,                
            },

            caixaMovimento: {
                id_caixa: 0,
                id_plano_contas: 0,
                data_movimento: moment().format('YYYY-MM-DD'),

                cheques: [],
                cartoes: [],
                depositosBancario: [],

                totalCheques: 0,
                totalCartoes: 0,
                totalDepositos: 0,
                totalDinheiro: 0,

                valor_recebido: 0,
                valor_pago: 0,
                totalGeral: 0,

                historico: '',

                tipo_movimento: 'L',
            },
            cartao: {                
                id: 0,
                id_cartao_bandeira: 0,
                qtde_parcelas: 1,
                autorizacao: '',
                valor: 0,
                descricao: ''
            },
            cheque: {
                id: 0,
                id_caixa_movimento: 0,
                num_cheque: '',
                agencia: '',
                conta: '',
                cpf_cnpj_emissor: '',
                nome_emissor: '',
                vencimento: moment().format('yyyy-MM-DD'),
                valor: 0,
            },
            depositoBancario: {
                id_conta_bancaria: 0,
                valor: 0
            },
            depositoBancarioPagar: {
                id_conta_bancaria: 0,
                valor: 0
            },
            transferenciaBancaria: {
                id_conta_bancaria: 0,
                id_conta_bancaria_destino: 0,
                valor: 0
            },
            liquidacao: {
                totalReceber: 0,
                totalPagar: 0,

                totalLiquidacao: 0,
                totalLiquidacaoAbs: 0,

                tipo: 'Valor Recebto/Pagto',
                obs: ''
            },

            fieldsCartao: [
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'qtde_parcelas', label: 'Qtde. Parcelas', sortable: true},
                {key: 'autorizacao', label: 'Autorizacao', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },              
                {key: 'actions', label: ''}
            ],
            fieldsCheque: [
                {key: 'descricao', label: 'Cheque', sortable: true},
                {key: 'vencimento', label: 'Vencimento', sortable: true,
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },              
                {key: 'actions', label: ''}
            ],
            fieldsDeposito: [
                // {key: 'id_conta_bancaria', label: 'Conta', sortable: true},
                {key: 'nome_conta', label: 'Conta', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },              
                {key: 'actions', label: ''}
            ],

            fieldsCaixas: [
                {key: 'id_caixa', label: 'Código', sortable: true},
                {key: 'id_filial', label: 'Filial', sortable: true},
                {key: 'data_caixa', label: 'Data', sortable: true,
                formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'nome', label: 'Usuário', sortable: true},
                {key: 'status', label: 'Status', sortable: false, formatter: value => value ==='A' ? 'Aberto' : 'Fechado'},
                {key: 'actions', label: ''}
            ],

            fieldsCaixaMovimentos: [
                {key: 'id_caixa_movimento', label: 'Código', sortable: true},
                {key: 'tipo', label: 'Tipo', sortable: true, formatter: value => value ==='E' ? 'Entrada' : 'Saída'},
                {key: 'data_movimento', label: 'Data', sortable: true,
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'valor_recebido', label: 'Entrada', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'valor_pago', label: 'Saída', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'actions', label: ''}
            ],
            fieldsMovFaturas: [
                {key: 'selected', label: ''},
                {key: 'id', label: 'Fatura', sortable: true},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'vencimento', label: 'Venc.', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'num_docto', label: 'Nº Docto', sortable: true},
                {key: 'num_parc', label: 'Parc.', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'saldo', label: 'Saldo', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                },
                {key: 'status', label: 'Status', 
                    formatter: value => { 
                        switch (value) {
                            case 'A': return "Aberta" 
                            case 'P': return "Parcial" 
                            case 'Q': return "Quitada"
                            case 'R': return "Renegociada"                        
                            case 'C': return "Cancelada"
                        }
                    }
                },
                {key: 'tipo', label: 'Tipo', sortable: true},
                {key: 'liquidar', label: 'Liquidar', sortable: true},
                {key: 'actions', label: ''}
            ], 

            fieldsMovFaturasNumerarios: [
                {key: 'id_fatura', label: 'Fatura', sortable: true},
                {key: 'num_parc', label: 'Parcela', sortable: true},                
                {key: 'saldo', label: 'Saldo', sortable: true, 
                    formatter: value => { 
                        return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00' 
                    }
                },
                {key: 'desconto', label: 'Desconto', sortable: true, 
                    formatter: value => { 
                        return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00' 
                    }
                },
                {key: 'acescimo', label: 'Acréscimo', sortable: true, 
                    formatter: value => { 
                        return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00' 
                    }
                },
                {key: 'liquido', label: 'Líquido', sortable: true, 
                    formatter: value => { 
                        return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00'  
                    }
                },
            ], 

            fieldsMovNumerarios: [
                {key: 'tipo', label: 'Tipo', sortable: true},
                {key: 'descricao', label: 'Descricao', sortable: true},
                {key: 'autorizacao', label: 'Autorizacao', sortable: true},
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0,00' 
                    }
                },
            ], 

            optionsStatus: [
                { value: 'A', text: 'Abertas' },
                { value: 'P', text: 'Parciais'},
                { value: 'T', text: 'Todas' }
            ],
            optionsPagarReceber: [
                { value: 'R', text: 'Receber' },
                { value: 'P', text: 'Pagar'},
                { value: 'T', text: 'Todas' }
            ],

            money: {
                decimal: ',',
                thousands: '.',
                // prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },

            moneyPercent: {
                decimal: ',',
                thousands: '.',
                suffix: '%',
                precision: 2,
                masked: false
            },

            mascaras: {
                masked: true,
                porcentagem: '##%',
                cpf: '###.###.###-##',
                cnpj: '##.###.###/####-##',
                telefone: '(##) ####-####',
                celular: '(##) # ####-####',
                cep: '#####-###',
                cfop: '#.###',
                nf: '##  ####  ##############  ##  ###  #########  #  ########  #',
                docto: '####################',
                serie: 'NNN',
                mod: 'NNN',
                prot: '#############################################'
            },
            cxFechado: false
               
        }
    },
    directives: {
        money: VMoney
    },
    methods: { 
        novaConsulta() {
            this.page = 1 
            this.loadCaixas()
        },

        novaConsultaFaturas() {
            this.pageFaturas = 1 
            this.pesquisaFaturas()
        },

        liquidarFaturas(){
            const c = localStorage.getItem('__lancamentos_caixa') 
            const idCaixa = c ? JSON.parse(c).idCaixa : 0

            if (idCaixa == 0) {this.$toasted.info('Você precisa logar em um caixa para continuar.'); return;} 
            if (this.selectedsFaturas.length < 1) {this.$toasted.info('Selecione ao menos uma fatura para liquidar.'); return;}

            this.caixaMovimento.totalCheques = 0
            this.caixaMovimento.totalCartoes = 0
            this.caixaMovimento.totalDepositos = 0 
            this.caixaMovimento.depositosBancario = []
            this.caixaMovimento.cartoes = []
            this.caixaMovimento.cheques = []

            this.carregaFaturas()
            this.loadAdministradoras()
            this.loadContasBancarias()
            
            this.showModalLiquidar = true
        },
        
        showModalExc(m) {
            this.showModalExcluir = true
            this.caixaMovimento = { ...m }
        },

        removeMovimento() {
            axios['delete'](`${baseApiUrl}/caixa_movimentos/${this.caixaMovimento.id_caixa_movimento}`)
                .then(() => {
                    this.loadCaixa(this.caixaMovimento)
                    this.showModalExcluir = false
                    this.$toasted.global.defaultSuccess()
                })
            .catch(showError)
        },

        salvarLiquidacao() {            
            const c = localStorage.getItem('__lancamentos_caixa') 
            const idCaixa = c ? JSON.parse(c).idCaixa : 0

            var data_1 = new Date(moment(JSON.parse(c).dataCaixa).format('yyyy-MM-DD hh:mm.ss'))
            var data_2 = new Date(moment(this.caixaMovimento.data_movimento).format('yyyy-MM-DD hh:mm.ss'))

            if (data_2 >= data_1){ 

                this.caixaMovimento.id_caixa = idCaixa
                this.caixaMovimento.totalGeral = this.liquidacao.totalLiquidacaoAbs
                this.caixaMovimento.valor_recebido = this.liquidacao.totalReceber
                this.caixaMovimento.valor_pago = this.liquidacao.totalPagar
                this.caixaMovimento.historico = this.liquidacao.obs

                let faturas = {
                    l: [],
                    n: {}
                }
                faturas.n = { ...this.caixaMovimento }
                
                this.selectedsFaturas.forEach((fatura) => {
                    faturas.l.push({
                        id_parcela: fatura.id_parcela,
                        data_recebimento: moment(this.caixaMovimento.data_movimento).format('yyyy-MM-DD hh:mm.ss'),
                        valor_saldo: Number(fatura.liquidar),
                        desconto: Number(fatura.desconto),
                        acrescimo: Number(fatura.acrescimo),
                        tipo: fatura.tipo
                    })
                })         

                axios["post"](`${baseApiUrl}/caixa/liquidacao`, faturas)
                .then(() => {
                    this.$toasted.global.defaultSuccess('Liquidação efetuada com sucesso.')
                    
                    this.pesquisaFaturas()
                    this.loadCaixa({id_caixa: idCaixa})
                    this.showModalLiquidar = false
                })
                .catch(err => {
                    showError(err)
                })

            } else { this.$toasted.info('A data de liquidação não pode ser menor do que a data do caixa.'); return;} 
            
            
        },

        resetCixaMovto() {
            this.caixaMovimento = {
                id_caixa: 0,
                id_plano_contas: 0,
                data_movimento: moment().format('YYYY-MM-DD'),

                cheques: [],
                cartoes: [],
                depositosBancario: [],

                totalCheques: 0,
                totalCartoes: 0,
                totalDepositos: 0,
                totalDinheiro: 0,

                valor_recebido: 0,
                valor_pago: 0,
                totalGeral: 0,

                historico: '',

                tipo_movimento: 'L',
            }
        },

        gravarDeposito() {
            let totalCheques = this.caixaMovimento.totalCheques 
            let totalCartoes = this.caixaMovimento.totalCartoes 
            let totalDepositos = this.caixaMovimento.totalDepositos 

            if (this.depositoBancario.id_conta_bancaria === 0) { this.$toasted.global.defaultError({msg: 'Selecione a conta bancária.'}); return; }
            if (this.depositoBancario.valor <= 0) { this.$toasted.global.defaultError({msg: 'Valor deve ser maior que zero.'}); return; }  
           
           if ((totalDepositos + this.depositoBancario.valor) > (this.liquidacao.totalLiquidacaoAbs - totalCheques - totalCartoes) ) { 
                this.$toasted.global.defaultError({msg: 'Total de numerários maior que o valor da liquidação.'}) 
                return 
            }

            const c = this.contasBancarias.find(obj => {
                return obj.id_conta_bancaria === this.depositoBancario.id_conta_bancaria;
            });

            this.depositoBancario.nome_conta = c.text

            this.caixaMovimento.depositosBancario.push({ ...this.depositoBancario })
            
            this.calculaDepReceb() 
            this.calculaDinheiro()

            this.depositoBancario = {
                id_conta_bancaria: 0,
                valor: 0
            }
        },

        gravaCartao() {
            let totalCheques = this.caixaMovimento.totalCheques 
            let totalCartoes = this.caixaMovimento.totalCartoes 
            let totalDepositos = this.caixaMovimento.totalDepositos 

            if (this.cartao.qtde_parcelas < 1) { this.$toasted.global.defaultError({msg: 'Informe a quantidade de parcelas.'}); return; }
            if (this.cartao.id === 0) { this.$toasted.global.defaultError({msg: 'Escolha uma administradora de cartão.'}); return; }
            if (this.cartao.id_cartao_bandeira === 0) { this.$toasted.global.defaultError({msg: 'Escolha uma condição.'}); return; }
            if (this.cartao.valor <= 0) { this.$toasted.global.defaultError({msg: 'Valor deve ser maior que zero.'}); return; }
            if ((totalCartoes + this.cartao.valor) > (this.liquidacao.totalLiquidacaoAbs - totalCheques - totalDepositos ) ) { 
                this.$toasted.global.defaultError({msg: 'Total de numerários maior que o valor da liquidação.'}) 
                return 
            }

            const a = this.administradoras.find(obj => {
                return obj.id_cartao_adm === this.cartao.id;
            });

            const c = this.cartaoCondicoes.find(obj => {
                return obj.id_cartao_bandeira === this.cartao.id_cartao_bandeira;
            });

            if (!this.cartao.qtde_parcelas || this.cartao.qtde_parcelas < c.num_parc_min || this.cartao.qtde_parcelas > c.num_parc_max) { 
                this.$toasted.global.defaultError({msg: `Esta condição só permite quantidade de ${c.num_parc_min} até ${c.num_parc_max} parcelas.`}); return; 
            }

            this.cartao.descricao = `${a.descricao} (${c.descricao})`

            this.caixaMovimento.cartoes.push({ ...this.cartao })
            
            this.calculaCartao() 
            this.calculaDinheiro()

            this.cartao= {
                id: 0,                
                id_cartao_bandeira: 0,
                qtde_parcelas: 1,
                autorizacao: '',
                valor: 0,
                descricao: ''
            }
        },

        gravaCheque() {
            let totalCheques = this.caixaMovimento.totalCheques 
            let totalCartoes = this.caixaMovimento.totalCartoes 
            let totalDepositos = this.caixaMovimento.totalDepositos 

            if (this.cheque.num_cheque < 1) { this.$toasted.global.defaultError({msg: 'Informe numero do cheque.'}); return; }
            if (this.cheque.num_banco < 1) { this.$toasted.global.defaultError({msg: 'Informe numero do banco.'}); return; }
            if (this.cheque.agencia < 1) { this.$toasted.global.defaultError({msg: 'Informe a agencia.'}); return; }
            if (this.cheque.conta < 1) { this.$toasted.global.defaultError({msg: 'Informe a conta.'}); return; }
            if (this.cheque.valor <= 0) { this.$toasted.global.defaultError({msg: 'Valor deve ser maior que zero.'}); return; }
            
            if ((totalCheques + this.cheque.valor) > (this.liquidacao.totalLiquidacaoAbs - totalCartoes - totalDepositos ) ) { 
                this.$toasted.global.defaultError({msg: 'Total de numerários maior que o valor da liquidação.'}) 
                return 
            }

            this.cheque.descricao = `Nº ${this.cheque.num_cheque} (AG: ${this.cheque.agencia} CC: ${this.cheque.conta})`

            this.caixaMovimento.cheques.push({ ...this.cheque })
            
            this.calculaCheque() 
            this.calculaDinheiro()

            this.cheque= {
                id: 0,
                id_caixa_movimento: 0,
                num_cheque: '',
                agencia: '',
                conta: '',
                cpf_cnpj_emissor: '',
                nome_emissor: '',
                vencimento: moment().format('yyyy-MM-DD'),
                valor: 0,
            }
        },
        
        pesquisaFaturas() {
            axios.get(`${baseApiUrl}/caixa/liquidacao`, {
                params: {
                    dataInicial: this.fatDataIni,
                    dataFinal: this.fatDataFim,
                    pagarReceber: this.fatPagarReceber,
                    parcial: this.parcial ? this.parcial.idpessoa || undefined : undefined,
                    page: this.pageFaturas,
                    status: this.fatStatus,
                    filial: this.fatFilial
                }
            })
            .then(res => {                
                if( res.data.count  === 0 ) this.$toasted.global.nadaEncontrado()


                this.faturas = res.data.data                
                this.countFatura = res.data.count 
                this.limitFatura = res.data.limit                
            })
            .catch(err => {
                showError(err)
            })            
        }, 
        onRowSelectedFaturas(s) { 
            this.selectedsFaturas = s
            this.selectedsFaturas.forEach((fatura)=>{
                if (fatura.valor_liquido === 0) fatura.valor_liquido = fatura.saldo
                if (fatura.liquidar === 0) fatura.liquidar = fatura.saldo 
            })
        },

        selectAllRowsFaturas() {
            this.faturas.forEach((item) => {
                this.$set(item, 'selected', true)
                this.selectedsFaturas = [ ...this.selectedRows ]
                this.selectedsFaturas.forEach((fatura)=>{
                    if (fatura.valor_liquido === 0) fatura.valor_liquido = fatura.saldo
                    if (fatura.liquidar === 0) fatura.liquidar = fatura.saldo 
                })
            })
        },

        clearSelectedFaturas() {
            this.selectedRows.forEach((item) => {
                this.$delete(item, 'selected')
                this.selectedsFaturas = []
            })
        },

        tbodyRowClass(item) {
            /* Style the row as needed */
            if(item.selected){
                return ["b-table-row-selected", "table-primary", "cursor-pointer"]
            } else {
                return ["cursor-pointer"]
            }
        },

        rowClicked(item) {
            if(item.selected){      
                this.$set(item, 'selected', false)

                this.selectedsFaturas = [ ...this.selectedRows ]
                this.selectedsFaturas.forEach((fatura)=>{
                    if (fatura.valor_liquido === 0) fatura.valor_liquido = fatura.saldo
                    if (fatura.liquidar === 0) fatura.liquidar = fatura.saldo 
                })
            }else{
                this.$set(item, 'selected', true)

                this.selectedsFaturas = [ ...this.selectedRows ]
                this.selectedsFaturas.forEach((fatura)=>{
                    if (fatura.valor_liquido === 0) fatura.valor_liquido = fatura.saldo
                    if (fatura.liquidar === 0) fatura.liquidar = fatura.saldo 
                })
            }


        },

        onSelect(pessoa) {
            this.parcial = pessoa.idpessoa
        },

        loadCaixas() { 
            axios.get(`${baseApiUrl}/caixa/consulta`, {
                params: {
                    idUsuario: this.idUsuario,
                    dataIni: this.dataIni,
                    dataFim: this.dataFim,
                    status: this.status
                }
            })
            .then(res => {
                this.caixas = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })
            .catch(err => {
                this.caixas = []
                showError(err)
            })   
        },

        opcoesCaixas() {

        },

        novoCaixa() {
            this.loadFiliais()
            this.showModalAbrirCx = true
        },

        abrirCaixa() {
            if (moment(this.dataNovoCaixa).isValid()) {
                axios.get(`${baseApiUrl}/caixa/abrir/?dataCaixa=${this.dataNovoCaixa}&filial=${this.filialNovoCaixa}` )
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCaixas()
                    this.showModalAbrirCx = false
                })
                .catch(err => {
                    showError(err)
                })
            } else {
                showError('A data de abertuda de caixa é inválida.')
            }
        },

        verificaCaixaLogado() {
            const c = localStorage.getItem('__lancamentos_caixa')
            if (c) {
                this.loadFiliais()
                this.loadPessoas()
                this.loadCaixa({id_caixa: JSON.parse(c).idCaixa})
            } 
        },
        
        logarCaixa(caixa) {
            axios.post(`${baseApiUrl}/caixa/logar/?idCaixa=${caixa.id_caixa}&idUsuario=${caixa.usu_cad}`)
            .then(()=> {
                localStorage.setItem('__lancamentos_caixa', 
                    JSON.stringify(
                        {
                            idCaixa: caixa.id_caixa, 
                            idUsuario: caixa.usu_cad,
                            idFilial: caixa.id_filial,
                            dataCaixa: caixa.data_caixa
                        }
                    )
                )                
                this.$toasted.success('Caixa logado com sucesso.')

                this.loadFiliais()
                this.loadPessoas()
                this.loadCaixa(caixa)
            })  
            .catch(err => {
                showError(err)
            }) 
        },

        reset() {
            this.caixa = {
                movimentos: []
            }
            this.loadCaixas()
            this.mode = 'new'
            this.show = true
        },

        loadCaixa(cx) {
            axios.get(`${baseApiUrl}/caixa/${cx.id_caixa}`)
            .then(res => {
                this.caixa = { ...res.data.caixa }
                this.caixa.movimentos = res.data.movimentos ? [ ...res.data.movimentos ] : []
                this.dataCaixa = moment(this.caixa.data_caixa).format('DD/MM/YYYY')
                this.caixaMovimento.data_movimento = moment(this.caixa.data_caixa).format('yyyy-MM-DD')
                
                this.show = false
                this.mode = 'save'
            })
            .catch( err =>{
                showError(err)
            })  
        },
        
        desejaExcluirCaixa(id) {
            this.idCaixa = id
            this.moldalDesejaExcluirCaixa = true
        },

        removeCaixa() {
            axios['delete'](`${baseApiUrl}/caixa/${this.idCaixa}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()                    
                    this.loadCaixas()
                    this.moldalDesejaExcluirCaixa = false
                })
            .catch(err => {
                showError(err)
            })
        },
        
        loadUsuarios() {
            const url = `${baseApiUrl}/usuarios`
            axios.get(url).then(res => {
                this.usuarios = res.data.map(usuario => {
                    return { ...usuario,  value: usuario.idusuario, text: `${usuario.idusuario} | ${usuario.nome}`}
                })

                this.usuarios.unshift({value: 0, text: 'TODOS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },

        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'TODAS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },

        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data.map(pessoa => {
                    return { ...pessoa, value: pessoa.idpessoa, text: `| ${pessoa.razao_social}` }
                })
            })
        },

        // Liquidar
        carregaFaturas() {
            let receber = 0
            let pagar = 0
            let acrescimosReceber = 0
            let descontosReceber = 0
            let acrescimosPagar = 0
            let descontosPagar = 0

            this.selectedsFaturas.forEach((fatura)=>{

                fatura.liquidar = (fatura.valor_liquido + fatura.desconto) - fatura.acrescimo

                if (fatura.tipo === 'Receber') {
                    receber = receber + fatura.valor_liquido
                    acrescimosReceber = acrescimosReceber + fatura.acrescimo
                    descontosReceber = descontosReceber + fatura.desconto
                }
                if (fatura.tipo === 'Pagar') {
                    pagar = pagar + fatura.valor_liquido
                    acrescimosPagar = acrescimosPagar + fatura.acrescimo
                    descontosPagar = descontosPagar + fatura.desconto
                }
            })

            this.liquidacao.totalReceber = receber
            this.liquidacao.totalPagar = pagar

            this.calculaTotal()
        },

        calculaTotal() {
            const t = this.liquidacao.totalReceber - this.liquidacao.totalPagar
            this.liquidacao.totalLiquidacao = t
            this.liquidacao.totalLiquidacaoAbs = Math.abs(t) 
            
            if (t === 0) {
                this.liquidacao.tipo = 'Valor Recebto/Pagto'
                this.caixaMovimento.tipo_movimento = 'L'
            }
            if (t > 0) {
                this.liquidacao.tipo = 'Valor do Recebimento'
                this.caixaMovimento.tipo_movimento = 'C'}
            if (t < 0) {
                this.liquidacao.tipo = 'Valor do Pagamento'                
                this.caixaMovimento.tipo_movimento = 'D'
            }

            this.calculaDinheiro()
        },

        calculaDinheiro(){
            this.caixaMovimento.totalDinheiro = this.liquidacao.totalLiquidacaoAbs - 
                (this.caixaMovimento.totalCheques +this.caixaMovimento.totalCartoes + this.caixaMovimento.totalDepositos)

            this.depositoBancario.valor = this.caixaMovimento.totalDinheiro
            this.cartao.valor = this.caixaMovimento.totalDinheiro
            this.cheque.valor = this.caixaMovimento.totalDinheiro
            
        },
        
        // salvarServico() {
        //     if (this.servico.qtde < 1) {
        //         this.$toasted.error('Informe a quantidade do serviço.') 
        //     } else {
        //         const s = this.servicos.find(obj => {
        //             return obj.id_servico === this.servico.id_servico;
        //         });
        //         this.servico.descricao = s.descricao
                
        //         this.modeServico === 'edit' ? this.orcamento.servicos.splice(this.index, 1, {...this.servico}) : this.orcamento.servicos.push({...this.servico})  
        //         this.resetServico()             
        //     }
        // },
        fluxoCaixa() {
            this.fluxoDataInicialF = moment(this.fluxoDataInicial).format('DD/MM/YYYY')
            this.fluxoDataFinalF = moment(this.fluxoDataFinal).format('DD/MM/YYYY')
            const url = `${baseApiUrl}/fluxocaixa/?dataIni=${this.fluxoDataInicial}&dataFim=${this.fluxoDataFinal}&idFilial=${this.fluxoFilial}`  
            axios.get(url).then(res => {
                this.loadFiliais()
                this.dadosFluxoCaixa = res.data
            })
            .catch(err => {
                showError(err)
            })
        },

        fecharCaixa() {            
            const id_caixa = this.caixa.id_caixa
            const vlr_din =  this.fecharCx.dinheiro
            const vlr_ch =  this.fecharCx.cheques

            if ((vlr_din < 0) || (vlr_ch < 0)) {
                showError('Os valores físicos não podem ser menor que zero!')

            } else {

                const url = `${baseApiUrl}/caixa/fechar/?id_caixa=${id_caixa}&vlr_din=${vlr_din}&vlr_ch=${vlr_ch}`    
    
                axios.get(url)
                .then(async () => {
                    localStorage.removeItem('__lancamentos_caixa')
                    this.show = true
                    this.$toasted.global.defaultSuccess('Caixa fechado com sucesso!')
                    this.loadCaixas()
                    this.showModalFecharCx = false
                    this.fecharCx.dinheiro = 0
                    this.fecharCx.cheques = 0
                })  
                .catch( err =>{
                    showError(err)
                })  
            }            
        },

        reabrirCaixa() {
            const id_caixa = this.caixa.id_caixa

            const url = `${baseApiUrl}/caixa/reabrir/?id_caixa=${id_caixa}`    

            axios.get(url)
            .then(() => {
                this.$toasted.global.defaultSuccess('Caixa reaberto com sucesso!')
                this.loadCaixa(this.caixa)
                this.cxFechado = false
                this.showModalReabrirCx = false                
            }) 
            .catch( err =>{
                showError(err)
            })  
            
        },

        salvarPdf(nome, id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.1,0.1,0.1,0.1],
                filename: `${nome}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },

        excluirDeposito(item) {
            const idx = this.caixaMovimento.depositosBancario.map((e)=>{ return e.id; }).indexOf(item.id);
            this.caixaMovimento.depositosBancario.splice(idx, 1)

            this.calculaDepReceb() 
            this.calculaDinheiro() 
        },
        excluirCheque(item) {
            const idx = this.caixaMovimento.cheques.map((e)=>{ return e.id; }).indexOf(item.id);
            this.caixaMovimento.cheques.splice(idx, 1)

            this.calculaCheque() 
            this.calculaDinheiro() 
        },
        excluirCartao(item) {
            const idx = this.caixaMovimento.cartoes.map((e)=>{ return e.id; }).indexOf(item.id);
            this.caixaMovimento.cartoes.splice(idx, 1)

            this.calculaCartao() 
            this.calculaDinheiro() 
        },

        calculaCartao() {
            if (!this.caixaMovimento.cartoes.length) { 
                this.caixaMovimento.totalCartoes = 0
            } else {
                this.caixaMovimento.totalCartoes = 0 
                this.caixaMovimento.cartoes.forEach((_, i) =>{ 
                    this.caixaMovimento.cartoes[i].id = i + 1 
                    this.caixaMovimento.totalCartoes = this.caixaMovimento.totalCartoes + this.caixaMovimento.cartoes[i].valor
                })
            }
        },
        calculaCheque() {
            if (!this.caixaMovimento.cheques.length) { 
                this.caixaMovimento.totalCheques = 0
            } else {
                this.caixaMovimento.totalCheques = 0 
                this.caixaMovimento.cheques.forEach((_, i) =>{ 
                    this.caixaMovimento.cheques[i].id = i + 1 
                    this.caixaMovimento.totalCheques = this.caixaMovimento.totalCheques + this.caixaMovimento.cheques[i].valor
                })
            }
        },
        calculaDepReceb() {
            if (!this.caixaMovimento.depositosBancario.length) { 
                this.caixaMovimento.totalDepositos = 0
            } else {
                this.caixaMovimento.totalDepositos = 0 
                this.caixaMovimento.depositosBancario.forEach((_, i) =>{ 
                    this.caixaMovimento.depositosBancario[i].id = i + 1 
                    this.caixaMovimento.totalDepositos = this.caixaMovimento.totalDepositos + this.caixaMovimento.depositosBancario[i].valor
                })
            }
        },
        loadContasBancarias() {
            const url = `${baseApiUrl}/contas_bancarias`

            axios.get(url).then(res => {
                this.contasBancarias = res.data.map(contaBancaria => {
                    return { ...contaBancaria, value: contaBancaria.id_conta_bancaria, text: `CC: ${contaBancaria.conta} | AG: ${contaBancaria.agencia} (${contaBancaria.nome_banco})` }
                })
            })
        },
        loadAdministradoras() {
            const url = `${baseApiUrl}/cartao_adm`

            axios.get(url).then(res => {
                this.administradoras = res.data.map(administradora => {
                    return { ...administradora, value: administradora.id_cartao_adm, text: `${administradora.id_cartao_adm} | ${administradora.descricao}` }
                })
            })
        },
        loadCondicoes() {
            const url = `${baseApiUrl}/cartao_bandeiras/?admin=${this.cartao.id}`

            axios.get(url).then(res => {
                this.cartaoCondicoes = res.data.map(cartaoCondicao => {
                    return { ...cartaoCondicao, value: cartaoCondicao.id_cartao_bandeira, text: `${cartaoCondicao.id_cartao_bandeira} | ${cartaoCondicao.descricao}` }
                })
            })
        },

        informacaoFatura(fatura) {
            const idFatura = fatura.id
            const tipo = (fatura.tipo === 'Pagar') ? 'contas_pagar' : 'contas_receber'
            const url = `${baseApiUrl}/${tipo}/${idFatura}`

            axios.get(url).then(res => {
                this.faturaDetalhe = { ...res.data }
                this.faturaDetalhe.data_competencia = moment(this.faturaDetalhe.data_competencia ).format('YYYY-MM-DD')   
                this.faturaDetalhe.data_documento = moment(this.faturaDetalhe.data_documento).format('YYYY-MM-DD')  
                this.faturaDetalhe.data_cad = moment(this.faturaDetalhe.data_cad ).format('DD/MM/YYYY HH:mm:ss')   
                this.faturaDetalhe.data_alt = moment(this.faturaDetalhe.data_alt).format('DD/MM/YYYY HH:mm:ss')
            })
        },

        carregaJuroDesconto(fatura) {
            this.fatura = fatura
        },

        calculaDescAcres(tipo) { 
            switch (tipo) {
                case 'pd':
                    this.fatura.desconto = (this.fatura.liquidar * this.fatura.porcDesconto) / 100
                    break;
                case 'pa':
                    this.fatura.acrescimo = (this.fatura.liquidar * this.fatura.porcAcrescimo) / 100
                    break;
                case 'vd':
                    this.fatura.porcDesconto = this.fatura.liquidar > 0 ? (this.fatura.desconto * 100) / this.fatura.liquidar : 0
                    break;
                case 'va':
                    this.fatura.porcAcrescimo= this.fatura.liquidar > 0 ? (this.fatura.acrescimo * 100) / this.fatura.liquidar : 0
                    break;
            }

            this.fatura.valor_liquido = (this.fatura.liquidar + this.fatura.acrescimo) - this.fatura.desconto 
        },

        infoMovimento (mov) {
            this.caixaMovimento = { ...mov }
            this.caixaMovimento.data_movimento = moment(mov.data_movimento).format('DD/MM/YYYY')

            axios['get'](`${baseApiUrl}/caixa_movimentos/composicao/?id_caixa_movimento=${mov.id_caixa_movimento}`)
                .then(res => {
                    this.movFaturas = [ ...res.data.faturas ]
                    this.movNumerarios = [ ...res.data.numerarios ]

                    let totalNumerarios = 0

                    res.data.numerarios.forEach((n)=>{
                        totalNumerarios += n.valor
                    })

                    this.movNumerarios.push({
                        tipo: 'Dinheiro',
                        descricao: '',
                        autorizacao: '',
                        valor:  Math.abs(this.caixaMovimento.valor_recebido - this.caixaMovimento.valor_pago) - totalNumerarios
                    })

                })
                .catch(showError)

            this.showInfoMovimento = !this.showInfoMovimento
        },     
        
        
    },
    watch: {
        page() {
            this.loadCaixas()
        },
        pageFaturas() {
            // this.pesquisaFaturas()
        },
        fluxoFilial() {
            this.fluxoCaixa()
        },
        'cartao.id': function() {
            this.loadCondicoes()
        }
    },
    mounted() {
        this.loadUsuarios(),
        this.verificaCaixaLogado()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .moeda-tabela-caixa {
        display: flex;
        box-shadow: 0 0 0 0;
        border: 0 none;
        outline: 0;
        background: transparent;
        width: 85px;
    }

    .faturas-numerarios {
        margin: -10px 0 -10px 0;
    }
    
    .saldos-conc {
        margin: 0 !important;
        text-align: center;
    } 
    .saldos-conc p {
        margin-top: -5px;
        font-size: 1rem;
        text-align: center;
    } 
    .saldos-conc h4 {
        margin: -15px 0 5px 0;
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
    } 

    .saldos-conc-mov {
        margin: 0 0 10px 0 !important;
        text-align: center;
        display: flex;
        align-content: stretch;
    } 
    .saldos-conc-mov p {
        margin-top: -5px;
        font-size: 1rem;
        text-align: center;
    } 
    .saldos-conc-mov h4 {
        margin: -5px 0 5px 0;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
    } 

    .saldos-conc-mov-li {
        width: 100%;
    }

    .saldos-conc-mov-li i {
        font-size: 1.5rem;
    }

    .hidden_header {
        display: none;
    }

    .moeda-tabela-liquidar {
        font-size: 1.5rem;
        margin: -10px 0 -10px 0;
    }
</style>