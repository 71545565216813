<template>
    <div class="cadastro-contas-bancarias">

        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                >
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div> 

        <b-card class="cadastros-card-corpo">
            <b-row>
                <b-col md="4" lg="3" sm="12" > 
                    <h4>{{contaBancaria.nome_conta || 'Selecione a conta'}}</h4>
                    <h6>{{contaBancaria.nome_banco || 'Banco...'}}</h6>
                </b-col>

                <b-col md="8" lg="9" sm="12" class="cadastros-card-corpo">
                    <b-row>
                        <!-- <div style="height: 30px" >                         -->
                            <b-col class="d-flex justify-content-end">
                                <b-button-toolbar>
                                    <b-button-group class="mx-1">
                                        <b-input-group>
                                            <b-form-input type="date" v-model="dataConciliacao"></b-form-input>
                                        </b-input-group>
                                                             
                                        <b-button variant="primary" @click="conciliarMovimentos('c')">Conciliar</b-button>
                                        <b-dropdown right variant="info">
                                            <b-dropdown-item @click="conciliarMovimentos('d')">Desconciliar selecionados</b-dropdown-item>
                                        </b-dropdown>
                                    </b-button-group>                                         
                                    <b-button-group class="mx-1">
                                        <b-input-group prepend="Período">
                                            <b-form-input type="date" v-model="vencimentoInicial"></b-form-input>
                                            <b-form-input type="date" v-model="vencimentoFinal"></b-form-input>
                                            <b-input-group-append>
                                            <b-button @click="loadMovimentosConta(contaBancaria)" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-button-group> 
                                </b-button-toolbar>
                            </b-col>
                        <!-- </div> -->
                    </b-row>

                </b-col>
            </b-row>

            <b-row>
                <b-col md="4" lg="3" sm="12" > 
                    <b-list-group>
                        <b-list-group-item 
                            href="#" @click="loadMovimentosConta(c)"
                            :active="contaBancaria.id_conta_bancaria === c.id_conta_bancaria"
                            v-for="(c, id_conta_bancaria) in contasBancarias" v-bind:key="id_conta_bancaria"
                            variant="light"
                        >
                            <h5>{{c.agencia}}-{{c.agencia_dv}} | {{c.conta}}-{{c.conta_dv}}</h5> 
                            <p style="font-size: 0.8rem; margin: 0 0 0 0"><strong>{{c.nome_conta}}</strong></p>

                            <b-list-group class="saldos-conc" v-if="contaBancaria.id_conta_bancaria === c.id_conta_bancaria">
                                <b-list-group-item variant="success">
                                    <p>Saldo Bancário</p>
                                    <h4>{{contaBancaria.saldoConciliado}}</h4>
                                </b-list-group-item>

                                <b-list-group-item variant="danger">
                                    <p>Saldo Disponível</p>
                                    <h4>{{contaBancaria.saldo}}</h4>
                                </b-list-group-item>

                            </b-list-group>

                        </b-list-group-item>
                    </b-list-group>
                </b-col>

                <b-col md="8" lg="9" sm="12" > 

                    <hr style="margin: 0">

                    <b-table 
                        hover 
                        striped 
                        :items="contaMovimentos" 
                        :fields="fields" 
                        borderless 
                        sort-icon-left 
                        small                        
                        :select-mode="'multi'"
                        @row-selected="onRowSelected"                                
                        ref="selectableTableMovimentos"                            
                        selectable
                    >  

                        <template v-slot:cell(actions)="data">
                            <div class="d-flex justify-content-end">
                                <b-dropdown variant="info" right class="mx-1">
                                    <b-dropdown-item v-if="data.item.e_aditivo !== 'S'" href="#" @click="aditivoContrato(data.item)"><i class="fa fa-pencil mr-1"></i> Alterar</b-dropdown-item>
                                    <b-dropdown-item href="#" @click="loadModelos(data.item)"><i class="fa fa-trash mr-1"></i> Excluir</b-dropdown-item>
                                </b-dropdown>                   
                            </div>
                        </template>

                        <template #head(selected) v-if="contaMovimentos.length > 0">                                    
                            <template v-if="contaMovimentos.length === selecteds.length">
                                <div style="margin-bottom: -5px">
                                    <span  aria-hidden="true" @click="clearSelected"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                                </div>
                            </template>
                            <template v-if="contaMovimentos.length !== selecteds.length"> 
                                <div style="margin-bottom: -5px">
                                    <span aria-hidden="true" @click="selectAllRows"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                                </div>
                            </template>
                        </template>

                        <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                                <span aria-hidden="true"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check"></i></span>
                            </template>
                            <template v-else>
                                <span aria-hidden="true">&nbsp;</span>
                            </template>
                        </template>

                    </b-table>
                    <b-pagination 
                        align="center" 
                        size="md" 
                        v-model="page"  
                        :total-rows="count" 
                        :per-page="limit" 
                        first-number 
                        last-number>
                    </b-pagination>
                </b-col>
            </b-row>
            
        </b-card>  
       
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
const moment = require('moment')
// import {VMoney} from 'v-money'
import axios from 'axios'

export default {
    name: 'CadConciliacaoBancaria',
    data: function() {
        return {
            dataConciliacao: moment().format('yyyy-MM-DD'),
            vencimentoInicial: moment('2023-01-01').format('yyyy-MM-DD'),
            vencimentoFinal: moment().format('yyyy-MM-DD'),

            movimentosSelecionados: [],
            selecteds: [],
            
            mode: 'new',
            contaBancaria: {
                id_conta_bancaria: 0,
                data_saldo: '',
                saldo: 0,
                saldoConciliado: 0
            },
            contasBancarias: [],
            contaMovimentos: [],

            saldoAnterior: 0,
            saldoConcAnterior: 0,
            page: 1,
            count: 0,
            limit: 0,
           
            show: true,
            fields: [  
                {key: 'selected', label: '', sortable: false},              
                {key: 'data_movimento', label: 'Movimento', sortable: true, 
                    formatter: value => {
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'data_conciliacao', label: 'Conciliação', sortable: true, 
                    formatter: value => {
                        return moment(value, "yyyy-MM-DD").isValid() ? moment(value).format('DD/MM/YYYY') : ''
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },
                {key: 'historico', label: 'Histórico', sortable: true},
                {key: 'tipo', label: '', sortable: false, 
                    formatter: value => {
                        switch (value) {
                            case 'E':
                                return 'Entrada'                        
                            case 'S':
                                return 'Saída' 
                        }
                    }
                },
                {key: 'actions', label: ''}
            ], 
            
            money: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },

            percent: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                suffix: ' %',
                precision: 2,
                masked: false
            }, 
        }
    },
    // directives: {
    //     money: VMoney
    // },
    methods: {  
        onRowSelected(s) { 
            this.selecteds = s
            this.movimentosSelecionados = this.selecteds.map(c=> {return c.id_conta_bancaria_movimento}) 
        },
        selectAllRows() {
            this.$refs.selectableTableMovimentos.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTableMovimentos.clearSelected()
        },

        novaFatura() {
            this.mode = 'save'
            this.show = false
        },      
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        loadContasBancarias() { 
            axios.get(`${baseApiUrl}/contas_bancarias`)
            .then(res => {
                this.contasBancarias = res.data
            })
            .catch(err => {
                showError(err)
            })            
        },       
        reset() {
            this.mode = 'new'


            this.contaBancaria = {
                ativo: 'S',
                convenios: []
            }

            this.loadContasBancarias()
            this.show = !this.show
        },
        save() {
            const method = this.contaBancaria.id_conta_bancaria ? 'put' : 'post'
            const idcontabancaria = this.contaBancaria.id_conta_bancaria ? `/${this.contaBancaria.id_conta_bancaria}` : '' 

            axios[method](`${baseApiUrl}/contas_bancarias${idcontabancaria}`, this.contaBancaria)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(err => {
                showError(err)
            })
        },
        desejaRemover(c) {
            this.idConta = c.id_conta_bancaria
            this.modalRemConta = true
        },
        remove() {
            axios.delete(`${baseApiUrl}/contas_bancarias/${this.idConta}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.loadContasBancarias()
                this.modalRemConta = false
            })
            .catch(err => {
                showError(err)
            })
        },
        conciliarMovimentos(t) {

            let conciliacao = {
                data: t === 'c' ? this.dataConciliacao : null,
                movimentos: [ ...this.movimentosSelecionados ]
            }

            axios['post'](`${baseApiUrl}/contas_bancarias/conciliar`, conciliacao)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.loadMovimentosConta(this.contaBancaria)
            })
            .catch(err => {
                showError(err)
            })
        },
        loadMovimentosConta(ct) {
            if (ct.id_conta_bancaria > 0) {

                this.contaBancaria.id_conta_bancaria = ct.id_conta_bancaria
                this.contaBancaria.nome_conta = ct.nome_conta
                this.contaBancaria.nome_banco= ct.nome_banco

    
                const id = ct.id_conta_bancaria
                const mIni = this.vencimentoInicial
                const mFim = this.vencimentoFinal
    
                const url = `${baseApiUrl}/contas_bancarias/movimentos/?idConta=${id}&mIni=${mIni}&mFim=${mFim}`   
                axios.get(url).then(res => {
                    this.contaMovimentos = [ ...res.data.movimentos ]
                    this.saldoAnterior = res.data.saldo
                    this.saldoConcAnterior = res.data.saldoConciliado  
                    
                    this.calculaSaldos()
                })
                .catch(err => {
                    this.contaMovimentos = []
                    showError(err)
                })                
            }
        },

        calculaSaldos() {
            this.contaBancaria.saldo = 0
            this.contaBancaria.saldoConciliado = 0

            this.contaMovimentos.forEach(m=>{
                if ( moment(m.data_conciliacao, "yyyy-MM-DD", true).isValid() ) {
                    this.contaBancaria.saldoConciliado = this.contaBancaria.saldoConciliado + m.valor
                    this.contaBancaria.saldo = this.contaBancaria.saldo + m.valor
                } else {
                    this.contaBancaria.saldo = this.contaBancaria.saldo + m.valor
                }
            })

            this.contaBancaria.saldo = this.contaBancaria.saldo + this.saldoAnterior
            this.contaBancaria.saldoConciliado = this.contaBancaria.saldoConciliado + this.saldoConcAnterior
        }


    },
    watch: {
        page() {
            this.loadContasBancarias()
        },
    },
    mounted() {
        this.loadContasBancarias()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    .saldos-conc {
        margin: 5px -10px 0 -10px !important;
        text-align: center;
    } 
    .saldos-conc p {
        margin-top: -12px;
        font-size: 0.8rem;
        text-align: center;
    } 
    .saldos-conc h4 {
        margin: -17px 0 -10px 0;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
    } 



</style>