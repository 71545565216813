<template>
    <div class="notify-area">
        <div class="notify-button">
            <i class="fa fa-bell-o"></i>
        </div>
    </div>
</template>

<script>
// import { userKey } from '@/global'
import { mapState } from 'vuex'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'

export default {
    name: 'Notifications',
    computed: mapState(['user']),
    data: function() {
        return {
            testes: '',
            retornosNotificados: [],
            nfseNotificadas: []
        }
    },
    methods: {
        notificacoesRetornos() {  

            setInterval(()=>{
                
                axios['get'](`${baseApiUrl}/contasbancariasretorno`)
                .then(async res => {                   
    
                    if (res.data.length > 0) {
                        const notificacoes = [ ...res.data ]
    
                        notificacoes.forEach(item =>{

                            if (!this.retornosNotificados.some(e => e.protocolo === item.protocolo && e.statusAtual === item.statusAtual)) {
                                this.retornosNotificados.push(item)

                                let sAnt = ''
                                let sAtu = ''
        
                                switch (item.statusAnterior) {
                                    case 'P':
                                        sAnt = 'PROCESSADO'
                                        break;
                                    case 'E':
                                        sAnt = 'ENVIADO'
                                        break;
                                    case 'F':
                                        sAnt = 'FALHA'
                                        break;
                                }
        
                                switch (item.statusAtual) {
                                    case 'P':
                                        sAtu = 'PROCESSADO'
                                        break;
                                    case 'E':
                                        sAtu = 'ENVIADO'
                                        break;
                                    case 'F':
                                        sAtu = 'FALHA'
                                        break;
                                }
        
                                const h = this.$createElement
                                const vNodesMsg = h(
                                    'p',
                                    [            
                                        'Arquivo ',
                                        h('strong', `${item.protocolo}`),
                                        ' atualizou!',
        
                                        h('br'),
                                        'Mudou de ',
                                        h('strong', `${sAnt}`),
                                        ' para ',
                                        h('strong', `${sAtu}.`),
                                        h('br'),
                                        h('strong', 'Mensagem: '),
                                        `${item.mensagem}`
                                        
                                    ]
                                )

                                this.testes = vNodesMsg
        
                                // this.$bvToast.toast([vNodesMsg], 
                                // {
                                //     title: 'Arquivo de Retorno Atualizado',
                                //     // autoHideDelay: 55000,
                                //     appendToast: false,
                                //     noAutoHide: true,
                                //     variant: item.variante,
                                //     solid: true,
                                //     toast: 'b-toaster-bottom-right'
                                // })
                            }

                        })
    
                    }
                })
                .catch(err=>{
                    showError(err)
                })
            }, (60 * 1000) * 2)


        },

        notificacoesNFSe() {  

            setInterval(()=>{
                
                axios.get(`${baseApiUrl}/gerarnfse/verificar`, {
                    params: {
                        consultaIndividual: 'N'
                    }
                })
                .then(async res => {
                    if (res.data.length > 0) {
                        const notificacoes = [ ...res.data ]
    
                        notificacoes.forEach(item =>{

                            if (!this.nfseNotificadas.some(e => e.idNf === item.idNf && e.statusAtual === item.statusAtual)) {
                                this.nfseNotificadas.push(item)                                
        
                                const h = this.$createElement
                                const vNodesMsg = h(
                                    'p',
                                    [            
                                        'NFS-e Id ',
                                        h('strong', `${item.idNf}`),
                                        ' atualizou!',
        
                                        h('br'),
                                        'Mudou de ',
                                        h('strong', `${item.statusAnterior}`),
                                        ' para ',
                                        h('strong', `${item.statusAtual}.`),
                                        h('br'),
                                        h('strong', 'Mensagem: '),
                                        `${item.mensagem}`
                                        
                                    ]
                                )
        
                                this.$bvToast.toast([vNodesMsg], 
                                {
                                    title: 'NFS-e Atualizada',
                                    // autoHideDelay: 55000,
                                    appendToast: false,
                                    noAutoHide: true,
                                    variant: item.variante,
                                    solid: true,
                                    toast: 'b-toaster-bottom-right'
                                })
                            }

                        })
    
                    }
                }) 
                .catch(err => {
                    showError(err)
                })

            }, (60 * 1000) * 2)

        },

        verificarStatusBoletos() {  

            setInterval(()=>{    

                axios['get'](`${baseApiUrl}/gerarboleto/verificar`)

            }, (60 * 1000) * 2)

        }
    },
    mounted() {
        this.notificacoesRetornos(),
        this.notificacoesNFSe()       
        this.verificarStatusBoletos()       
    }
}
</script>

<style>
    .notify-area {
        align-self: initial;
        height: 100%;
    }

    .notify-area:hover {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .notify-button {
        display: flex;
        font-size: 1.2rem;
        align-items: center;
        padding: 27px 20px;
    }  
    
</style>
