<template>
    <div class="cadastro-funil">
        <div class="acoesTopo" v-if="!showPainel">  
            <b-button variant="danger" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                @click="reset">
                    <i class="fa fa-times button-icons-acoes"></i>
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Excluir'"
                @click="desejaExcluirFunil">
                    <i class="fa fa-trash button-icons-acoes"></i>
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                @click="save">
                    <i class="fa fa-check button-icons-acoes"></i>
            </b-button>                        
            <b-button variant="success" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Opções de Funil'"
                @click="opcoesFunil">
                    <i class="fa fa-cogs button-icons-acoes"></i>
            </b-button> 
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Relatórios'"
                @click="relatorios">
                    <i class="fa fa-file-text-o button-icons-acoes"></i>
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Nova Fatura'"
                @click="novoFunil">
                    <i class="fa fa-plus button-icons-acoes"></i>
            </b-button> 
        </div>

        <div class="acoesTopo" v-if="showPainel">             
            <b-button variant="primary" class="botoesAcao" v-if="showPainel" v-b-tooltip.hover.bottomleft="'Voltar'"
                @click="()=> {showPainel = false; show=true; mode='new';}">
                    <i class="fa fa-arrow-left button-icons-acoes"></i>
            </b-button>         
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show && !showPainel">
            
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        :readonly="mode === 'remove'"
                        placeholder="Informe parte do nome do funil..." />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="A">Abertos</b-form-radio>
                            <b-form-radio value="C">Concluídos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>       

        <b-sidebar id="sidebar-right-orcamento" title="Orçamentos & Contratos" width=500px right shadow backdrop>
            <div class="px-3 py-2">
                <h5><strong>{{negocio.razao_social}}</strong></h5>
                <p>Celular: ({{negocio.ddd_celular}}) {{negocio.celular}} <br> Telefone: ({{negocio.ddd_telefone}}) {{negocio.telefone}}</p>
                
                <b-button-toolbar>  
                    <b-button-group class="mx-1">                  
                        <b-dropdown v-if="!showNovo" size="sm" variant="primary"  right text="Novo">
                            <b-dropdown-item @click="novoOrcamento(negocio)"><i class="fa fa-file-text-o mr-1"></i> Orçamento</b-dropdown-item>
                            <b-dropdown-item @click="novoContrato(negocio)"><i class="fa fa-gavel mr-1"></i> Contrato</b-dropdown-item>
                        </b-dropdown>
                        <b-button v-if="showNovo" @click="salvarOrcCont()" variant="success" size="sm">Salvar</b-button>
                        <b-button v-if="showNovo" @click="cancelarOrcCont()" variant="danger" size="sm">Cancelar</b-button>
                    </b-button-group>

                    <b-button-group v-if="!showNovo" class="mx-1">
                        <b-button size="sm">Relatório</b-button>
                    </b-button-group>
                </b-button-toolbar>
                <hr>

                <div v-if="!showNovo">
                    <!-- Contratos ******************* -->
                    <h6 class="mb-3"><strong>Contratos</strong></h6>
                    <b-list-group v-if="contratos.length > 0"> 
                        <b-list-group-item  href="#" class="flex-column align-items-start mb-2" v-for="(o, id_contrato) in contratos" v-bind:key="id_contrato">
                            <b-row>
                                <b-col md="3" sm="3">
                                    <p style="font-size: 1.5rem">{{o.num_contrato}}/{{o.sequencia}}</p>
                                    <p style="font-size: 1rem"><strong>{{o.e_aditivo === 'S' ? 'Aditivo' : 'Primário'}}</strong></p>
                                </b-col>
                                <b-col md="5" sm="5">
                                    <p  style="font-size: 1.1rem; margin-bottom: -10px">
                                        Data: {{o.data}} <br>
                                        Enc.: {{o.encerramento}}
                                    </p>
                                    <hr style="margin-bottom: 5px">                                    
                                    <p style="font-size: 1.2rem; margin-bottom: -5px">Total: <strong>{{o.servicos_total_liquido}}</strong></p>
                                </b-col>

                                <b-col md="2" sm="2">
                                </b-col>

                                <b-col md="2" sm="12">
                                    <b-button :id="`ttip-icont-aba-${o.id_contrato}`" size="sm" class="close toolbal-item-funil" variant="outline-light">
                                        <i style="font-size: 2rem; margin: 5px 10px 10px 0" class="fa fa-print"></i>
                                    </b-button>
                                    <b-tooltip :target="`ttip-icont-aba-${o.id_contrato}`" noninteractive>Imprimir</b-tooltip>

                                    <b-button @click="loadContrato(o.id_contrato)" :id="`ttip-econt-aba-${o.id_contrato}`" size="sm" class="close toolbal-item-funil" variant="outline-light">
                                        <i style="font-size: 2rem; margin: 0 10px 10px 0" class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-tooltip :target="`ttip-econt-aba-${o.id_contrato}`" noninteractive>Editar</b-tooltip>
                                </b-col>
                                
                            </b-row>
                        </b-list-group-item>
                    </b-list-group> 
                    <p v-else>Negócio não possui contratos...</p> 

                    <!-- Orcamentos ******************* -->
                    <h6 class="mb-3"><strong>Orçamentos</strong></h6>
                    <b-list-group v-if="orcamentos.length > 0">
                        <b-list-group-item href="#" class="flex-column align-items-start mb-2" v-for="(o, id_orcamento) in orcamentos" v-bind:key="id_orcamento">
                            <b-row >
                                <b-col md="3" sm="3">
                                    <p style="font-size: 1.5rem">{{o.num_orcamento}}</p>
                                </b-col>
                                <b-col md="4" sm="4">
                                    <p  style="font-size: 1.1rem; margin-bottom: -10px">{{o.data}}</p>
                                    <hr style="margin-bottom: 5px">
                                    Total de serviços <br>
                                    <p style="font-size: 1.2rem; margin-bottom: -5px"><strong>{{o.servicos_total_liquido}}</strong></p>
                                </b-col>

                                <b-col md="3" sm="3">
                                </b-col>

                                <b-col md="2" sm="12">
                                    <b-button :id="`ttip-iorc-aba-${o.id_orcamento}`" size="sm" class="close toolbal-item-funil" variant="outline-light">
                                        <i style="font-size: 2rem; margin: 5px 10px 10px 0" class="fa fa-print"></i>
                                    </b-button>
                                    <b-tooltip :target="`ttip-iorc-aba-${o.id_orcamento}`" noninteractive>Imprimir</b-tooltip>

                                    <b-button @click="loadOrcamento(o.id_orcamento)" :id="`ttip-eorc-aba-${o.id_orcamento}`" size="sm" class="close toolbal-item-funil" variant="outline-light">
                                        <i style="font-size: 2rem; margin: 0 10px 10px 0" class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-tooltip :target="`ttip-eorc-aba-${o.id_orcamento}`" noninteractive>Editar</b-tooltip>
                                </b-col>
                                
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>  
                    <p v-else>Negócio não possui orçamentos...</p>               
                </div>

                <div v-if="showNovo === 'orcamento'">
                    <h5 style="color: #6ca7ff">Novo Orçamento</h5>
                    <b-form>
                        <input id="orcamentos-id" type="hidden" v-model="orcamento.id_servico" />

                        <b-row> 
                            <b-col md="4" sm="12" v-if="orcamento.num_orcamento > 0">
                                    Nº
                                    <p style="font-size: 1.2rem"><strong>{{orcamento.num_orcamento}}</strong></p>
                            </b-col> 

                            <b-col md="4" sm="12">
                                    Data Orçamento
                                    <p style="font-size: 1.2rem"><strong>{{orcamento.data}}</strong></p>
                            </b-col>
                        </b-row> 
                        <b-row style="margin-top: -20px"> 
                            <b-col md="12" sm="12"> 
                                Nome / Razão Social                                
                                <b-form-input size="sm" id="orcamento-pessoa" type="text"
                                    :formatter="upperCase"
                                    v-model="orcamento.razao_social" required readonly
                                />
                                Vendedor
                                <b-form-select size="sm" id="vendedor-orcamento" v-model="orcamento.id_vendedor" :options="vendedores"/>
                            </b-col>
                        </b-row>                                                
                        <b-row> 
                            <b-col md="7" sm="12">
                                Plano de Contas
                                <b-form-select size="sm" id="orcamento-plano-conta" v-model="orcamento.id_plano_conta" :options="planoContas" />
                            </b-col> 
                            <b-col md="5" sm="12">
                                Desc. até o Vencto
                                <money style="height: 31px; font-size: 0.8rem" id="desc-ate-venc-orcamento" v-model="orcamento.desc_ate_vencto" v-bind="percent" required class="form-control" /> 
                            </b-col> 
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12">
                                Forma
                                <b-form-select size="sm" id="orcamento-forma" v-model="orcamento.id_forma_pagar_receber" :options="formas" />
                            </b-col>
                            <b-col md="6" sm="12">
                                Condição
                                <b-form-select size="sm" id="orcamento-cond" v-model="orcamento.id_condicao_pagar_receber" :options="condicoes" />
                            </b-col>
                        </b-row>  
                        <br>                 

                        <h5>Serviços</h5>
                        <b-row> 
                            <b-col md="12" sm="12">
                                <b-button size="sm" variant="primary" @click="addServico('orcamento')" >
                                    <i class="fa fa-plus mr-1"></i> Novo
                                </b-button>                            
                            </b-col>
                        </b-row>                                               
                        
                        <b-row  v-if="orcamento.servicos.length > 0">
                            <b-col>
                                <b-table class="mt-2" hover striped :items="orcamento.servicos" :fields="fieldsServicos" small>  
                                    <template v-slot:cell(actions)="data">
                                        <b-button size="sm" variant="warning" @click="loadServico(data.item)" class="mr-2" >
                                            <i class="fa fa-pencil"></i>
                                        </b-button>
                                        <b-button size="sm" variant="danger" @click="desejaRemover(data.item)" >
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>

                        </b-row>
                        <p v-else class="mt-3">Nenhum serviço adicionado...</p>
                        <hr>

                        <b-modal id="modal-remove-servico" title="Deletar Serviço" v-model="modalRemServico"   >
                            <p class="my-4">Deseja realmente excluir este serviço?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeServico()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-servico')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>

                        </b-modal>  
                        <b-row>  
                            <b-col md="12" sm="12"> 
                                <b-form-group
                                label="Observações"
                                label-for="orcamento-obs"
                                class="mb-0"                            
                                >
                                    <b-form-textarea
                                        id="orcamento-obs"
                                        v-model="orcamento.obs"
                                    ></b-form-textarea>
                                </b-form-group> 
                            </b-col>                
                        </b-row>                   

                        <br>
                        <b-row>
                            <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                                <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                                <b-popover target="popover-target-1" triggers="hover" placement="top">
                                    <template #title>Alterações</template>
                                    <b>Usuário Cadastro:</b> {{orcamento.usu_cad}} <br>
                                    <b>Data de Cadastro:</b> {{orcamento.data_cad}} <br>
                                    <b>Usuário Alteração:</b> {{orcamento.usu_alt}} <br>
                                    <b>Data de Alteração:</b> {{orcamento.data_alt}}
                                </b-popover>                        
                            </b-col>
                        </b-row>

                    </b-form>
                </div>
                
                <div v-if="showNovo === 'contrato'">
                    <h5 style="color: #6ca7ff">Novo Contrato</h5>
                    <b-form>
                        <input id="contratos-id" type="hidden" v-model="contrato.id_servico" />

                        <b-row> 
                            <b-col md="4" sm="12" v-if="contrato.num_contrato > 0">
                                    Nº
                                    <p style="font-size: 1.2rem">
                                        <strong>{{contrato.num_contrato}}-{{contrato.sequencia}}</strong> <br>
                                        {{contrato.e_aditivo === 'S' ? 'Aditivo' : 'Primário'}}
                                    </p>
                            </b-col> 

                            <b-col md="4" sm="12">
                                    Data Contrato
                                    <p style="font-size: 1.2rem"><strong>{{contrato.data}}</strong></p>
                            </b-col>
                        </b-row> 

                        <b-row style="margin-top: -20px"> 
                            <b-col md="12" sm="12"> 
                                Nome / Razão Social                                
                                <b-form-input size="sm" id="contrato-pessoa" type="text"
                                    :formatter="upperCase"
                                    v-model="contrato.razao_social" required readonly                                    
                                />
                            </b-col>
                        </b-row>  
                        <b-row> 
                            <b-col md="7" sm="12">
                                Plano de Contas
                                <b-form-select size="sm" id="contrato-plano-conta" v-model="contrato.id_plano_conta" :options="planoContas" />
                            </b-col>                            
                            <b-col md="5" sm="12">
                                Encerramento
                                <b-form-input size="sm" id="encerramento-contrato" type="date"
                                    v-model="contrato.encerramento"
                                    :readonly="mode === 'remove'" required
                                />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="6" sm="12">
                                Forma
                                <b-form-select size="sm" id="contrato-forma" v-model="contrato.id_forma_pagar_receber" :options="formas" />
                            </b-col>

                            <b-col md="6" sm="12">
                                Periodicidade
                                <b-form-input size="sm" id="periodicidade-contrato" type="number"
                                    v-model="contrato.periodicidade"
                                    :readonly="mode === 'remove'" required
                                />
                            </b-col>                         
                        </b-row>  
                        
                        <b-row>
                            <b-col md="5" sm="12">
                                Primeiro Vencimento
                                <b-form-input size="sm" id="pri-venc-contrato" type="date"
                                    v-model="contrato.primeiro_vencimento"
                                    :readonly="mode === 'remove'" required
                                />
                            </b-col>

                            <b-col md="3" sm="12">
                                Qtde. Parc
                                <b-form-input size="sm" id="q-parcelas-contrato" type="number"
                                    v-model="contrato.qtde_parcelas"
                                    :readonly="mode === 'remove'" required
                                />
                            </b-col> 

                            <b-col md="4" sm="12">
                                Desc. Vencto
                                <money style="height: 31px; font-size: 0.8rem" id="desc-ate-venc-contrato" v-model="contrato.desc_ate_vencto" v-bind="percent" required class="form-control" /> 
                            </b-col> 
                        </b-row>
                        <br>

                        <b-card no-body class="pl-3">
                            <b-row class="cadastros-opcoes-row p-0" align-h="around">                            
                                <b-col md="4" sm="12" class="p-0">
                                    <b-form-checkbox value="S" unchecked-value="N" id="contrato-boleto" checked v-show="mode === 'save'" v-model="contrato.e_cobranca_boleto" class="mt-3 mb-3" switch>Cobrança via boleto</b-form-checkbox>
                                </b-col>                            
                                <b-col md="4" sm="12" class="p-0">
                                    <b-form-checkbox value="S" unchecked-value="N" id="contrato-ult-dia" checked v-show="mode === 'save'" v-model="contrato.e_vencto_ultimo_dia_mes" class="mt-3 mb-3" switch>Vencto. último dia do mês</b-form-checkbox>
                                </b-col>    
                                <b-col md="4" sm="12" class="p-0">
                                    <b-form-checkbox value="S" unchecked-value="N" id="contrato-prepago" checked v-show="mode === 'save'" v-model="contrato.e_prepago" class="mt-3 mb-3" switch>Pré-pago</b-form-checkbox>
                                </b-col>                            
                            </b-row>
                        </b-card> 
                        <br>                

                        <h5>Serviços</h5>
                        <b-row> 
                            <b-col md="12" sm="12">
                                <b-button size="sm" variant="primary" @click="addServico('contrato')" >
                                    <i class="fa fa-plus mr-1"></i> Novo
                                </b-button>                            
                            </b-col>
                        </b-row>

                        <b-row  v-if="contrato.itens.length > 0">
                            <b-col>
                                <b-table class="mt-2" hover striped :items="contrato.itens" :fields="fieldsServicos" small>  
                                    <template v-slot:cell(actions)="data">
                                        <b-button size="sm" variant="warning" @click="loadServico(data.item)" class="mr-2" >
                                            <i class="fa fa-pencil"></i>
                                        </b-button>
                                        <b-button size="sm" variant="danger" @click="desejaRemover(data.item)" >
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>

                        </b-row>
                        <p v-else class="mt-3">Nenhum serviço adicionado...</p>
                        <hr>                                             

                        <b-row>  
                            <b-col md="12" sm="12" lg="12"> 
                                <b-form-group
                                label="Observações"
                                label-for="contrato-obs"
                                class="mb-0"
                                >
                                    <b-form-textarea
                                        id="contrato-obs"
                                        v-model="contrato.obs"
                                    ></b-form-textarea>
                                </b-form-group> 
                            </b-col>
                            <b-col md="12" sm="12" lg="12"> 
                                <b-form-group
                                label="Instruções"
                                label-for="contrato-instrucoes"
                                class="mb-0"
                                >
                                    <b-form-textarea
                                        id="contrato-instrucoes"
                                        v-model="contrato.instrucoes"
                                    ></b-form-textarea>
                                </b-form-group> 
                            </b-col>                
                        
                            <b-col md="12" sm="12" lg="12">  
                                <b-form-group
                                label="Observações Fiscais"
                                label-for="contrato-obs-fiscais"
                                class="mb-0"
                                >
                                    <b-form-textarea
                                        id="contrato-obs-fiscais"
                                        v-model="contrato.obs_fiscais"
                                    ></b-form-textarea>
                                </b-form-group> 
                            </b-col>                
                        </b-row>

                        <br>
                        <b-row>
                            <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                                <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                                <b-popover target="popover-target-1" triggers="hover" placement="top">
                                    <template #title>Alterações</template>
                                    <b>Usuário Cadastro:</b> {{contrato.usu_cad}} <br>
                                    <b>Data de Cadastro:</b> {{contrato.data_cad}} <br>
                                    <b>Usuário Alteração:</b> {{contrato.usu_alt}} <br>
                                    <b>Data de Alteração:</b> {{contrato.data_alt}}
                                </b-popover>                        
                            </b-col>

                        </b-row>

                    </b-form>
                </div>

            </div>
        </b-sidebar>        

        <div class="funil-etapas" v-if="showPainel">
            <div v-if="etapas.length < 1"> 
                <h5>Este funil de vendas não possui etapas! Adicione uma etapa para inicial o funil.</h5>
                <br>
                <b-button variant="primary" @click="addEtapa(0)">
                    <i class="fa fa-plus"></i> Adiciionar Etapa
                </b-button>
            </div> 

            <section class="funil-section">

                <!-- Etapas ************** -->
                <div class="funil-etapa" v-for="(it, id_funil_venda_etapa) in etapas" v-bind:key="id_funil_venda_etapa" :style="`border-left: 1px dashed #${it.cor}`">

                    <div class="funil-topo-bg" :style="`background: #${it.cor}`">
                        <h2 class="funil-texto">                            
                            <p>{{it.nome}}</p>
                            <div v-if="it.e_orcamento === 'S' || it.e_contrato === 'S'">
                                <i :id="`popo-target-etapa-${it.id_funil_venda_etapa}`" style="margin: -23px 0 0 175px" 
                                class="fa fa-exclamation-circle close"></i>

                                <b-popover :target="`popo-target-etapa-${it.id_funil_venda_etapa}`" triggers="hover" placement="top">
                                    <template #title>Obrigatório na Etapa</template>
                                    <p v-if="it.e_orcamento === 'S'">Gerar ORÇAMENTO</p>
                                    <p v-if="it.e_contrato === 'S'">Gerar CONTRATO</p>
                                </b-popover>                         
                            </div>
                        </h2>
                        
                    
                        <b-button 
                            class="mr-2 close" 
                            size="sm" 
                            variant="outline-light"
                            @click="addEtapa(it.sequencia)"                        
                        >
                            <i class="fa fa-plus"></i>
                        </b-button>
                    </div>

                    <div class="funil-div-btn">
                        <b-button 
                            v-if="it.sequencia === 1"
                            size="sm" 
                            class="funil-bt-novo-negocio" 
                            pill 
                            variant="outline-primary"
                            @click="novoNegocio(it)"
                            v-b-tooltip.hover.bottomright="'Novo Negócio'"
                        >
                            <i class="fa fa-plus"></i>
                        </b-button>

                        <b-form-input 
                            @input="filtrarNegocios(it)" 
                            class="funil-bt-pesquisa" 
                            size="sm" 
                            v-model="it.filtro" 
                            type="search" 
                            placeholder="Procurar"                            
                        >
                        </b-form-input>
                        {{filtroNegocios}}
                    </div>                   

                    <!-- Negócios ************ -->
                    <div class="funil-etapa-list">
                        <b-list-group >
                            <b-list-group-item href="#" class="flex-column align-items-start mb-2" v-for="(n, id_funil_venda_negocio) in it.filtrados" v-bind:key="id_funil_venda_negocio">
                                <b-row>

                                    <b-col  @click="loadNegocio(n)" sm="10">
                                        <!-- <div class="d-flex w-100 justify-content-between">
                                            <small>{{n.tempo}}</small>
                                            <h6 class="mb-1">{{n.razao_social}}</h6>
                                        </div> -->
                                        <div class="w-100">                                            
                                            <h6 class="mb-1"><strong>{{n.razao_social}}</strong></h6>
                                        </div>                                        
                                        <hr style="margin: 0">
                                        
                                        <div v-if="n.nome_contato" style="margin: 0 0 -5px 0"><small>Contato<br></small></div>
                                        <small><strong>{{n.nome_contato}}</strong></small>
                                        
                                        <div v-if="n.obs" style="margin: 5px 0 -5px 0"><small>Observação<br></small></div>
                                        <p class="mb-1 obs-negocio">
                                            {{n.obs}}
                                        </p>
                                        <small>{{n.tempo}}</small>
                                    </b-col>

                                    <b-col sm="2">
                                        <b-button-group style="margin: -15px 0 0 0">
                                            <b-button :id="`tooltip-avr-${n.id_funil_venda_negocio}`" size="sm" @click="moverNegocio(n, it.sequencia-1, 'retroceder', false)" style="margin-left: -10px" class="close toolbal-item-funil" variant="outline-light">
                                                <i  class="fa fa-chevron-left"></i>
                                            </b-button>
                                            <b-button :id="`tooltip-ava-${n.id_funil_venda_negocio}`" size="sm" @click="moverNegocio(n, it.sequencia-1, 'avancar', false)" style="margin-left: 10px" class="close toolbal-item-funil" variant="outline-light">
                                                <i  class="fa fa-chevron-right"></i>
                                            </b-button>                                            
                                        </b-button-group>
                                        <b-tooltip :target="`tooltip-ava-${n.id_funil_venda_negocio}`" noninteractive>Avançar</b-tooltip>
                                        <b-tooltip :target="`tooltip-avr-${n.id_funil_venda_negocio}`" noninteractive>Retroceder</b-tooltip>                                       

                                        <!-- <b-button :id="`tooltip-ed-${n.id_funil_venda_negocio}`" size="sm" class="close toolbal-item-funil" variant="outline-light">
                                            <i class="fa fa-pencil"></i>
                                        </b-button>
                                        <b-tooltip :target="`tooltip-ed-${n.id_funil_venda_negocio}`" noninteractive>Editar</b-tooltip> -->

                                        <b-button :id="`tooltip-wt-${n.id_funil_venda_negocio}`" size="sm" @click="abreLink(`https://wa.me/${n.ddd_celular}${n.celular}`, 's')" class="close toolbal-item-funil" variant="outline-light">
                                            <i class="fa fa-whatsapp"></i>
                                        </b-button>
                                        <b-tooltip :target="`tooltip-wt-${n.id_funil_venda_negocio}`" noninteractive>WhatsApp para:({{n.ddd_celular}}){{n.celular}}</b-tooltip>

                                        <!-- <b-button :id="`tooltip-em-${n.id_funil_venda_negocio}`" size="sm" @click="abreLink(`mailto:${n.email}`, 'e')" class="close toolbal-item-funil" variant="outline-light">
                                            <i class="fa fa-envelope-o"></i>
                                        </b-button> -->
                                        <b-button :id="`tooltip-em-${n.id_funil_venda_negocio}`" size="sm" @click="exibirEmail(n.email)" class="close toolbal-item-funil" variant="outline-light">
                                            <i class="fa fa-envelope-o"></i>
                                        </b-button>
                                        <b-tooltip :target="`tooltip-em-${n.id_funil_venda_negocio}`" noninteractive>Enviar E-mail para: {{n.email}}</b-tooltip>

                                        <b-button v-b-toggle.sidebar-right-orcamento @click="painelOrcamento(n)" :id="`tooltip-or-${n.id_funil_venda_negocio}`" size="sm"  class="close toolbal-item-funil" variant="outline-light">
                                            <i class="fa fa-gavel"></i>
                                        </b-button>
                                        <b-tooltip :target="`tooltip-or-${n.id_funil_venda_negocio}`" noninteractive>Orçamento/Contrato</b-tooltip>
                                    </b-col>

                                </b-row>   
                                
                            </b-list-group-item>
                        </b-list-group>

                    </div>

                </div>
            </section>

            

        </div> 
                     

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-if="!show && !showPainel">
                <b-form> 
                    <input id="funil-id" type="hidden" v-model="funil.id_funil_venda" />

                    <b-row> 
                        <b-col md="6" sm="12">
                            <b-form-group label="Nome" label-for="funil-nome"> 
                                <b-form-input id="funil-nome" type="text"
                                    :formatter="upperCase"
                                    v-model="funil.nome" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group> 
                        </b-col>

                        <b-col md="3" sm="12">
                            <b-form-group label="Vigência Inicial" label-for="funil-vigencia-inicial">
                                <b-form-input id="funil-vigencia-inicial" type="date"
                                    v-model="funil.vigencia_inicial"
                                    :readonly="mode === 'remove'" required/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" sm="12">
                            <b-form-group label="Vigência Final" label-for="funil-vigencia-final">
                                <b-form-input id="funil-vigencia-final" type="date"
                                    v-model="funil.vigencia_final"
                                    :readonly="mode === 'remove'" required/>
                            </b-form-group>
                        </b-col>

                        <b-col md="12" sm="12">
                            <b-form-group label="Descrição" label-for="funil-descricao"> 
                                <b-form-textarea
                                    id="funil-descricao"
                                    v-model="funil.descricao"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>

                    </b-row>              
                    
                    <b-card class="cadastros-opcoes-row-card">
                        <b-row class="cadastros-opcoes-row" align-h="around">                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox value="A" unchecked-value="A" id="funil-ativo" v-show="mode === 'save'" v-model="funil.status" class="mt-3 mb-3" switch>Concluído</b-form-checkbox>
                            </b-col>                
                        </b-row>
                    </b-card>
                    <br>

                    <b-card header="Etapas" header-bg-variant="light">  
                        <b-row> 
                            <div>
                                <b-button variant="primary" @click="addEtapa(0)">
                                    <i class="fa fa-plus"></i> Nova Etapa
                                </b-button>
                            </div>
                        </b-row>

                        <b-row  >
                            <b-table class="mt-2" hover striped :items="etapas" :fields="fieldsEtapas" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="warning" @click="loadEtapa(data.item)" class="mr-2">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button variant="danger" @click="desejaRemoverEtapa(data.item)" class="mr-2">
                                        <i class="fa fa-trash"></i>
                                    </b-button>

                                    <b-button variant="info" @click="mudaPosicaoEtapa(data.item, 'subir')" class="mr-2">
                                        <i class="fa fa-arrow-up"></i>
                                    </b-button>
                                    <b-button variant="info" @click="mudaPosicaoEtapa(data.item, 'descer')">
                                        <i class="fa fa-arrow-down"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-row>  

                        <b-modal id="modal-remove-etapa" title="Remover etapa" v-model="modalRemEtapa"   >
                            <p class="my-4">Deseja realmente excluir esta etapa?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeEtapa()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-etapa')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>

                        </b-modal>                
                    </b-card>
                    <br>
                    
                    <br>
                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{funil.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{funil.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{funil.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{funil.data_alt}}
                            </b-popover>                        
                        </b-col>

                    </b-row>

                </b-form>
            </b-card>
        </b-overlay>   

        <b-card v-show="!show && !showPainel" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novoFunil">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-if="show && !showPainel">
            <b-table hover striped :items="funis" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="primary" @click="loadFunil(data.item, false)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="success" @click="exibirPainel(data.item)" class="mr-2">
                        <i class="fa fa-filter"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>  

        <b-modal 
            hideBvModalEvent
            v-model="modalNovoNegocio"                        
            id="modal-novo-negocio" 
            centered size="md" 
            ok-only
            ok-disabled
            title="Novo Negócio"
        >   
            <div v-if="editaNegocio">
                <b-row>                 
                    <b-col md="12" sm="12">
                        <b-card no-body class="pt-2 pl-3 mb-2" >
                            <b-form-group  v-slot="{ ariaDescribedby }">
                                <b-form-radio-group 
                                    id="radio-slots-negocio"
                                    v-model="tipoCadastro"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots-negocio"
                                >
                                    <b-form-radio value="B">Básico</b-form-radio>
                                    <b-form-radio value="V">Vincular</b-form-radio>
                                    <b-form-radio value="N">Novo Cadastro</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </b-card>

                        <b-form-group v-if="tipoCadastro === 'V'" label="Razão Social/Nome" label-for="neg-nome-razao-p">
                            <Autocomplete
                                id="neg-nome-razao-p"
                                :options="pessoas"
                                label-key="razao_social"
                                value-key="idpessoa"
                                v-model="negocio.razao_social"
                                placeholder="Pesquise por parte do Nome/Razão Social"
                                @shouldSearch="loadPessoas"
                                @select="onSelect"
                                
                            />
                        </b-form-group>
                        <b-form-group v-else label="Razão Social/Nome" label-for="neg-nome-razao"> 
                            <b-form-input id="neg-nome-razao" type="text"
                                :formatter="upperCase"
                                v-model="negocio.razao_social" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group> 

                        <b-form-group label="E-mail" label-for="neg-email"> 
                            <b-form-input id="neg-email" type="text"
                                :formatter="lowerCase"
                                v-model="negocio.email" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>                                 
                        <b-form-group label="Sobre a Empresa" label-for="neg-obs"> 
                            <b-form-textarea
                                id="neg-obs"
                                v-model="negocio.obs"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" sm="6">
                        <b-form-group label="Contato" label-for="neg-contato"> 
                            <b-form-input id="neg-contato" type="text"
                                :formatter="upperCase"
                                v-model="negocio.nome_contato" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>  
                    </b-col>
                    <b-col md="6" sm="6">
                        <b-form-group label-for="neg-cpf"> 
                            <b-form-checkbox value="J" unchecked-value="F" id="funil-ativo" v-model="negocio.tipo_fj" class="mb-2" switch>{{negocio.tipo_fj === 'F' ? 'Física: CPF' : 'Jurídica: CNPJ' }}</b-form-checkbox>

                            <b-form-input v-if="negocio.tipo_fj === 'F'" id="neg-cpf" type="text"
                                :formatter="upperCase"
                                v-model="negocio.cpf" required
                                :readonly="mode === 'remove'"/>
                        
                            <b-form-input v-else id="neg-cnpj" type="text"
                                :formatter="upperCase"
                                v-model="negocio.cnpj" required
                                :readonly="mode === 'remove'"/>
                        </b-form-group>  
                    </b-col>

                </b-row>

                <b-row>
                    <b-col md="6" sm="6">
                        <b-form-group label="Telefone" label-for="negocio-telefone"> 
                            <b-input-group>
                                <div style="width: 65px;">
                                    <b-form-input id="negocio-telefone-ddd" type="text" 
                                    v-mask="mascaras.ddd"
                                    :formatter="upperCase"
                                    placeholder="DDD"
                                    v-model="negocio.ddd_telefone" required
                                    :readonly="mode === 'remove'" />
                                </div>

                                <b-form-input id="negocio-telefone" type="text"
                                v-mask="soNumeros(negocio.telefone) > 8 ? mascaras.celular : mascaras.telefone"
                                :formatter="upperCase"
                                v-model="negocio.telefone" required
                                :readonly="mode === 'remove'" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="6">
                        <b-form-group label="Celular" label-for="negocio-celular"> 
                            <b-input-group>
                                <div style="width: 65px;">
                                    <b-form-input id="negocio-celular-ddd" type="text" 
                                    v-mask="mascaras.ddd"
                                    :formatter="upperCase"
                                    placeholder="DDD"
                                    v-model="negocio.ddd_celular" required
                                    :readonly="mode === 'remove'" />
                                </div>

                                <b-form-input id="negocio-celular" type="text"
                                v-mask="soNumeros(negocio.celular) > 8 ? mascaras.celular : mascaras.celular"
                                :formatter="upperCase"
                                v-model="negocio.celular" required
                                :readonly="mode === 'remove'" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                </b-row>            
            </div> 

            <div v-else>
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>{{negocio.razao_social}}</h4>
                            <h5>{{negocio.cpf}}{{negocio.cnpj}}</h5>                            
                        </b-col>
                    </b-row>
                </b-card>
                <br>
                
                <b-row>
                    <b-col md="5" sm="12">
                        <span class="detalhes-neg-span">Contato</span>
                        <h5>{{negocio.nome_contato}}</h5>                        
                    </b-col>

                    <b-col md="7" sm="12">
                        <div v-if="negocio.email">
                            <span class="detalhes-neg-span">E-mail</span>
                            <h6 class="cont-neg-span">{{negocio.email}}</h6>
                        </div>

                        <div v-if="negocio.celular">
                            <span class="detalhes-neg-span">Celular</span>
                            <h6 class="cont-neg-span">({{negocio.ddd_celular}}) {{negocio.celular}}</h6>
                        </div>

                        <div v-if="negocio.telefone">
                            <span class="detalhes-neg-span">Telefone</span>
                            <h6 class="cont-neg-span">({{negocio.ddd_telefone}}) {{negocio.telefone}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <span class="detalhes-neg-span">Observações</span>
                        <p>{{negocio.obs}}</p>
                    </b-col>
                </b-row>                

            </div>    

            <template #modal-footer>
                <div class="w-100">                               

                    <b-col v-if="!excluirNegocio">
                        <b-button class="mr-1" v-if="editaNegocio" variant="primary" @click="salvarNegocio">Salvar</b-button>
                        <b-button class="mr-1" v-if="editaNegocio !== 'novo'" variant="link" @click="editaNegocio = 'novo'">Editar</b-button>
                        <b-button v-if="editaNegocio !== 'novo'" variant="link" @click="excluirNegocio = true">Excluir</b-button>
                    </b-col>

                    <b-col v-else>
                        <p style="color: red"><strong>ATENÇÃO:</strong> Deseja realmente excluir este negócio?</p>
                        <b-button class="mr-1" variant="danger" @click="removeNegocio(negocio.id_funil_venda_negocio)">Sim</b-button>
                        <b-button variant="secondary" @click="excluirNegocio = false">Não</b-button>
                    </b-col>
                </div>
            </template>                    
        </b-modal>

        <b-modal 
            hideBvModalEvent
            v-model="modalEditaNegocio"                        
            id="modal-edita-negocio" 
            centered 
            size="xl" 
            ok-only
            ok-disabled
        >  
            <template #modal-title>
                <div class="head-edita-negocio">
                    <div class="title-edita-negocio"><strong>{{negocio.razao_social}}</strong> </div>

                    <div class="btn1-edita-negocio">
                        <i class="fa fa-arrow-left" @click="moverNegocio(negocio, negocio.sequencia-1, 'retroceder', true)" v-b-tooltip.hover.bottomleft="'Retroceder'"></i>
                    </div>

                    <span class="et-edita-negocio">{{negocio.nome_etapa}}</span>

                    <div class="btn2-edita-negocio">
                        <i class="fa fa-arrow-right" @click="moverNegocio(negocio, negocio.sequencia-1, 'avancar', true)" v-b-tooltip.hover.bottomleft="'Avançar'"></i>
                    </div>
                </div>
            </template>    

            <template #modal-footer>
                <div class="w-100"> 
                    <b-col v-if="!excluirNegocio">
                        <b-button class="mr-1" v-if="editaNegocio" variant="primary" @click="salvarNegocio">Salvar</b-button>
                        <b-button class="mr-1" v-if="editaNegocio !== 'novo'" variant="link" @click="editaNegocio = 'novo'">Editar</b-button>
                        <b-button v-if="editaNegocio !== 'novo'" variant="link" @click="excluirNegocio = true">Excluir</b-button>
                    </b-col>

                    <b-col v-else>
                        <p style="color: red"><strong>ATENÇÃO:</strong> Deseja realmente excluir este negócio?</p>
                        <b-button class="mr-1" variant="danger" @click="removeNegocio(negocio.id_funil_venda_negocio)">Sim</b-button>
                        <b-button variant="secondary" @click="excluirNegocio = false">Não</b-button>
                    </b-col>
                </div>
            </template>  

            <b-row>
                <b-col md="5" sm="12" >
                    <div class="botoes-acao">
                        <b-button 
                            pill 
                            size="sm"
                            variant="outline-secondary" 
                            @click="painelOrcamento(negocio)"
                            v-b-toggle.sidebar-right-orcamento
                        >
                            <i class="fa fa-gavel"></i> Orçamento/Contrato
                        </b-button>

                        <b-button 
                            pill 
                            size="sm"
                            variant="outline-secondary"
                            @click="enviarWhats()"
                        >
                            <i class="fa fa-whatsapp"></i> WhatsApp
                        </b-button>

                        <b-button 
                            pill 
                            size="sm"
                            variant="outline-secondary"
                            @click="exibirEmail(negocio.email)"
                        >
                            <i class="fa fa-envelope-o"></i> E-mail
                        </b-button>

                        <b-button 
                            pill 
                            size="sm"
                            variant="outline-secondary"
                            @click="exibirComentario()"
                        >
                            <i class="fa fa-comment-o"></i> Comentário
                        </b-button>
                    </div>

                    <div v-if="exibeComent">
                        <b-input-group class="mt-3">
                            <b-form-textarea
                                v-model="atividade.conteudo"
                            ></b-form-textarea>

                            <b-input-group-append>
                                <b-button  @click="salvarAtividade()" variant="outline-success">{{botaoAtividade}}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <br>
                    </div>

                    <div v-if="negocio.e_contrato === 'S' || negocio.e_orcamento === 'S' || negocio.e_email === 'S'">
                        <h6>
                            <strong>Obrigatório nesta etapa</strong>
                        </h6>
                        <b-badge v-if="negocio.e_contrato === 'S'" :variant="negocio.e_contrato_feito === 'S' ? 'success' : 'danger'">Gerar Contrato <i v-if="negocio.e_contrato_feito === 'S'" class="fa fa-check"></i></b-badge>
                        <b-badge v-if="negocio.e_orcamento === 'S'" :variant="negocio.e_orcamento_feito === 'S' ? 'success' : 'danger'">Gerar Orçamento <i v-if="negocio.e_orcamento_feito === 'S'" class="fa fa-check"></i></b-badge>
                        <b-badge v-if="negocio.e_email === 'S'" :variant="negocio.e_email_enviado === 'S' ? 'success' : 'danger'">Enviar e-mail <i v-if="negocio.e_email_enviado === 'S'" class="fa fa-check"></i></b-badge>
                        <br>
                    </div>
                    <hr>

                    <h6><strong>Dados Iniciais</strong></h6>

                    <!-- <div class="funil-dados-list">
                        <div v-if="negocio.email">
                            <span class="detalhes-neg-span">E-mail</span>
                            <h6 class="cont-neg-span">{{negocio.email}}</h6>
                        </div>

                        <div v-if="negocio.celular">
                            <span  class="detalhes-neg-span">Celular</span>
                            <h6 class="cont-neg-span">({{negocio.ddd_celular}}) {{negocio.celular}}</h6>
                        </div>

                        <div v-if="negocio.telefone">
                            <span class="detalhes-neg-span">Telefone</span>
                            <h6 class="cont-neg-span">({{negocio.ddd_telefone}}) {{negocio.telefone}}</h6>
                        </div>

                        <span class="detalhes-neg-span">Observações</span>
                        <p>{{negocio.obs}}</p>
                    </div> -->

                    <div v-if="editaNegocio" class="funil-dados-list">
                        <b-row>                 
                            <b-col md="12" sm="12">
                                <b-card no-body class="pt-2 pl-3 mb-2" >
                                    <b-form-group  v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group 
                                            id="radio-slots-negocio"
                                            v-model="tipoCadastro"
                                            :aria-describedby="ariaDescribedby"
                                            name="radio-options-slots-negocio"
                                        >
                                            <b-form-radio value="B">Básico</b-form-radio>
                                            <b-form-radio value="V">Vincular</b-form-radio>
                                            <b-form-radio value="N">Novo Cadastro</b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-card>

                                <b-form-group v-if="tipoCadastro === 'V'" label="Razão Social/Nome" label-for="neg-nome-razao-p">
                                    <Autocomplete
                                        id="neg-nome-razao-p"
                                        :options="pessoas"
                                        label-key="razao_social"
                                        value-key="idpessoa"
                                        v-model="negocio.razao_social"
                                        placeholder="Pesquise por parte do Nome/Razão Social"
                                        @shouldSearch="loadPessoas"
                                        @select="onSelect"
                                        
                                    />
                                </b-form-group>
                                <b-form-group v-else label="Razão Social/Nome" label-for="neg-nome-razao"> 
                                    <b-form-input id="neg-nome-razao" type="text"
                                        :formatter="upperCase"
                                        v-model="negocio.razao_social" required
                                        :readonly="mode === 'remove'"/>
                                </b-form-group> 

                                <b-form-group label="E-mail" label-for="neg-email"> 
                                    <b-form-input id="neg-email" type="text"
                                        :formatter="lowerCase"
                                        v-model="negocio.email" required
                                        :readonly="mode === 'remove'"/>
                                </b-form-group>                                 
                                <b-form-group label="Sobre a Empresa" label-for="neg-obs"> 
                                    <b-form-textarea
                                        id="neg-obs"
                                        v-model="negocio.obs"
                                        rows="3"
                                        max-rows="6"
                                    ></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="Contato" label-for="neg-contato"> 
                                    <b-form-input id="neg-contato" type="text"
                                        :formatter="upperCase"
                                        v-model="negocio.nome_contato" required
                                        :readonly="mode === 'remove'"/>
                                </b-form-group>  
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label-for="neg-cpf"> 
                                    <b-form-checkbox value="J" unchecked-value="F" id="funil-ativo" v-model="negocio.tipo_fj" class="mb-2" switch>{{negocio.tipo_fj === 'F' ? 'Física: CPF' : 'Jurídica: CNPJ' }}</b-form-checkbox>

                                    <b-form-input v-if="negocio.tipo_fj === 'F'" id="neg-cpf" type="text"
                                        :formatter="upperCase"
                                        v-model="negocio.cpf" required
                                        :readonly="mode === 'remove'"/>
                                
                                    <b-form-input v-else id="neg-cnpj" type="text"
                                        :formatter="upperCase"
                                        v-model="negocio.cnpj" required
                                        :readonly="mode === 'remove'"/>
                                </b-form-group>  
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="Telefone" label-for="negocio-telefone"> 
                                    <b-input-group>
                                        <div style="width: 65px;">
                                            <b-form-input id="negocio-telefone-ddd" type="text" 
                                            v-mask="mascaras.ddd"
                                            :formatter="upperCase"
                                            placeholder="DDD"
                                            v-model="negocio.ddd_telefone" required
                                            :readonly="mode === 'remove'" />
                                        </div>

                                        <b-form-input id="negocio-telefone" type="text"
                                        v-mask="soNumeros(negocio.telefone) > 8 ? mascaras.celular : mascaras.telefone"
                                        :formatter="upperCase"
                                        v-model="negocio.telefone" required
                                        :readonly="mode === 'remove'" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label="Celular" label-for="negocio-celular"> 
                                    <b-input-group>
                                        <div style="width: 65px;">
                                            <b-form-input id="negocio-celular-ddd" type="text" 
                                            v-mask="mascaras.ddd"
                                            :formatter="upperCase"
                                            placeholder="DDD"
                                            v-model="negocio.ddd_celular" required
                                            :readonly="mode === 'remove'" />
                                        </div>

                                        <b-form-input id="negocio-celular" type="text"
                                        v-mask="soNumeros(negocio.celular) > 8 ? mascaras.celular : mascaras.celular"
                                        :formatter="upperCase"
                                        v-model="negocio.celular" required
                                        :readonly="mode === 'remove'" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                        </b-row>            
                    </div> 

                    <div v-else class="funil-dados-list">
                        <div v-if="negocio.email">
                            <span class="detalhes-neg-span">E-mail</span>
                            <h6 class="cont-neg-span">{{negocio.email}}</h6>
                        </div>

                        <div v-if="negocio.celular">
                            <span  class="detalhes-neg-span">Celular</span>
                            <h6 class="cont-neg-span">({{negocio.ddd_celular}}) {{negocio.celular}}</h6>
                        </div>

                        <div v-if="negocio.telefone">
                            <span class="detalhes-neg-span">Telefone</span>
                            <h6 class="cont-neg-span">({{negocio.ddd_telefone}}) {{negocio.telefone}}</h6>
                        </div>

                        <span class="detalhes-neg-span">Observações</span>
                        <p>{{negocio.obs}}</p>
                    </div>
                    
                </b-col>   

                <b-col md="7" sm="12" class="border-start">
                    <hr class="div-hr">
                    <h6><strong>Atividades</strong></h6>

                    <div class="funil-atividade-list">
                        <b-list-group> 
                            <b-list-group-item 
                                v-for="(a, id_negocio_atividade) in atividades" v-bind:key="id_negocio_atividade" 
                                href="#" 
                                class="flex-column align-items-start atividades-card"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">
                                        <i v-if="a.tipo === 'EMAIL'" class="fa fa-envelope-o"></i> 
                                        <i v-if="a.tipo === 'COMENT'" class="fa fa-comment-o"></i> 
                                        <i v-if="a.tipo === 'WHATS'" class="fa fa-whatsapp"></i> 
                                        <i v-if="a.tipo === 'EDICAO'" class="fa fa-pencil"></i> 
                                        <i v-if="a.tipo === 'ETAPA' && a.conteudo.acao === 'avançou'" class="fa fa-arrow-right"></i> 
                                        <i v-if="a.tipo === 'ETAPA' && a.conteudo.acao === 'retrocedeu'" class="fa fa-arrow-left"></i> 
                                        {{a.descricao}}
                                    </h5>
                                    <small class="text-muted">{{a.data_cad}}</small>
                                </div>

                                <div v-if="a.tipo === 'EMAIL'">
                                    <h6>para: <strong>{{a.conteudo.destinatario}}</strong></h6>
                                    <h6>assunto: <strong>{{a.conteudo.assunto}}</strong></h6>
                                    <b-card no-body class="p-2" v-if="a.ativo">
                                        <div v-html="a.conteudo.texto"></div>                                    
                                    </b-card>
                                </div>
                                <div v-if="a.tipo === 'COMENT'">
                                    <p class="mb-1">
                                        {{ a.conteudo }}
                                    </p>
                                </div>
                                <div v-if="a.tipo === 'WHATS'">
                                    <h6>contato: <strong>{{a.conteudo.contato}}</strong></h6>
                                    <b-card no-body class="p-2" v-if="a.ativo">
                                        <div v-html="a.conteudo.mensagem"></div>                                    
                                    </b-card>
                                </div>
                                <div v-if="a.tipo === 'EDICAO'">
                                    <p class="mb-1">
                                        {{ a.conteudo }}
                                    </p>
                                </div>
                                <div v-if="a.tipo === 'ETAPA'">
                                    <p class="mb-1">
                                        {{ a.conteudo.detalhes }}
                                    </p>
                                </div>

                                <div @click="a.ativo = !a.ativo" v-if="!a.ativo" class="mais-det-icon">
                                    <i class="fa fa-angle-down"></i> <small>mais detalhes.</small>
                                </div>
                                <small @click="a.ativo = !a.ativo" v-else class="mais-det-icon"><i class="fa fa-angle-up"></i>menos detalhes.</small>

                            </b-list-group-item>
                        </b-list-group>
                    </div>

                </b-col> 
            </b-row>                  
        </b-modal>

        <b-modal 
            hideBvModalEvent
            v-model="modalAddEtapa"                        
            id="modal-tipos" 
            centered size="sm" 
            ok-only
            ok-disabled
            title="Adicionar Etapa"
        >  
            
            <b-row> 
                <b-col md="12" sm="12">
                    <b-form-group label="Nome" label-for="funil-nome-etapa"> 
                        <b-form-input id="funil-nome-etapa" type="text"
                            v-model="etapa.nome" required
                            :readonly="mode === 'remove'"/>
                    </b-form-group>                                 
                    <b-form-group label="Descrição" label-for="funil-descricao-etapa"> 
                        <b-form-textarea
                            id="funil-descricao-etapa"
                            v-model="etapa.descricao"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-form-checkbox value="S" unchecked-value="N" id="etapa-e-orcamento" v-show="mode === 'save'" v-model="etapa.e_orcamento" class="mt-3 mb-3" switch>Obrigar gerar Orçamento</b-form-checkbox>
                    <b-form-checkbox value="S" unchecked-value="N" id="etapa-e-contrato" v-show="mode === 'save'" v-model="etapa.e_contrato" class="mt-3 mb-3" switch>Obrigar gerar Contrato</b-form-checkbox>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12" sm="12">
                    <b-card no-body :style="`background-color: #${etapa.cor || '#2fb8ac'}; padding: 10px;`"> <p style="color: white">Cor da etapa</p></b-card>
                </b-col>
                <b-col md="6" sm="12">
                    <b-list-group>
                        <b-list-group-item @click="(c)=>{etapa.cor = it.valor}" :style="`background-color: #${it.valor}`" v-for="(it, valor) in coresFrias" v-bind:key="valor" href="#"></b-list-group-item>
                    </b-list-group>
                </b-col>
                <b-col md="6" sm="12">
                    <b-list-group>
                        <b-list-group-item @click="(c)=>{etapa.cor = it.valor}" :style="`background-color: #${it.valor}`" v-for="(it, valor) in coresQuentes" v-bind:key="valor" href="#"></b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row> 
            <template #modal-footer>
                <div class="w-100">                               

                    <b-col md="10" sm="10">
                        <b-button variant="primary"
                            @click="salvarEtapa">Salvar</b-button>
                        <b-button variant="danger" class="ml-2" @click="resetEtapa">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                    
        </b-modal>

        <b-modal 
            hideBvModalEvent
            v-model="modalAddServico"                        
            id="modal-tipos" 
            centered size="xl" 
            ok-only
            ok-disabled
            title="Serviço"
        >  
            
            <b-row> 
                <b-col md="5" sm="12">
                    <b-form-group label="Serviço" label-for="servico-name-input">
                        <b-form-select id="servico-name-input" v-model="servico.id_servico" :options="servicos" />
                    </b-form-group>                                                      
                    <b-form-group label="Vendedor" label-for="vendedor-add-servico" v-if="tipoServico === 'contrato'">
                        <b-form-select id="vendedor-add-servico" v-model="servico.id_vendedor" :options="vendedores" />
                    </b-form-group>
                </b-col>
                                
                <b-col md="2" sm="12">
                    <b-form-group label="Qtde" label-for="add-servico-qtde"> 
                        <b-form-input id="add-servico-qtde" type="number"
                            v-model="servico.qtde"
                            :readonly="mode === 'remove'" 
                            required
                            @input="calculaServicos()"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="2" sm="12">
                    <b-form-group label="Unitário" label-for="add-servico-unit">  
                        <money id="add-servico-unit" v-bind="moneyItens" v-model="servico.unitario" class="form-control"/> 
                    </b-form-group>
                </b-col>

                <b-col md="3" sm="12">
                    <b-form-group label="Total" label-for="add-servico-total">
                        <money id="add-servico-total" v-model="servico.total" v-bind="moneyItens" readonly class="form-control"/>                                        
                    </b-form-group>
                </b-col>

            </b-row> 

            <b-row style="margin-top:-25px">  
                <b-col md="12" sm="12"> 
                    <b-form-group
                    label="Detalhes"
                    label-for="add-servico-det"
                    class="mb-0"
                    >
                        <b-form-textarea
                            id="add-servico-det"
                            v-model="servico.detalhes"
                        ></b-form-textarea>
                    </b-form-group> 
                </b-col>                
            </b-row>                           
            
            
            <template #modal-footer>
                <div class="w-100">                               

                    <b-col md="10" sm="10">
                        <b-button variant="primary"
                            @click="salvarServico()">Salvar</b-button>
                        <b-button class="ml-2" @click="resetServico">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                  
        </b-modal>        

        <b-modal 
            hideBvModalEvent
            v-model="modalEmail"                        
            id="modal-email" 
            centered size="xl" 
            ok-only
            ok-disabled
            title="E-mail"
        >  
            <b-overlay :show="emailBusy" rounded="lg" opacity="0.6">
                <template #overlay>
                    <div class="d-flex align-items-center">
                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                        <b-spinner type="grow" variant="dark"></b-spinner>
                        <b-spinner small type="grow" variant="secondary"></b-spinner>                        
                    </div>

                    <div class="d-flex align-items-center">
                        <h3>Enviando e-mail, aguarde...</h3>
                    </div>
                </template>

                <b-row> 
                    <b-col md="12" sm="12">
                        <b-form-group label="Destinatário" label-for="email-dest"> 
                            <b-form-input id="email-dest" type="email" v-model="email.destinatario" />
                        </b-form-group>

                        <b-form-group label="Assunto" label-for="email-assunto"> 
                            <b-form-input id="email-assunto" type="text" v-model="email.assunto" />
                        </b-form-group>

                        <VueEditor id="email-editor" v-model="email.texto" />

                    </b-col>               
                </b-row> 
            </b-overlay>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-col md="10" sm="10">
                        <b-button variant="primary"
                            @click="enviarEmail()">Enviar</b-button>
                        <b-button class="ml-2" @click="modalEmail = false">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                  
        </b-modal> 
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import {VMoney} from 'v-money'
import Autocomplete from '../autocomplete/Autocomplete.vue'
import { VueEditor } from "vue2-editor"
const moment = require('moment')
import axios from 'axios'
import { showSuccess } from '../../global'

export default {
    name: 'FunilVendas',
    components: { Autocomplete, VueEditor },
    data: function() {
        return { 
            testes: {},
            modalEmail: false,
            emailBusy: false,
            email: {
                destinatario: '',
                assunto: '',
                texto: ''
            }, 
            editaNegocio: false,
            excluirNegocio: false,
            edicao: false,

            urlWhatsApp: 'https://wa.me/5545999628100',
            coresFrias: [
                {cor: '#046d8b', valor: '046d8b'},
                {cor: '#309292', valor: '309292'},
                {cor: '#2fb8ac', valor: '2fb8ac'},
                {cor: '#93a42a', valor: '93a42a'},
                {cor: '#ecbe13', valor: 'ecbe13'},
            ],
            coresQuentes: [
                {cor: '#efac41', valor: 'efac41'},
                {cor: '#de8531', valor: 'de8531'},
                {cor: '#b32900', valor: 'b32900'},
                {cor: '#6c1305', valor: '6c1305'},
                {cor: '#330a04', valor: '330a04'}
            ],
            etapa: {
                nome: '',
                descricao: '',
                cor: '2fb8ac'
            },
            etapas: [],
            etapasEx: [
                {id_funil_venda_etapa: 1, nome: 'Prospect', cor: '68b2f8'},
                {id_funil_venda_etapa: 2, nome: 'Lead', cor: '506ee5'},
                {id_funil_venda_etapa: 3, nome: 'Contato Inicial', cor: '68b2f8'},
                {id_funil_venda_etapa: 4, nome: 'E-mail Enviado', cor: '7037cd'},
                {id_funil_venda_etapa: 5, nome: 'Folow Up', cor: '651f71'},
                {id_funil_venda_etapa: 6, nome: 'Reunião', cor: '1d0c20'},
                {id_funil_venda_etapa: 7, nome: 'Proposta', cor: 'fd9407'},
                {id_funil_venda_etapa: 8, nome: 'Follow Up Proposta', cor: '506ee5'},
                {id_funil_venda_etapa: 9, nome: 'Stand By', cor: 'cc063e'},
            ],
            mascaras: {
                masked: true,
                cpf: '###.###.###-##',
                cnpj: '##.###.###/####-##',
                telefone: '####-####',
                ddd: '(##)',
                celular: '# ####-####',
                cep: '#####-###'
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mode: 'new',
            funil: {
                id_funil_venda: '',
                nome: '',
                descricao: '',
                vigencia_inicial: moment().format('yyyy-MM-DD'),
                vigencia_final: moment().add(7, 'days').format('yyyy-MM-DD'),
                status: 'A',
                etapas: [
                    {
                        dados: []
                    }
                ]
            },
            botaoAtividade: 'Publicar',
            funis: [],
            negocio: {},
            atividade: {
                conteudo: ''
            },
            atividades: [
                {
                    descricao: 'Alex enviou E-mail',
                    data_cad: '27/05/2024 22:03:00',
                    conteudo: ''
                }
            ],
            exibeComent: false,
            showNovo: false,
            modoNegocio: 'rolagem',
            filtroNegocios: '',
            pessoas: [],
            tipoCadastro: 'B',
            modalNovoNegocio: false,
            modalEditaNegocio: false,
            page: 1,
            count: 0,
            limit: 0,           
            show: true,
            showPainel: false,
            showOver: false,
            modalAddEtapa: false,
            modeEtapa: 'new',
            modalRemEtapa: false,
            etapaAtual: 0,
            index: 0,
            parcial: '',
            ativos: 'T',
            orcamento: {
                servicos: []
            },
            orcamentos: [],
            vendedores: [],
            planoContas: [],
            servico: {
                id_servico: 0
            },
            servicos: [],
            formas: [],
            condicoes: [],
            contratos: [],
            contrato: {
                id_pessoa: '',
                itens: []
            },
            fields: [
                {key: 'id_funil_venda', label: 'Código', sortable: true},
                {key: 'nome', label: 'Nome', sortable: true},             
                {key: 'vigencia_inicial', label: 'Vigência inicial', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },             
                {key: 'vigencia_final', label: 'Vigência final', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },              
                {key: 'status', label: 'Status', sortable: false, formatter: value => value ==='A' ? 'Aberto' : 'Concluído'},
                {key: 'actions', label: ''}
            ],

            fieldsServicos: [
                {key: 'id_servico', label: 'Código', sortable: false},
                {key: 'descricao', label: 'Descrição', sortable: false},
                {key: 'qtde', label: 'Qtde', sortable: false},
                {key: 'unitario', label: 'Unitário', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },               
                {key: 'total', label: 'Total', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },                
                {key: 'actions', label: ''}
            ],

            fieldsEtapas: [
                {key: 'id_funil_venda_etapa', label: 'Código', sortable: true},
                {key: 'sequencia', label: 'Sequencia', sortable: true},
                {key: 'nome', label: 'Nome', sortable: true},            
                {key: 'actions', label: ''}
            ],
            
            percent: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                suffix: ' %',
                precision: 2,
                masked: false
            },
            moneyItens: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            }, 
            modalAddServico: false ,
            modalRemServico: false,
            modalImprimeContrato: false,
            tipoServico: '',            
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        filtrarNegocios(neg){            
            const ng = neg.dados.filter(x => x.razao_social.toLowerCase().includes(neg.filtro.toLowerCase()))
            neg.filtrados = ng
            // this.filtroNegocios = ng
        },
        
        resetContrato() {            
            this.contrato = {
                id_contrato: '',
                id_pessoa: '',
                id_plano_conta: '',
                id_forma_pagar_receber: '',
                periodicidade: 30,
                data: moment().format('DD/MM/YYYY'),
                primeiro_vencimento: moment().format('YYYY-MM-DD'),
                encerramento: moment().add(6, 'months').format('YYYY-MM-DD'), 
                qtde_parcelas: 6,
                desc_ate_vencto: 0,
                obs: '',
                instrucoes: '',
                obs_fiscais: '',
                itens: [] 
            }
        },

        exibirEmail(e) {
            this.modalEmail = true
            this.email.destinatario = e
        },

        enviarEmail() {  
            this.emailBusy = true
            axios['post'](`${baseApiUrl}/email`, this.email)
            .then(async () => {
                
                
                
                this.atividade = {
                    tipo: 'EMAIL',
                    conteudo: { ...this.email }
                }

                await this.salvarAtividade()

                this.email = {
                    destinatario: '',
                    assunto: '',
                    texto: ''
                }   

                this.emailBusy = false   
                this.modalEmail = false    
                showSuccess('E-mail enviado com sucesso.')              
                
            })
            .catch(err => {
                this.emailBusy = false 
                showError(err)
            })           
        },

        saveOrcamento() {
            const method = this.orcamento.id_orcamento ? 'put' : 'post'
            const idorcamento = this.orcamento.id_orcamento ? `/${this.orcamento.id_orcamento}` : '' 
            const orc =  { ...this.orcamento }
            orc.data = moment(this.orcamento.data, 'DD/MM/yyyy').format('yyyy-MM-DD')
            
            axios[method](`${baseApiUrl}/orcamentos${idorcamento}`, orc)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.resetOrcamento()
                this.loadFunil(this.funil, true)
                this.loadOrcamentos(this.negocio.id_funil_venda_negocio)
                this.showNovo = false
            })
            .catch(err => {
                showError(err)
            })           
        },

        saveContrato() {
            const method = this.contrato.id_contrato ? 'put' : 'post'
            const idcontrato = this.contrato.id_contrato ? `/${this.contrato.id_contrato}` : ''  
            const contt =  { ...this.contrato }
            contt.data = moment(this.contrato.data, 'DD/MM/yyyy').format('yyyy-MM-DD')         

            axios[method](`${baseApiUrl}/contratos${idcontrato}`, contt)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.resetContrato()
                this.loadFunil(this.funil, true)
                this.loadContratos(this.negocio.id_funil_venda_negocio)
                this.showNovo = false
            })
            .catch(showError)            
        },

        addServico(t) {
            this.tipoServico = t 
            this.loadServicos()
            this.modalAddServico = true            
            this.modeServico = 'new'
        },
        loadServicos() {  
            const url = `${baseApiUrl}/servicos` 
            axios.get(url).then(res => {
                this.servicos = res.data.map(servico => {
                    return { ...servico, value: servico.id_servico, text: `${servico.id_servico} | ${servico.descricao}` }
                })
            })          
        },
        salvarServico() {
            if (this.servico.qtde < 1) {
                this.$toasted.error('Informe a quantidade do serviço.') 
            } else {
                
                const s = this.servicos.find(obj => {
                    return obj.id_servico === this.servico.id_servico;
                });

                this.servico.descricao = s.descricao

                if(this.tipoServico === 'orcamento') {
                    this.modeServico === 'edit' ? this.orcamento.servicos.splice(this.index, 1, {...this.servico}) : this.orcamento.servicos.push({...this.servico}) 
                }
                if(this.tipoServico === 'contrato') {
                    this.modeServico === 'edit' ? this.contrato.itens.splice(this.index, 1, {...this.servico}) : this.contrato.itens.push({...this.servico}) 
                }

                this.resetServico()             
            }
        }, 
        resetServico() { 
            this.enumeraServicos() 
            
            this.servico = {}
            this.servico = {
                id_servico: '',
                descricao: '',
                qtde: 1,
                unitario: 0,
                detalhes: ''
            } 
            this.modalAddServico = false 
        },
        novoOrcamento(n) {
            if (n.id_pessoa > 0) {
                this.showNovo = 'orcamento'
                this.orcamento.data = moment().format('DD/MM/YYYY')
                this.orcamento.razao_social = n.razao_social
                this.orcamento.id_pessoa = n.id_pessoa
                this.orcamento.id_funil_venda_negocio = n.id_funil_venda_negocio
    
                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()
                this.loadVendedores()
            } else {
                showError('Vincule um cadastro de pessoa ao negócio antes de criar um orçamento.')
            }
        },
        novoContrato(n) {
            if (n.id_pessoa > 0) {
                this.resetContrato()
                this.showNovo = 'contrato'
                this.contrato.data = moment().format('DD/MM/YYYY')
                this.contrato.razao_social = n.razao_social
                this.contrato.id_pessoa = n.id_pessoa
                this.contrato.id_funil_venda_negocio = n.id_funil_venda_negocio

                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()
                this.loadVendedores()
            } else {
                showError('Vincule um cadastro de pessoa ao negócio antes de criar um contrato.')
            }
        },

        salvarOrcCont() {
            if(this.showNovo === 'orcamento') {
                this.saveOrcamento()                
            } else {
                this.saveContrato()
            }
        },

        loadOrcamento(id) {
            const url = `${baseApiUrl}/orcamentos/${id}` 

            axios.get(url).then(res => {                
                this.showNovo = 'orcamento'             
                this.orcamento = { ...res.data }
                this.orcamento.data = moment(this.orcamento.data).format('DD/MM/YYYY') 

                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()
                this.loadVendedores()
                this.enumeraServicos()

                this.orcamento.servicos.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })
            }) 
            .catch(err => {
                showError(err)
            })
        },

        async loadContrato(id) {
            const url = `${baseApiUrl}/contratos/${id}`    

            await axios.get(url).then(res => {
                this.showNovo = 'contrato' 
                this.contrato = { ...res.data }
                this.contrato.data = moment(this.contrato.data).format('DD/MM/YYYY')
                this.contrato.encerramento = moment(this.contrato.encerramento).format('yyyy-MM-DD')
                this.contrato.primeiro_vencimento = moment(this.contrato.primeiro_vencimento).format('yyyy-MM-DD') 
                
                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()
                this.loadVendedores()
                this.enumeraServicos() 

                this.contrato.itens.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })
            }) 
            .catch(err => {
                showError(err)
            })
        },        

        resetOrcamento() {
            this.orcamento = {
                id_orcamento: '',
                id_pessoa: '',
                id_vendedor: '',
                id_plano_conta: '',
                id_forma_pagar_receber: '',
                id_condicao_pagar_receber: '',
                periodicidade: 30,
                data: moment().format('DD/MM/YYY'),                
                desc_ate_vencto: 0,
                obs: '',
                servicos: [],
                produtos: []
            }
        },
        
        cancelarOrcCont() {
            if(this.showNovo === 'orcamento') {
                this.resetOrcamento()
                this.showNovo = false
            } else {

                this.showNovo = false                
            }

        },

        atualizaTempo(){
            function calculaTempo(t) {
                moment.locale('pt-br')
                let y = moment(t).format('yyyy-MM-DD')
                return moment(y).fromNow()
            }

            if (this.funil.etapas) {    
                if (this.funil.etapas.length > 0) {
                    this.funil.etapas.forEach(e=>{
                        if (e.dados) {  
                            if (e.dados.length > 0) {
                                e.dados.forEach(n=>{
                                    n.tempo = calculaTempo(n.data_cad)
                                })
                            }
                        }
                    })
                }    
            }

            setInterval(()=>{
                if (this.funil.etapas) {    
                    if (this.funil.etapas.length > 0) {
                        this.funil.etapas.forEach(e=>{
                            if (e.dados) {  
                                if (e.dados.length > 0) {
                                    e.dados.forEach(n=>{
                                        n.tempo = calculaTempo(n.data_cad)
                                    })
                                }
                            }
                        })
                    }    
                }
            }, (60 * 1000) * 1)

        },
        soNumeros(value) { 
            const n = value ? value.replace(/[^\d]+/g,'') : ''
            return n.length
        },

        novoNegocio(etapa) {
            const e = this.funil.etapas
            let n = true
            this.editaNegocio = 'novo'
            this.excluirNegocio = false 

            for (var i = 0; i < etapa.sequencia-1; i++) {
                if (e[i].e_orcamento === 'S' || e[i].e_contrato === 'S' || e[i].e_email === 'S' ) {
                    n = false                    
                    showError(`Você não deve adicionar novos negócios após etapas com ações obrigatórias: ${e[i].nome}`)
                    break
                } 
            }

            if (n) {
                this.negocio.id_funil_venda_etapa = etapa.id_funil_venda_etapa
                this.modalNovoNegocio = true
                this.modoNegocio === 'novo'
            }

        },

        loadNegocio(n) {
            this.modalEditaNegocio = true   
            this.editaNegocio = false        
            this.excluirNegocio = false 

            axios['get'](`${baseApiUrl}/funil/negocio/${n.id_funil_venda_negocio}`)
            .then(res => {
                this.negocio = { ...res.data }
                this.loadAtividades(this.negocio.id_funil_venda_negocio)
            })
            .catch(err => {
                showError(err)
            })
        },

        salvarNegocio() {
            const method = this.negocio.id_funil_venda_negocio ? 'put' : 'post'
            const idNeg = this.negocio.id_funil_venda_negocio ? `/${this.negocio.id_funil_venda_negocio}` : ''  
            
            this.negocio.tipoCadastro = this.tipoCadastro

            axios[method](`${baseApiUrl}/funil/negocio${idNeg}`, this.negocio)
            .then(async () => {
                await this.loadFunil(this.funil, true)
                this.loadNegocio(this.negocio) 
                this.editaNegocio = false
            })
            .catch(err => {
                showError(err)
            })
    
        },

        async salvarAtividade() {
            const idNeg = this.negocio.id_funil_venda_negocio

            if (this.atividade.tipo === 'COMENT') {
                this.exibeComent = false   

                const c = this.atividade.conteudo.trim() 

                if (c.length < 2) { 
                    showError('Um comentário deve possuir ao menos 2 caracteres')
                    return
                }                               
            }   
            
            if (this.atividade.tipo === 'WHATS') {
                this.exibeComent = false   

                const c = this.atividade.conteudo.trim() 
                const ddd = this.negocio.ddd_celular
                const cel = this.negocio.celular

                if (!c || c.length < 2) { 
                    showError('Digite a mensagem inicial que deseja enviar via WhatsApp.')
                    return
                }   
                if (!ddd || ddd.length < 2) { 
                    showError('Informe do DDD do celular.')
                    return
                }   
                if (!cel || cel.length < 8) { 
                    showError('Numero de celular inválido ou inexistente.')
                    return
                }   

                this.abreLink(`https://wa.me/${this.negocio.ddd_celular}${this.negocio.celular}?text=${this.atividade.conteudo}`, 's')

                const msg = this.atividade.conteudo

                this.atividade.conteudo = {
                    contato: `(${this.negocio.ddd_celular}) ${this.negocio.celular}`,
                    mensagem: msg
                }
            }
            

            await axios['post'](`${baseApiUrl}/funil-atividades/${idNeg}`, this.atividade)
            .then(async () => {
                await this.loadAtividades(idNeg)
                this.atividade.conteudo = ''
            })
            .catch(err => {
                showError(err)
            })    
        },

        async loadAtividades(id) {
            await axios.get(`${baseApiUrl}/funil-atividades/${id}`)
            .then(res => {
                this.atividades = { ...res.data }                      
            }) 
            .catch(err => {
                showError(err)
            })
        }, 

        exibirComentario() {
            this.exibeComent = !this.exibeComent
            this.atividade.tipo = 'COMENT'
            this.botaoAtividade = 'Publicar'
        },

        resetNegocio() {  
            this.negocio = {}   
            this.modalNovoNegocio = false
            this.modoNegocio = 'rolagem'
            this.editaNegocio = false
            this.excluirNegocio = false
        },

        async exibirPainel(f) {
            await this.loadFunil(f, true)
            this.showPainel = true
        },

        painelOrcamento(n) {
            this.negocio = { ...n }
            this.loadOrcamentos(n.id_funil_venda_negocio)
            this.loadContratos(n.id_funil_venda_negocio)
            this.modalEditaNegocio = false
        },

        loadOrcamentos(p) {  
            const url = `${baseApiUrl}/orcamentos/negocio/${p}` 

            axios.get(url).then(res => {
                this.orcamentos = [ ...res.data ]
                this.orcamentos.forEach(o=>{
                    o.data = moment(o.data).format('DD/MM/YYYY')
                    o.servicos_total_liquido = o.servicos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                })
            }) 
            .catch(err => {
                showError(err)
            })          
        },

        loadServico(cServItem) {  
            this.modeServico = 'edit'
            this.servico = { ...cServItem }                     
            this.index = cServItem.seq - 1
                      
            this.modalAddServico = true
        },

        loadContratos(p) {  
            const url = `${baseApiUrl}/contratos/negocio/${p}` 

            axios.get(url).then(res => {
                this.contratos = [ ...res.data ]
                this.testes = [ ...res.data ]
                this.contratos.forEach(c=>{
                    c.data = moment(c.data).format('DD/MM/YYYY')
                    c.encerramento = moment(c.encerramento).format('DD/MM/YYYY')
                    // o.servicos_total_liquido = o.servicos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                })
            }) 
            .catch(err => {
                showError(err)
            })          
        },

        desejaExcluirFunil() {

        },
        opcoesFunil() {

        },
        relatorios() {

        },
        addEtapa(seq) {
            if (seq > 0) {
                this.etapaAtual = seq
            }

            this.etapa.cor = '2fb8ac'
            this.modalAddEtapa = true
            this.modeEtapa = 'new'
        },

        salvarEtapa() {
            if (this.etapa.nome.trim() === '' ) {
                this.$toasted.error('Informe um nome para a etapa.') 
            } else {
                if (this.etapaAtual > 0) {
                    this.etapas.splice((this.etapaAtual), 0, {...this.etapa})    
                    this.enumeraEtapa() 
                    this.save()
                    this.resetEtapa()               
                } else {
                    this.modeEtapa === 'edit' ? this.etapas.splice(this.index, 1, {...this.etapa}) : this.etapas.push({...this.etapa})  
                    this.enumeraEtapa() 
                    this.save()
                    this.resetEtapa()
                }
            }
        },
        resetEtapa() {
            this.enumeraEtapa() 
            
            this.etapa = {}
            this.etapa = {
                nome: '',
                descricao: '',
                cor: '2fb8ac'
            } 

            this.modalAddEtapa = false 
        },
        enumeraEtapa() { 
            this.etapas.forEach((_, i) =>{                
                this.etapas[i].sequencia = i+1
            })
        },
        mudaPosicaoEtapa(e, acao) {

            const posicao = e.sequencia - 1

            function changePosition(arr, from, to) {
                arr.splice(to, 0, arr.splice(from, 1)[0]);
                return arr;
            }

            if (acao === 'subir'){
                if (posicao > 0) changePosition(this.etapas, posicao, posicao-1)
            } else {
                if (posicao < (this.etapas.length - 1)) changePosition(this.etapas, posicao, posicao+1)
            }

            this.enumeraEtapa()
        },

        async moverNegocio(n, i, m, e) {
            
            this.negocio = { ...n } 
            this.edicao = e
            this.testes = this.edicao 
            let c = {}
            let antes = this.etapas.find(x => x.id_funil_venda_etapa === n.id_funil_venda_etapa )
            let depois = {}


            if (m === 'avancar') { 

                c.acao = 'avançou'
                depois = this.etapas.find(x => x.id_funil_venda_etapa === this.etapas[i+1].id_funil_venda_etapa)
                

                if (this.etapas[i].e_orcamento === 'S' && this.negocio.e_orcamento_feito !== 'S') {
                    showError('Esta estapa requer que seja gerado um orçamento antes de avançar.')
                    return
                }
                if (this.etapas[i].e_contrato === 'S' && this.negocio.e_contrato_feito !== 'S') {
                    showError('Esta estapa requer que seja gerado um contrato antes de avançar.')
                    return
                }
                if (this.etapas[i].e_email === 'S' && this.negocio.e_email_enviado !== 'S') {
                    showError('Esta estapa requer um envio de e-mail antes de avançar.')
                    return
                }

                this.negocio.id_funil_venda_etapa = this.etapas[i+1].id_funil_venda_etapa                

            } else {
                c.acao = 'retrocedeu'
                depois = this.etapas.find(x => x.id_funil_venda_etapa === this.etapas[i-1].id_funil_venda_etapa)

                this.negocio.id_funil_venda_etapa = this.etapas[i-1].id_funil_venda_etapa
            }

            c.detalhes = `${c.acao} de ${ antes.nome } para ${ depois.nome }`

            this.atividade = {
                descricao: 'mudou a etapa',
                tipo: 'ETAPA',
                conteudo: { ...c }
            }

            await this.salvarAtividade()


            this.salvarNegocio()
        },

        enviarWhats() {
            this.atividade.tipo = 'WHATS'
            this.botaoAtividade = 'Enviar'
            this.exibeComent = !this.exibeComent            
        },

        async abreLink(url, tipo){
            if (tipo === 's') {  //Site
                window.open(url , '_blank');

            } 
            if (tipo === 'e') {  //E-mail
                window.open(url);
            } 
        },

        removeEtapa() {            
            this.etapas.splice(Number(this.index), 1)
            this.modalRemEtapa = false
            this.resetEtapa()
        },
        
        loadEtapa(e) {
            this.modeEtapa = 'edit'
            this.etapa = { ...e }                     
            this.index = e.sequencia - 1
                      
            this.modalAddEtapa = true
        },
        desejaRemoverEtapa(e) {
            this.index = Number(e.sequencia) - 1
            this.modalRemEtapa = true
        },
        novoFunil() {
            this.mode = 'save'
            
            this.funil = {
                id_funil_venda: '',
                nome: '',
                descricao: '',
                vigencia_inicial: moment().format('yyyy-MM-DD'),
                vigencia_final: moment().add(7, 'days').format('yyyy-MM-DD'),
                status: 'A',
                etapas: [
                    {
                        dados: []
                    }
                ]
            }

            this.etapas = [ ...this.etapasEx ]
            this.enumeraEtapa()
            this.show = false
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadFunis()
        },        
        loadFunis() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&ativos=${this.ativos}&parcial=${this.parcial}&tipo=cadfunilvenda` 

            axios.get(url).then(res => {
                this.funis = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(showError)           
        },
        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data
            })
        },
        onSelect(p) {
            this.negocio.id_pessoa = p.idpessoa
            this.negocio.email = p.email
            this.negocio.ddd_celular = p.ddd_celular
            this.negocio.ddd_telefone = p.ddd_telefone
            this.negocio.celular = p.celular
            this.negocio.telefone = p.telefone
            this.negocio.cpf = p.cpf
            this.negocio.cnpj = p.cnpj
            this.negocio.tipo_fj = p.tipo_fj
        },
        reset() {
            this.mode = 'new'
            
            this.funil = {
                id_funil_venda: '',
                nome: '',
                descricao: '',
                vigencia_inicial: moment().format('yyyy-MM-DD'),
                vigencia_final: moment().add(7, 'days').format('yyyy-MM-DD'),
                status: 'A',
                etapas: [
                    {
                        dados: []
                    }
                ]
            }

            this.loadFunis()
            this.show = !this.show
        },
        save() {
            const method = this.funil.id_funil_venda ? 'put' : 'post'
            const idfunil = this.funil.id_funil_venda ? `/${this.funil.id_funil_venda}` : '' 
            this.funil.etapas = [ ...this.etapas ]         

            axios[method](`${baseApiUrl}/funil${idfunil}`, this.funil)
            .then(() => {
                if (this.showPainel) {
                    this.loadFunil(this.funil, true)
                } else {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                }
            })
            .catch(err => {
                showError(err)
            })           
        },
        remove() {
            const idfunil = this.funil.id_funil_venda
            axios.delete(`${baseApiUrl}/funil/${idfunil}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(err => {
                    showError(err)
                })
        },

        removeNegocio(id) {
            axios.delete(`${baseApiUrl}/funil/negocio/${id}`)
            .then(() => {
                showSuccess('Negócio removido com sucesso.')
                this.loadFunil(this.funil, true)
                this.resetNegocio()
            })
            .catch(err => {
                showError(err)
            })
        },

        calculaServicos() {
            const qtde = ((!this.servico.qtde) || (this.servico.qtde < 0)) ? 0 : this.servico.qtde
            const unitario = ((!this.servico.unitario) || (this.servico.unitario < 0)) ? 0 : this.servico.unitario
            
            this.servico.total = qtde * unitario           
        },

        enumeraServicos(){
            if (this.tipoServico === 'orcamento') {
                this.orcamento.servicos_total_liquido = 0                    
    
                this.orcamento.servicos.forEach((_, i) =>{                
                    this.orcamento.servicos[i].seq = i+1
                    const total = this.orcamento.servicos[i].total
                    this.orcamento.total_servicos = this.orcamento.total_servicos + total
                })
            }

            if (this.tipoServico === 'contrato') {
                this.contrato.total_servicos = 0                    

                this.contrato.itens.forEach((_, i) =>{                
                    this.contrato.itens[i].seq = i+1
                    const total = this.contrato.itens[i].total
                    this.contrato.total_servicos = this.contrato.total_servicos + total
                })
            }
        },

        async loadFunil(funil, painel, mode = 'save') {
            await axios.get(`${baseApiUrl}/funil/${funil.id_funil_venda}`)
            .then(res => {
                this.funil = { ...res.data }
                this.etapas = [ ...res.data.etapas ]
                this.funil.vigencia_inicial = moment(this.funil.vigencia_inicial).format('yyyy-MM-DD')
                this.funil.vigencia_final = moment(this.funil.vigencia_final).format('yyyy-MM-DD')

                this.etapas.forEach(e=>{
                    e.filtrados = [...e.dados]
                })

                if (this.etapaAtual > 0 ) {
                    this.etapaAtual = 0
                } else {
                    if (!painel) {
                        this.show = false
                        
                    }
                }
                this.atualizaTempo()

                this.mode = mode                
            }) 
            .catch(err => {
                showError(err)
            })
        }, 

        
        
        loadVendedores() {  
            const url = `${baseApiUrl}/vendedores`

            axios.get(url).then(res => {
                this.vendedores = res.data.map(vendedor => {
                    return { ...vendedor, value: vendedor.idpessoa, text: `${vendedor.idpessoa} | ${vendedor.razao_social}` }
                })
            })
        }, 

        loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma, value: forma.id_forma_pagar_receber, text: `${forma.id_forma_pagar_receber} | ${forma.descricao}` }
                })
            })
        }, 
        loadCondicoes() {
            const url = `${baseApiUrl}/condicoes_pagar_receber`
            axios.get(url).then(res => {
                this.condicoes = res.data.map(condicao => {
                    return { ...condicao, value: condicao.id_condicao_pagar_receber, text: `${condicao.id_condicao_pagar_receber} | ${condicao.descricao}` }
                })
            })
        }, 
        loadPlanoContas() {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
            })
        },
    
    },
    watch: {
        page() {
            this.loadFunis()
        },
        'servico.unitario': function() {
            this.calculaServicos()
        }
    }
    // mounted() {
    //     this.atualizaTempo()
    // }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

   
    .funil-topo-bg {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        padding: 5px 5px 0px 10px;
        width: 255px;
        left: -25px;
        top: 0;
        bottom: 0;
        right: 13px;
        z-index: -999;
        background: #7a7a7a;
        border-radius: 0px 5px 40px 5px;
    }  

    .funil-topo-bg p {
        float: left; 
        margin: 0;        
    }

    .funil-topo-bg button {
        margin: 0 15px 0 0 !important;
        height: 20px;
    }

    .funil-topo-bg button:hover {
        background-color: transparent !important;
    }

    .contenedor{
		background-color:#CCC;
		width:100%;
		height:100px;
        display:flex;
        justify-content: space-between;
	}
	.contenido{
		height:80px;
		width:10%;
		float:left;
		margin: 10px;
	}
    
    .funil-texto {        
        font-size: 1.2rem;
        color: aliceblue;        
    }     

    .toolbal-item-funil {
        margin-right: -10px !important;        
    }

    .toolbal-item-funil i {
        font-size: 1rem;
        margin: 1px 1px 1px 5px;
    }


    .funil-div-btn {
        display: flex;
        text-align: center;
    }

    .funil-bt-novo-negocio {
        width: 45px;
        margin: 0 10px 15px 10px;
    }

    .funil-bt-pesquisa {
        width: 100%;
        margin: 0 8px 15px 0;

    }
    

    .funil-section {
        margin-top: 20px;
        padding: 0 0 10px 0;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        display: grid;
        width: 100%;
        grid-auto-flow: column;
        gap: 10px;
    }
    .funil-section::-webkit-scrollbar {      
        width: 3px;
        height: 8px; /* A altura só é vista quando a rolagem é horizontal */
        border-radius: 5px;
    }
    .funil-section::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
    }
    .funil-section::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
    }

    .funil-etapa {        
        scroll-snap-align: start;
        width: 250px;
        height: 65vh;
        
        flex-shrink: 0;
        border-left: 1px dashed rgb(160, 160, 160);
    }

    .funil-atividade-list {
        padding-right: 5px;
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .funil-atividade-list::-webkit-scrollbar {      
        width: 3px;
        height: 3px; /* A altura só é vista quando a rolagem é horizontal */
    }
    .funil-atividade-list::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
    }
    .funil-atividade-list::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
    }

    .funil-dados-list {
        padding-right: 5px;
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .funil-dados-list::-webkit-scrollbar {      
        width: 3px;
        height: 3px; /* A altura só é vista quando a rolagem é horizontal */
    }
    .funil-dados-list::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
    }
    .funil-dados-list::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
    }


    .funil-etapa-list {
        padding-right: 5px;
        height: 55vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .funil-etapa-list::-webkit-scrollbar {      
        width: 3px;
        height: 3px; /* A altura só é vista quando a rolagem é horizontal */
    }
    .funil-etapa-list::-webkit-scrollbar-track {
        background: transparent;
        padding: 2px;
    }
    .funil-etapa-list::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
    }

    .funil-etapas {
        height: 65vh;
        margin: 10px 0 10px 0 !important;
        width: 100%;
        /* height: 400px; */

        display: grid;
        flex-direction: column;
        align-items: center;
        /* align-content: center;
        justify-items: center; */
        gap: 20px;
    }

    .detalhes-neg-span {
        font-size: 1rem;
        color: #747474;
    }

    .cont-neg-span {
        font-size: 1.21rem;
    }

    .obs-negocio {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
    }

    .obs-negocio:hover {
        text-overflow: unset;
        white-space: normal;
        /* overflow: hidden;  */
    }

    .head-edita-negocio {
        display: flex;
        align-items: stretch;
    }
    .title-edita-negocio {
        margin-right: 20px;
    }
    .btn1-edita-negocio {
        color: #6c757d;
        margin: -5px 0 0 0;
        font-size: 1.6rem;
    }
    .btn2-edita-negocio {
        margin: -5px 0 0 0;
        color: #5dc289;
        font-size: 1.6rem;
    }
    .et-edita-negocio {
        margin: 3px 10px 0 10px;
        font-size: 1rem;
        font-weight: 600;
    }

    .div-hr{ 
        margin: -16px 20px 0 0;
        float: left;
        border:         none;
        border-left:    1px solid #d2d6eb !important;
        height:         100% !important;
        width:          1px !important;       
    }

    .botoes-acao {
        margin-bottom: 30px;        
    }

    .botoes-acao button {
        margin: 0 5px 5px 0;
        font-size: 0.8rem;  
        font-weight: 600;   
    }

    .botoes-acao i {
        margin-right: 5px; 
        font-size: 1.1rem;   
    }

    .atividades-card {
        padding: 5px 10px 5px 25px;
        border: none;
    }

    .atividades-card h5 {
        font-size: 0.9rem;
        font-weight: 600;
    }

    .atividades-card small {
        font-weight: 600;
    }

    .atividades-card i {
        margin: 0 5px -15px -25px;
        font-size: 1.2rem;
    }

    .mais-det-icon {
        margin-left: 25px;
        display: flex;
        color: #4c85db;
    }

    /* .mais-det-icon i {
        margin-top: 115px;
    } */



</style>