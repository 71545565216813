<template>
    <div class="home">       

        <PageTitle icon="fa fa-home" main="Dashboard"
            sub="Painel administrativo" />

        <!-- <div>
            <v-card
                class="mx-auto text-center"
                color="green"
                primary
                max-width="600"
            >
                <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                    :value="value"
                    color="rgba(255, 255, 255, .7)"
                    height="100"
                    padding="24"
                    stroke-linecap="round"
                    smooth
                    >
                    <template v-slot:label="item">
                        ${{ item.value }}
                    </template>
                    </v-sparkline>
                </v-sheet>
                </v-card-text>

                <v-card-text>
                <div class="text-h4 font-weight-thin">
                    Sales Last 24h
                </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                <v-btn
                    block
                    text
                >
                    Go to Report
                </v-btn>
                </v-card-actions>
            </v-card>
            
            <v-card
                class="mt-4 mx-auto"
                max-width="400"
            >
                <v-sheet
                class="v-sheet--offset mx-auto"
                color="cyan"
                elevation="12"
                max-width="calc(100% - 32px)"
                >
                <v-sparkline
                    :labels="labels"
                    :value="value"
                    color="white"
                    line-width="2"
                    padding="16"
                ></v-sparkline>
                </v-sheet>

                <v-card-text class="pt-0">
                <div class="text-h6 font-weight-light mb-2">
                    User Registrations
                </div>
                <div class="subheading font-weight-light grey--text">
                    Last Campaign Performance
                </div>
                <v-divider class="my-2"></v-divider>
                <v-icon
                    class="mr-2"
                    small
                >
                    mdi-clock
                </v-icon>
                <span class="text-caption grey--text font-weight-light">last registration 26 minutes ago</span>
                </v-card-text>
            </v-card>
        </div> -->

        
        <div class="stats"> 
            <Stat 
                title="Contratos" 
                detail="Encerramento próx. 30 dias" 
                :value="estatisticas.contratos" 
                icon="fa fa-gavel" 
                iconDetail="fa fa-calendar-o" 
                color="rgb(253, 154, 19)"
                exibeDetail="true"
                tipo='numero'
            />            
            <Stat 
                title="A Receber" 
                :value="estatisticas.receber" 
                detail="Nos próximos 7 dias" 
                icon="fa fa-usd" 
                iconDetail="fa fa-calendar-o" 
                color="#3bc480"
                exibeDetail="true"
                tipo='moeda'
            />
            <Stat 
                title="A Pagar" 
                :value="estatisticas.pagar" 
                detail="Nos próximos 7 dias" 
                icon="fa fa-usd" 
                iconDetail="fa fa-calendar-o" 
                color="#d54d50"
                exibeDetail="true"
                tipo='moeda'
            />
        </div>

        <b-row>
            <b-col md="6" lg="6" sm="12">
                <b-card title="Andamento das consultorias"> 
                    <Bar
                        style="height: 300px"
                        :chart-options="chartOptions"
                        :chart-data="chartData"
                        chart-id="graf-rosca"
                    />
                </b-card>
            </b-col>
        </b-row>

        <div class="container-home">
        </div>
        
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import { baseApiUrl, showError } from '@/global'
import Stat from './Stat.vue'
import axios from 'axios'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register( Tooltip, BarElement, CategoryScale, LinearScale)

export default {
    name: 'Home',
    components: { PageTitle, Stat, Bar },
    data: function() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }, 
            chartData: {
                labels: [],
                datasets: [
                    {
                        title: 'asdfasdf',
                        label: '% concluída: ',
                        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                        data: []
                    }
                ]
            },
            labels: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sáb',
            ],
            value: [
                200,
                675,
                410,
                390,
                310,
                460,
                250,
            ],
            estatisticas: {
                lancamentos: 0,
                vendas: 0,
                receber: 0,
                pagar: 0
            },
            accounts: null,
            account: null,
            teste: 'teste'
        }    
    },
    created() {
        // accountService.getAll().then(x => this.accounts = x)
        // accountService.account.subscribe(x => this.account = x)
    },
    methods: {
        loadStats() {             
            const url = `${baseApiUrl}/stats`    

            axios.get(url).then(res => {
                this.estatisticas.contratos = (res.data.contratos || 0)
                this.estatisticas.vendas = (res.data.vendas || 0)
                this.estatisticas.receber = (res.data.receber || 0)
                this.estatisticas.pagar = (res.data.pagar || 0)

                this.consultorias = (res.data.consultoriasProg || [])
                this.consultorias.forEach(c => {
                    this.chartData.labels.push(c.nome)
                    const p = parseInt(c.qtde_concluida) > 0 ? ((parseInt(c.qtde_concluida) / parseInt(c.qtde))*100) : 0 //.toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 2})
                    this.chartData.datasets[0].data.push(p)
                })
            })
            .catch(err => {
                showError(err)
            })         
        },
    },

    mounted() {
        this.loadStats()
    }
}
</script>

<style>
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }

    .stats {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .container-home {
        padding: 15px;
    }

    .row-home {
        margin-bottom: 20px;
    }

    .card-notification {
        height: 400px;
        width: 100%;
    }

    a { cursor: pointer }

    .btn-facebook {
        background: #3B5998;
        color: #fff;
    }

    .btn-facebook:hover {
        color: #fff;
        opacity: 0.8;
    }

    .btn-delete-account {
        width: 40px;
        text-align: center;
        box-sizing: content-box;
    }
</style>
