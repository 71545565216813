<template>
    <div class="consultorias-pages">
        <PageTitle icon="fa fa-rocket" main="Consultorias"
            sub="Consulta e manutenção de projetos de consultoria"/>
        <Consultorias />         
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import Consultorias from './Consultorias'

export default {
    name: 'ConsultoriaPages',
    components: { PageTitle , Consultorias }
}
</script>

<style>

</style>
