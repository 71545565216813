<template>
    <div class="dre-caixas">    
        <div class="acoesTopo"> 
            <b-button variant="primary" class="botoesAcao" v-b-tooltip.hover.bottomleft="'Baixar PDF'" 
                @click="salvarPdf('DRE','dre-render-pdf')">
                    <i style="margin-right: 10px" class="fa fa-cloud-download button-icons-acoes"></i> PDF
            </b-button>  
        </div> 

        <b-card class="cadastros-card-fluxo">            
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Filial">
                                <b-form-select style="width: 200px; margin-right: 10px;" id="conta-bancaria" v-model="dreFilial" :options="filiais"/>    
                            </b-input-group> 

                            <b-input-group>                                                               
                                <b-form-radio-group
                                    class="mr-2"
                                    id="btn-radios-tipo-dre"
                                    v-model="tipoDre"
                                    :options="optionsTipo"
                                    button-variant="outline-secondary"
                                    name="radios-btn-tipo-dre"
                                    buttons
                                ></b-form-radio-group>
                            </b-input-group>

                            <b-input-group prepend="Período">
                                <b-form-input type="date" v-model="dreDataInicial"></b-form-input>
                                <b-form-input type="date" v-model="dreDataFinal"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="relatorio" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row>
            <hr>
        
            <div v-if="relatorioDRE.length > 0" style="width: 100%" class="d-flex justify-content-md-center">
                <section class="pdf-item">
                    <div class="myStyle" >
                        <div id="dre-render-pdf" class="containerPdf">
                            <h3 style="color: #4747f1">{{ configRel.param.titulo }}</h3>
                            <h5>{{ configRel.param.subtitulo2 }}</h5>
                            <h6>{{ configRel.param.subtitulo }}</h6>
                            
                            <v-data-table 
                                :headers="headers"
                                :items="relatorioDRE"
                                :sort-by="ordenar"
                                :group-by="agrupador"
                                hide-default-footer
                                dense
                                :items-per-page="1000"
                                style="padding: 0px"
                            >
                                
                                <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
                                    <td :colspan="headers.length">
                                        <div class="cabecalho-grupo">
                                            <v-btn @click="toggle" x-small icon :ref="group">
                                                <v-icon v-if="isOpen">mdi-minus</v-icon>
                                                <v-icon v-else>mdi-plus</v-icon>
                                            </v-btn>

                                            <span class="mx-1 font-weight-bold">{{ group }}</span>
                                            
                                            <span class="font-weight-bold total-grupo">
                                                {{ (items[0].total || 0).toLocaleString('pt-br', {minimumFractionDigits: 2}) }} </span>
                                            <span class="font-weight-bold porc-grupo">
                                                {{ items[0].porcGrupo }} </span>
                                        </div>
                                    </td>
                                </template> 
                                <template v-slot:[`item.valor`]="{ item }">   
                                    <span v-if="item.DESCRICAO != ''"> {{ moedaBr(item.valor) }} </span>   
                                    
                                </template>  
                                <template v-slot:[`item.CONTA`]="{ item }">   
                                    <span v-if="item.DESCRICAO != ''">{{ item.CONTA }}</span>
                                </template>                   
                                                    
                            </v-data-table>  
                        </div>
                    </div>
                </section>   
            </div>

        </b-card>   
       
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"
// import { showSuccess } from '../../global'
// import jsPDF from "jspdf"
// import html2canvas from "html2canvas"
export default {
    name: 'Dre',
    components: {  },
    data: function() {
        return {
            headers: [
                {
                    text: '',
                    align: 'start',
                    value: 'AGRUPADOR', //AGRUPADOR
                    groupable: false,
                },
                { text: '', value: 'CONTA', align: 'left' },
                { text: '', value: 'DESCRICAO', align: 'left' },
                { text: '', value: 'valor', align: 'right' },
                { text: '', value: 'porc', align: 'right' },
            ],
            relacao: [],
            agrupador: ['AGRUPADOR',2], //AGRUPADOR
            // agrupador: 'AGRUPADOR', //AGRUPADOR
            ordenar: '',
            configRel: { 
                param: {
                    titulo: 'DRE - Demonstrativo de resultados',
                    subtitulo: `Período: ${moment(this.dreDataInicial).format('DD/MM/YYYY')} à ${moment(this.dreDataFinal).format('DD/MM/YYYY')}`,
                    arquivo: 'dre-demonstrativo-de-resultados',
                    datainicial: this.dreDataInicial,
                    datafinal: this.dreDataFinal,
                    
                    page: 1,
                    limit: 10,
                    //order: 'asc'
                } 
            } ,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }, 
            // DRE
            tipoDre: 'S',
            dreFilial: 0,
            filiais: [],
            filialX: {},
            relatorioDRE: [],
            dreDataInicial: moment().add(-1, 'M').format('YYYY-MM-01'),
            dreDataFinal: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
            optionsTipo: [
                { text: 'Sintético', value: 'S' },
                { text: 'Analítico', value: 'A' }
            ]
            
        }
    },
    methods: {
        relatorio: async function() {

            let filial = ''

            this.filiais.forEach( obj => {
                if (obj.value === this.dreFilial) {
                    filial = obj.text
                } 
            }) 
            
            this.configRel.param = {  
                titulo: `DRE - Demonstrativo de resultados (${this.tipoDre === 'S' ? 'Sintético' : 'Analítico'})`,
                subtitulo2: `Filial: ${filial}`,
                subtitulo: `Período: ${moment(this.dreDataInicial).format('DD/MM/YYYY')} à ${moment(this.dreDataFinal).format('DD/MM/YYYY')}`,
                arquivo: 'dre-demonstrativo-de-resultados',
                datainicial: this.dreDataInicial,
                datafinal: this.dreDataFinal,
                
                page: 1,
                limit: 10,
                //order: 'asc'
            }           

            const url = `${baseApiUrl}/dre/?datainicial=${this.configRel.param.datainicial}&datafinal=${this.configRel.param.datafinal}&tipo=${this.tipoDre}&filial=${this.dreFilial}`    

            await axios.get(url).then(res => {
                this.relatorioDRE = res.data
            }).catch(err => {
                showError(err)
            })           
            
        },
        moedaBr(valor) {
            return valor.toLocaleString('pt-br', {minimumFractionDigits: 2})
        },

        salvarPdf(nome, id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.1,0.1,0.1,0.1],
                filename: `${nome}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },
        loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'TODAS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },
    
    },
    mounted() {
        this.loadFiliais()
    },
    watch: {
        dreFilial() {
            this.relatorio()
        },
        tipoDre() {
            this.relatorio()
        },
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .opcoes-faturar {
        display: flex;
        align-items: center;
        width: 200px;
        padding: 25px;
        background-color: beige;
    }

    .opcoes-faturar:hover {
        background-color: rgb(197, 197, 197);
    }

    .opcoes-faturar-f {
        width: 30%;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    



</style>