<template>
    <div class="plano-contas-pages">
        <PageTitle icon="fa fa-clipboard" main="Plano de contas"
            sub="Cadastros & Cia" />
        <div class="plano-contas-pages-tabs">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Plano de contas">
                        <CadPlanoContas />
                    </b-tab>
                    <b-tab title="Grupos">
                        <CadGrupoPlanoContas />
                    </b-tab>
                    <b-tab title="Relatórios"> 
                        <b-row>
                            <b-col b-col md="6" sm="12">
                                <b-card border-variant="dark" header-tag="header">
                                    <template #header>
                                        <h5 class="mb-0">DRE - Demostrativo de resultados</h5>
                                    </template>

                                    <b-row>
                                        <b-col md="6" sm="12">
                                            <b-form-group label="Data inicial" label-for="dre-data-inicial">
                                                <b-form-input id="dre-data-inicial" type="date" v-model="dreDataInicial" required/>
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="6" sm="12">
                                            <b-form-group label="Data final" label-for="dre-data-final">
                                                <b-form-input id="dre-data-final" type="date" v-model="dreDataFinal" required/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col align="right">
                                            <b-button  variant="primary" style="color: white" @click="relatorio">Gerar demonstrativo</b-button>
                                        </b-col>
                                    </b-row>
                                </b-card>

                                <!-- MODAL DO DRE --> 
                                <b-modal size="huge" scrollable ref="relatorio-modal" hide-footer>                            
                                    <template #modal-header="{ close }" >
                                        <b-row align-v="center" style="padding-left: 20px">
                                            <b-col md="11" sm="11">
                                                <b-button variant="primary" style="color: white" 
                                                    @click="()=>{gerarPdf = gerarPdf + 1 }">
                                                        <i class="fa fa-print" ></i>
                                                        Imprimir
                                                </b-button>
                                            </b-col>
                                            <b-col md="1" sm="1">
                                                <b-button size="sm" variant="outline-default" @click="close()">
                                                    <i class="fa fa-times" style="font-size: 22px"></i>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </template>

                                    <RelatorioDRE :itens="relatorioDRE" :gerarPdf="gerarPdf" :config="configRel"/>

                                </b-modal>

                            </b-col>
                        </b-row> 
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>        
        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import { mapState } from 'vuex'
import axios from 'axios'
import PageTitle from '@/components/template/PageTitle'
import CadPlanoContas from './CadPlanoContas'
import CadGrupoPlanoContas from './CadGrupoPlanoContas'
import RelatorioDRE from '@/components/financeiro/planoDeContas/report/RelatorioDRE'
const moment = require('moment')

export default {
    name: 'PlanoContasPages',
    computed: mapState(['user']),
    components: { PageTitle, CadPlanoContas, CadGrupoPlanoContas, RelatorioDRE },
    data: function(){
        return {
            gerarPdf: 0,
            relatorioDRE: [],
            dreDataInicial: moment().add(-1, 'M').format('YYYY-MM-01'),
            dreDataFinal: moment().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
            configRel: {
                headers: {},
                param: {}
            },
        }
    },
    methods:{
        relatorio: async function() {

            this.configRel.headers = [
                {
                    text: '',
                    align: 'start',
                    value: 'agrupador',
                    groupable: false,
                },
                { text: '', value: 'CONTA', align: 'left' },
                { text: '', value: 'DESCRICAO', align: 'left' },
                { text: '', value: 'valor', align: 'right' },
                { text: '', value: 'porc', align: 'right' },
            ]
            this.configRel.param = {  
                titulo: 'DRE - Demonstrativo de resultados',
                subtitulo: `Período: ${moment(this.dreDataInicial).format('DD/MM/YYYY')} à ${moment(this.dreDataFinal).format('DD/MM/YYYY')}`,
                arquivo: 'dre-demonstrativo-de-resultados',
                datainicial: this.dreDataInicial,
                datafinal: this.dreDataFinal,
                agrupador: 'AGRUPADOR',
                ordenar: '',
                
                page: 1,
                limit: 10,
                //order: 'asc'
            }           

            const url = `${baseApiUrl}/dre/?datainicial=${this.configRel.param.datainicial}&datafinal=${this.configRel.param.datafinal}`    

            await axios.get(url).then(res => {
                this.relatorioDRE = res.data
            }).then(() => {               
                this.$refs['relatorio-modal'].show()
            }).catch(err => {
                showError(err)
            })           
            
        },
    }
}
</script>

<style>

</style>
