import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"pdf-item"},[_c('div',{staticClass:"myStyle"},[_c('div',{staticClass:"containerPdf",attrs:{"id":"renderPDF"}},[_c('h3',[_vm._v(_vm._s(_vm.config.param.titulo))]),_c('h6',[_vm._v(_vm._s(_vm.config.param.subtitulo))]),_c(VDataTable,{staticStyle:{"padding":"0px"},attrs:{"headers":_vm.headers,"items":_vm.relacao,"sort-by":_vm.ordenar,"group-by":_vm.agrupador,"hide-default-footer":"","dense":"","items-per-page":1000},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var items = ref.items;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"cabecalho-grupo"},[_c(VBtn,{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v("mdi-minus")]):_c(VIcon,[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-1 font-weight-bold"},[_vm._v(_vm._s(group))]),_c('span',{staticClass:"font-weight-bold total-grupo"},[_vm._v(" "+_vm._s((items[0].total || 0).toLocaleString('pt-br', {minimumFractionDigits: 2}))+" ")]),_c('span',{staticClass:"font-weight-bold porc-grupo"},[_vm._v(" "+_vm._s(items[0].porcGrupo)+" ")])],1)])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.DESCRICAO != '')?_c('span',[_vm._v(" "+_vm._s(_vm.moedaBr(item.valor))+" ")]):_vm._e()]}},{key:"item.CONTA",fn:function(ref){
var item = ref.item;
return [(item.DESCRICAO != '')?_c('span',[_vm._v(_vm._s(item.CONTA))]):_vm._e()]}}],null,true)})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }