<template>
    <div id="wrapper" >  
        <div class="acoesTopo" v-if="!showPainel">             
            <b-button variant="danger" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                @click="reset">
                    <i class="fa fa-times button-icons-acoes"></i>
            </b-button>
            <!-- <b-button variant="danger" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Excluir'"
                @click="desejaExcluirConsultoria">
                    <i class="fa fa-trash button-icons-acoes"></i>
            </b-button> -->
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                @click="save">
                    <i class="fa fa-check button-icons-acoes"></i>
            </b-button>                        
            <!-- <b-button variant="success" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Opções'"
                @click="opcoesConsultoria">
                    <i class="fa fa-cogs button-icons-acoes"></i>
            </b-button>  -->
            <!-- <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Relatórios'"
                @click="relatorios">
                    <i class="fa fa-file-text-o button-icons-acoes"></i>
            </b-button>             -->
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Nova Consultoria'"
                @click="novaConsultoria">
                    <i class="fa fa-plus button-icons-acoes"></i>
            </b-button> 
        </div>

        <div class="acoesTopo" v-if="showPainel">             
            <b-button variant="primary" class="botoesAcao" v-if="showPainel" v-b-tooltip.hover.bottomleft="'Voltar'"
                @click="()=> {showPainel = false; show=true; mode='new';}">
                    <i class="fa fa-arrow-left button-icons-acoes"></i>
            </b-button>         
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show && !showPainel">            
            <b-row>
                <b-col md="4" sm="12">
                    <b-form-group>
                        Localizar
                        <b-form-input id="localizar" type="search"
                            :formatter="upperCase"
                            v-model="parcial"
                            :readonly="mode === 'remove'"
                            placeholder="Informe o nome do projeto de consultoria..." />
                    </b-form-group>
                </b-col> 

                <b-col md="3" sm="12">
                    <b-form-group>
                        Prazo inicial
                        <b-form-input id="data-inicial" type="date"
                            v-model="dataInicial"
                            :readonly="mode === 'remove'" />
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group>
                        Prazo final
                        <b-form-input id="data-final" type="date"
                            v-model="dataFinal"
                            :readonly="mode === 'remove'" />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-show="!show && !showPainel">
                <b-form>
                    <input id="consultoria-id" type="hidden" v-model="consultoria.id_consultoria" />

                    <b-row>
                        <b-col md="2" sm="12">
                            <b-form-group label="Data" label-for="data-docto-consultoria">
                                <b-form-input id="data-docto-consultoria" type="date"
                                    v-model="consultoria.data_cad"
                                    readonly/>
                            </b-form-group>
                        </b-col>

                        <b-col md="2" sm="12">
                            <b-form-group label="Prazo Final" label-for="prazo-docto-consultoria">
                                <b-form-input id="prazo-docto-consultoria" type="date"
                                    v-model="consultoria.prazo"/>
                            </b-form-group>
                        </b-col> 

                        <b-col md="5" sm="12"> 
                            <b-form-group label="Pessoa" label-for="contrato-pessoa">
                                <vue-single-select
                                    id="contrato-pessoa"
                                    placeholder="Procurar"
                                    v-model="pessoaSelecionada"
                                    :options="pessoas"
                                    option-key="value"
                                    option-label="text"
                                ></vue-single-select>
                            </b-form-group>
                        </b-col> 

                        <b-col md="3" sm="12">
                            <b-form-group label="Contrato" label-for="contrato-consultoria">
                                <vue-single-select
                                    id="contrato-consultoria"
                                    placeholder="Procurar"
                                    v-model="contratoSelecionado"
                                    :options="contratos"
                                    option-key="value"
                                    option-label="text"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                ></vue-single-select>
                            </b-form-group>
                        </b-col>  
                    </b-row>

                    <b-row>
                        <b-col md="5" sm="12">
                            <b-form-group label="Nome do Plano de Consultoria" label-for="consultoria-nome"> 
                                <b-form-input id="consultoria-nome" type="text"
                                    :formatter="upperCase"
                                    v-model="consultoria.nome" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group>                             
                        </b-col> 
                        <b-col md="7" sm="12">
                            <b-form-group label="Descrição" label-for="consultoria-descricao"> 
                                <b-form-textarea
                                    id="consultoria-descricao"
                                    v-model="consultoria.descricao"
                                    rows="6"
                                    max-rows="12"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col> 
                    </b-row>                                    

                    <b-card header="Membros" header-bg-variant="light">  
                        <b-row> 
                            <div>
                                <b-button variant="primary" @click="addMembro">
                                    <i class="fa fa-plus"></i> Membro
                                </b-button>                            
                            </div>
                        </b-row>

                        <b-modal 
                            hideBvModalEvent
                            v-model="modalAddMembro"                        
                            id="modal-tipos" 
                            centered size="sm" 
                            ok-only
                            ok-disabled
                            title="Membro"
                        >  
                            <b-row>
                                <b-col md="12" sm="12">
                                    <b-row>
                                        <b-col md="12" sm="12">
                                            <b-form-group label="Responsável" label-for="resp-name-input-c" >
                                                <b-form-select id="resp-name-input-c" v-model="membro.id_usuario" :options="membros" :select-size="6"/>
                                            </b-form-group>  
                                        </b-col>
                                    </b-row>                                    
                                </b-col>
                            </b-row>

                            <template #modal-footer>
                                <div class="w-100"> 
                                    <b-col md="10" sm="10">
                                        <b-button variant="primary" v-if="modeAddMembro === 'save'"
                                            @click="salvarMembro">Salvar</b-button>
                                        <b-button class="ml-2" @click="resetMembro">Cancelar</b-button>
                                    </b-col>
                                </div>
                            </template>                  
                        </b-modal>                        

                        <b-row  v-if="consultoria.membros.length > 0">                            
                            <b-table class="mt-2" hover striped :items="consultoria.membros" :fields="fieldsMembros" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="warning" @click="loadMembro(data.item)" class="mr-2">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button variant="danger" @click="desejaRemoverMembro(data.item)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-row>

                        <b-modal id="modal-remove-membro" title="Remover Membro" v-model="modalRemMembro"   >
                            <p class="my-4">Deseja realmente excluir este membro?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeMembro()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-membro')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>
                        </b-modal>  

                    </b-card> 
                    <br>

                    <!-- <b-card header="Tarefas" header-bg-variant="light">  
                        <b-row> 
                            <div>
                                <b-button variant="primary" @click="addTarefa" :disabled="visualizar">
                                    <i class="fa fa-plus"></i> Tarefa
                                </b-button>                            
                            </div>
                        </b-row>
                                              

                        <b-row  v-if="consultoria.tarefas.length > 0">                            
                            <b-table class="mt-2" hover striped :items="consultoria.tarefas" :fields="fieldsTarefas" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="warning" @click="loadTarefa(data.item)" class="mr-2" v-if="!visualizar">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button variant="danger" @click="desejaRemoverTarefa(data.item)" v-if="!visualizar">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-row>

                        <b-modal id="modal-remove-tarefa" title="Remover Tarefa" v-model="modalRemTarefa"   >
                            <p class="my-4">Deseja realmente excluir esta tarefa?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeTarefa()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-tarefa')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>
                        </b-modal>  

                    </b-card> 
                    <br> -->

                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{consultoria.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{consultoria.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{consultoria.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{consultoria.data_alt}}
                            </b-popover>                        
                        </b-col>
                    </b-row>

                </b-form>

            </b-card>

        </b-overlay>  

        <!-- painel -->
        <b-card v-if="showPainel">
            <div class="d-flex justify-content-md-center">
                <b-button-toolbar>
                    <b-button-group class="mx-1">
                        <b-button @click="abaPainel = 'principal'" variant="primary">Painel de Tarefas</b-button>  
                    </b-button-group>                    
                    <b-button-group class="mx-1">
                        <b-dropdown right text="Novo" variant="primary">
                            <b-dropdown-item  @click="addTarefa('save')">Tarefa</b-dropdown-item>
                            <b-dropdown-item  @click="addMembro()">Novo Membro</b-dropdown-item>
                        </b-dropdown>
                    </b-button-group>  
                    <b-button-group class="mx-1" v-if="abaPainel === 'roadmap'" >
                        <b-button @click="salvarPdf('ROADMAP','roadmap-render-pdf')" variant="primary">
                            <i class="fa fa-cloud-download button-icons"></i>PDF
                        </b-button> 
                    </b-button-group> 
                    <b-button-group class="mx-1">
                        
                        <b-dropdown right text="Relatórios" variant="primary">
                            <b-dropdown-item @click="loadRoadmap()">Roadmap de Investimentos</b-dropdown-item>
                            <b-dropdown-item @click="loadDespesas()">Despesas com a consultoria</b-dropdown-item>
                            <b-dropdown-item @click="loadRoadmap()">Projeção de gastos e recebientos</b-dropdown-item>
                            <!-- <b-dropdown-item @click="loadRoadmap()">ROI (Retorno sobre o investimento)</b-dropdown-item>
                            <b-dropdown-item @click="loadRoadmap()">CAC (custo de aquisição por cliente)</b-dropdown-item> -->
                        </b-dropdown>
                    </b-button-group>                  
                    
                </b-button-toolbar>
            </div>
        </b-card>
        
        <b-card class="cadastros-card-corpo" v-if="showPainel  && abaPainel === 'principal'">
            <b-table borderless small sort-icon-left hover :items="consultoria.tarefas" :fields="fieldsTarefasPainel" :tbody-tr-class="rowClass"> 
                
                <template v-slot:cell(nome)="data"> 
                    <b-badge @click="loadTarefa(data.item)" href="#" variant="outline-primary" style="font-size: 0.9rem;">
                        {{data.item.nome}} <i style="font-size: 0.8rem;" class="fa fa-pencil ml-2" aria-hidden="true"></i>
                    </b-badge>
                   
                    <!-- <b-button variant="link" href="#" @click="loadTarefa(data.item)">
                        {{data.item.nome}}
                    </b-button> -->
                </template>
            </b-table>
        </b-card>

        <b-card class="cadastros-card-corpo" v-if="showPainel  && abaPainel === 'despesas'">
            <b-row align-v="center">
                <b-col class="d-flex justify-content-md-center">
                    <b-button-toolbar>                                                               
                        <b-button-group class="mx-1">
                            <b-input-group prepend="Filial">
                                <b-form-select style="width: 200px; margin-right: 10px;" id="filtro-filial" v-model="filtrosDespesas.idFilial" :options="filiais"/>                                
                            </b-input-group>
                            <b-input-group prepend="Tipo">
                                <b-form-select style="width: 135px; margin-right: 10px;" id="filtro-tiporel" v-model="filtrosDespesas.tipoRel" :options="despTiposOptions"/>                                
                            </b-input-group>
                            <b-input-group prepend="Período">
                                <b-form-input type="date" v-model="filtrosDespesas.dataIni"></b-form-input>
                                <b-form-input type="date" v-model="filtrosDespesas.dataFin"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="loadDespesas()" variant="primary"><i class="fa fa-search button-icons"></i></b-button>
                                </b-input-group-append>
                            </b-input-group>

                        </b-button-group> 
                        <b-button-group class="mx-1">
                            <b-button @click="salvarPdf('DESPESAS_DA_CONSULTORIA','despesas-render-pdf')" variant="primary">
                                <i class="fa fa-cloud-download button-icons"></i>
                            </b-button> 
                        </b-button-group> 
                    </b-button-toolbar>
                </b-col>    
            </b-row>
            <hr>

            <div class="d-flex justify-content-md-center">
                <div style="width: 775px; padding: 5px;" id="despesas-render-pdf">                    
                    <b-row style="margin: 0">
                        <b-col md="12" sm="12" class="d-flex justify-content-md-center"> 
                            <h3>{{cabecalhoDespesas.titulo}}</h3>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="12"> 
                            <h5>{{cabecalhoDespesas.filial}}</h5>
                        </b-col>
                        <b-col md="6" sm="12" class="d-flex justify-content-end"> 
                            <h5>{{cabecalhoDespesas.dataIni}} até {{cabecalhoDespesas.dataFin}}</h5>  
                        </b-col>
                    </b-row>
                    <hr>

                    <b-row>
                        <b-col md="12" sm="12"> 
                            <b-table borderless small sort-icon-left hover :items="despesas" :fields="fieldsDespesas">
                            </b-table>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row class="d-flex justify-content-end">
                        <b-col md="6" sm="12" class="d-flex justify-content-end">                             
                            <h5 style="margin: 0 20px 0 0"><strong>Valor total: 
                                {{cabecalhoDespesas.total.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}</strong></h5>  
                        </b-col>
                    </b-row>

                </div>

            </div>            

        </b-card>

        <b-card class="cadastros-card-corpo" v-if="showPainel  && abaPainel === 'roadmap'">
            <div class="div-timeline-road" id="roadmap-render-pdf">
                <b-row>
                    <b-col md="12" sm="12" class="d-flex justify-content-md-center"> 
                        <h2 style="margin: 0px -40px 10px 0"><strong>Roadmap</strong></h2><br>               
                    </b-col>
                    <b-col md="12" sm="12" class="d-flex justify-content-md-center"> 
                        <h4 style="margin: -30px -40px 10px 0"><strong>Investimentos e Retornos</strong></h4>                       
                    </b-col>
                    <b-col md="12" sm="12" class="d-flex justify-content-md-center"> 
                        <p class="par-roadmap">Aqui são listados todos os investimentos e retornos diretamente ou indiretamente gerados por esta consultoria, 
                            de modo que seja possível visualizar a partir de que ponto ela começou a dar lucros. <br>
                            
                            <i id="duvida-1" class="fa fa-question-circle duvida-01" aria-hidden="true"></i>
                            <i id="duvida-2" class="fa fa-question-circle duvida-02" aria-hidden="true"></i>
                            <i id="duvida-3" class="fa fa-question-circle duvida-03" aria-hidden="true"></i>
                            <br>
                            <i class="fa fa-sort-desc duvida-start" aria-hidden="true"></i>
                        </p>

                        <b-popover target="duvida-1" triggers="hover" placement="top">
                            <template #title>Receitas</template>
                            <p>Receitas obtidas através desta consultoria</p>
                        </b-popover>  
                        <b-popover target="duvida-2" triggers="hover" placement="top">
                            <template #title>Despesas Diretas</template>
                            <p>Despesas diretamente ligadas a consultoria</p>
                        </b-popover>  
                        <b-popover target="duvida-3" triggers="hover" placement="top">
                            <template #title>Despesas Indiretas</template>
                            <p>São os custos operacionais da empresa que não estão diretamente ligados a consultoria</p>
                        </b-popover>      
                    </b-col>
                </b-row>
                <b-row v-for="(t, id) in timeline" v-bind:key="id" >
                    <!-- <b-popover show :target="`popover-manual-${t.id}`" :placement="t.id % 2 === 0 ? 'leftbottom' : 'rightbottom'"  triggers="click">
                        <h5>{{t.dados.titulo}}</h5>
                        <p>{{t.dados.texto}}</p> 
                    </b-popover> -->
                    <b-col md="5" sm="5"> 
                        <div v-if="t.id % 2 === 0" class="road-topo-bg-esquerda" :style="`background: #${t.cor}`">
                            <h2 class="funil-texto">                            
                                <p>{{t.titulo}}</p>                                
                            </h2>
                        </div>
                        <div class="pop-esquerda" v-if="t.id % 2 === 0">
                            <ul>
                                <!-- <div>
                                    <h6>{{t.texto}}</h6>
                                </div> -->
                                <li v-for="(f, id) in t.faturas" v-bind:key="id">{{f.plano_contas}} - {{f.valor}}</li>
                                <div>
                                    <hr>
                                    <p><strong>{{t.total}}</strong></p>
                                </div>
                            </ul>
                        </div>
                        
                    </b-col>
                    <b-col md="2" sm="2" class="pop-centro"> 
                        <h5 class="filho-esquerda" v-if="t.id % 2 !== 0">{{t.data}}</h5>
                        <div class="div-centro" :id="`popover-manual-${t.id}`">
                            <b-button href="#" pill size="sm" variant="primary"><i class="fa fa-circle-o " aria-hidden="true"></i></b-button>
                        </div>
                            <div class="div-principal-road"></div>
                        <h5 class="filho-direita" v-if="t.id % 2 === 0">{{t.data}}</h5>
                    </b-col>
                    <b-col md="5" sm="5"> 
                        <div v-if="t.id % 2 !== 0" class="road-topo-bg-direita" :style="`background: #${t.cor}`">
                            <h2 class="funil-texto">                            
                                <p>{{t.titulo}}</p>                                
                            </h2>
                        </div>
                        <div class="pop-direita" v-if="t.id % 2 !== 0">
                            <ul>
                                <!-- <div>
                                    <h6>{{t.texto}}</h6>
                                </div> -->
                                <li v-for="(f, id) in t.faturas" v-bind:key="id">{{f.plano_contas}} - {{f.valor}}</li>
                                <div>
                                    <hr>
                                    <p><strong>{{t.total}}</strong></p>
                                </div>
                            </ul>
                        </div>

                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12" sm="12"> 
                        <h4>...</h4>
                    </b-col>
                </b-row>
            </div>           
        </b-card>
        
        <b-card v-show="!show && !showPainel" style="margin-top: 10px">
            <div class="acoesRodape">                 
                <b-button variant="danger" class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                    @click="reset">
                        <i class="fa fa-times button-icons-acoes"></i>
                </b-button>           
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                    @click="save">
                        <i class="fa fa-check button-icons-acoes"></i>
                </b-button> 
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-if="show && !showPainel">
            <b-table hover striped :items="consultorias" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="primary" @click="loadConsultoria(data.item, false)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button variant="success" @click="exibirPainel(data.item)" class="mr-2">
                        <i class="fa fa-line-chart"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>

        <b-modal id="modal-exclui-consult" title="Excluir Consultoria" v-model="modalExcluiContult"   >
            <p class="my-4">Deseja realmente excluir esta consultoria?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-exclui-consult')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal>          

        <b-modal size="lg" id="modal-imprime-consult" title="Imprimir resumo Consultoria" v-model="modalImprimeConsultoria">
            <b-row>
                <b-col md="12" sm="12">
                    <b-input-group                    
                        class="mb-3"
                        prepend="Modelo"
                    >
                        <b-form-select id="modelo-impresso" v-model="idmodelo" :options="modelos" />
                        <b-input-group-append>
                            <b-button variant="info" @click="editaModelo = !editaModelo">
                                <i v-if="!editaModelo" class="fa fa-pencil button-icons"></i>
                                <i v-if="editaModelo" class="fa fa-floppy-o button-icons"></i> 
                                {{editaModelo ? 'Salvar' : 'Editar'}}
                            </b-button>
                            <b-button :disabled="editaModelo" variant="info" @click="salvarPdf()">
                                <i class="fa fa-print button-icons"></i> Imprimir
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>

            </b-row>
            <hr>
            
            <div v-if="!editaModelo" id="renderPDF" v-html="impresso" class="ql-editor"></div>
            
            <div>

                <VueEditor v-if="editaModelo" id="editor-este"
                    v-model="impresso" 
                    
                />
            </div>
            
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>

        <b-modal title="Tarefa" v-model="modalAddTarefa"            
            hideBvModalEvent                                   
            id="modal-tipos" 
            centered size="lg" 
            ok-only
            ok-disabled
        >  
            <b-row v-if="modeTarefa === 'save'">
                <b-col md="8" sm="12">
                    <b-row>
                        <b-col md="8" sm="12">
                            <b-form-group label="Nome da Tarefa" label-for="consultoria-nome-tarefa"> 
                                <b-form-input id="consultoria-nome-tarefa" type="text"
                                    :formatter="upperCase"
                                    v-model="tarefa.nome" required
                                    :readonly="mode === 'remove'"/>
                            </b-form-group> 
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-group label="Prazo Final" label-for="prazo-docto-consultoria">
                                <b-form-input id="prazo-docto-consultoria" type="date"
                                    v-model="tarefa.prazo"/>
                            </b-form-group>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col md="12" sm="12">
                            <b-form-group label="Descrição" label-for="consultoria-descricao-tarefa"> 
                                <b-form-textarea
                                    id="consultoria-descricao-tarefa"
                                    v-model="tarefa.descricao"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col md="4" sm="12">
                    <b-form-group label="Responsável" label-for="resp-name-input" >
                        <b-form-select id="resp-name-input" v-model="tarefa.id_usuario" :options="responsaveisTarefa" :select-size="9"/>
                    </b-form-group>  
                </b-col>

            </b-row>

            <div v-if="modeTarefa !== 'save'">
                <b-row>
                    <b-col md="8" sm="12">
                        <b-form-group label="Nome da Tarefa" label-for="consultoria-nome-tarefa"> 
                            <h5>{{tarefa.nome}}</h5>
                        </b-form-group> 
                    </b-col>

                    <b-col md="4" sm="12">
                        <b-form-group label="Prazo Final" label-for="prazo-docto-consultoria">
                            <h5>{{tarefa.prazo}}</h5>
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row>
                    <b-col md="12" sm="12">
                        <b-form-group label="Descrição" label-for="consultoria-descricao-tarefa"> 
                            <b-form-textarea
                                id="consultoria-descricao-tarefa"
                                v-model="tarefa.descricao"
                                rows="3"
                                max-rows="6"
                                readonly
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6" sm="12">
                        <b-form-group label-for="tarefa-status-op">
                            Alterar status
                            <b-form-select size="lg" id="tarefa-status-op" v-model="tarefa.status" :options="optionsStatusTarefa" />
                        </b-form-group> 
                    </b-col>
                </b-row>
            </div>

            <template #modal-footer>
                <div class="w-100"> 
                    <b-col md="10" sm="10">

                        <b-button variant="primary" 
                            @click="salvarTarefa">Salvar
                        </b-button>                       

                        <b-button class="ml-2" @click="resetTarefa">
                            Cancelar                            
                        </b-button>

                        <b-button class="ml-2" variant="link" v-if="modeTarefa === 'new'"
                            @click="modeTarefa = 'save'">Editar
                        </b-button>

                    </b-col>
                </div>
            </template>                  
        </b-modal>  
        
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global'
import VueSingleSelect from "vue-single-select";
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"
let extenso = require('extenso')
import { VueEditor } from "vue2-editor"

export default {
    name: 'CadastroOrcamentos',
    components: { VueSingleSelect, VueEditor },
    data: function() {
        return {
            timeline: [
                {   
                    id: 1,
                    data: "01/05/1972", 
                    titulo: 'Item 001 da Timeline',
                    texto: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi aliquet tell',
                    faturas: [
                        {id: 1, plano_contas: 'ALUGUEL', valor: '1.200,00'},
                        {id: 1, plano_contas: 'ÁGUA', valor: '1.200,00'},
                        {id: 1, plano_contas: 'LUZ', valor: '1.200,00'},
                    ],
                    total: '3.600,00',
                    cor: '3485ff'
                },
                {
                    id: 2,
                    data: "07/08/1985", 
                    titulo: 'Item 002 da Timeline',
                    texto: ' elementum urna volutpat, acc. In tempus non diam condimentum dapibus. Curabitur sodales enim et urna',
                    faturas: [
                        {id: 1, doc: 4578, plano_contas: 'ALUGUEL', valor: '1.200,00'},
                        {id: 2, doc: 587, plano_contas: 'ÁGUA', valor: '1.200,00'},
                        {id: 3, doc: 112247, plano_contas: 'LUZ', valor: '1.200,00'},
                    ],
                    total: '3.600,00',
                    cor: '3485ff'
                },
                {
                    id: 3,
                    data: "29/12/2024", 
                    titulo: 'Item 002 da Timeline',
                    texto: 'Ut at ex sollicitudin, elementum urna volutpat, accumsan lectus. Curabitur sodales enim et urna',
                    faturas: [
                        {id: 1, doc: 4578, plano_contas: 'ALUGUEL', valor: '1.200,00'},
                        {id: 2, doc: 587, plano_contas: 'ÁGUA', valor: '1.200,00'},
                        {id: 3, doc: 112247, plano_contas: 'LUZ', valor: '1.200,00'},
                    ],
                    total: '3.600,00',
                    cor: '385ff'
                },
            ],
            abaPainel: 'principal',
            visualizar: false,
            modalAddTarefa: false,
            modalRemTarefa: false,

            filtrosDespesas: {
                idConsultoria: 0,
                idFilial: 0,
                dataIni: moment().add(-2, 'months').format('yyyy-MM-DD'),
                dataFin: moment().format('yyyy-MM-DD'),
                tipoRel: 'CP'
            },
            cabecalhoDespesas: {
                filial: '',
                dataIni: '',
                dataFin: '',
                titulo: '',
                total: 0
            },

            despesas: [],
            filiais: [],
            contratos: [],
            contratoSelecionado: '',
            pessoaSelecionada: '',
           
            modalAddMembro: false,
            modalRemMembro: false,

            modalFatOrc: false,
            editaModelo: false,
            modalExcluiContult: false,
            modalImprimeConsultoria: false,
            modelos: [],
            modelo: '',
            nomeModelo: 'consultoria',
            idmodelo: 0,
            idOrca: 0,
            impresso: '',
            legBotaoGravar: '',

            modeAddTarefa: 'save',
            modeTarefa: 'new',

            modeAddMembro: 'save',
            modeMembro: 'new',

            index: 0,
            pessoas: [],           
            tarefa: {
                id_consultoria_tarefa: '',
                nome: '',
                descricao: '',
                prazo: moment().add(7, 'days').format('yyyy-MM-DD'),
                status: 'P'
            },
            tarefas: [],
            responsaveisTarefa: [],
            membro: {
                id_consultoria_membro: '',
                id_usuario: ''
            },    
            membros: [],
            consultoria: {
                id_consultoria: '',
                data_cad: moment().format('yyyy-MM-DD'),
                prazo: moment().add(6, 'months').format('yyyy-MM-DD'),
                id_pessoa: '',
                tarefas: [],
                membros: []
            },
            consultorias: [],
            
            mode: 'new',            
            page: 1,
            count: 0,
            limit: 0,                     
            parcial: '',
            dataInicial: moment('1900-01-01').format('yyyy-MM-DD'),
            dataFinal: moment('2400-12-31').format('yyyy-MM-DD'),
            status: 'T',
            ativos: 'S',
            show: true,
            showOver: false,   
            showPainel: false,         
            fields: [
                {key: 'id_consultoria', label: 'Cód', sortable: true},
                {key: 'nome', label: 'Nome da Consultoria', sortable: true},
                {key: 'razao_social', label: 'Cliente', sortable: true},
                {key: 'data_cad', label: 'Data', sortable: true, 
                    formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                },                
                {key: 'prazo', label: 'Prazo final', sortable: true, 
                    formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                },                
                {key: 'status', label: 'Status', sortable: true, 
                    formatter: value => { 
                        switch (value) {
                        case "A":
                            return 'Em andamento';
                        case "S":
                            return 'Suspensa';
                        case "C":
                            return 'Cancelada';                        
                        }
                    }
                },         
                {key: 'actions', label: ''}
            ], 
            fieldsMembros: [
                {key: 'id_usuario', label: 'Código', sortable: false},
                {key: 'nome', label: 'Membro', sortable: false},                
                {key: 'actions', label: ''}
            ], 
            fieldsTarefas: [
                {key: 'nome', label: 'Nome', sortable: false},
                {key: 'nome_responsavel', label: 'Responsavel', sortable: false},
                {key: 'prazo', label: 'Prazo', sortable: true, 
                    formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                },               
                {key: 'actions', label: ''}
            ], 
            fieldsDespesas: [],
            
            fieldsTarefasPainel: [
                {key: 'nome', label: 'Nome', sortable: true},
                {key: 'status', label: '', sortable: true, 
                    formatter: value => {
                        switch (value) {
                            case 'A':
                                return 'Em andamento';
                            case 'P':
                                return 'Pendente';
                            case 'C':
                                return 'Concluída';                        
                        }
                    }
                },
                {key: 'data_alt', label: 'Atividade', sortable: true, 
                    formatter: value => { 
                        moment.locale('pt-br')
                        return moment(value).format('lll');
                    }
                },               
                {key: 'prazo', label: 'Prazo final', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },               
                {key: 'nome_criador', label: 'Criado por', sortable: true},
                {key: 'nome_responsavel', label: 'Responsável', sortable: true}, 
            ], 
            optionsStatusTarefa: [
                { value: 'P', text: 'Pendente' },
                { value: 'A', text: 'Em andamento'},
                { value: 'C', text: 'Concluída'}
            ], 
            despTiposOptions: [
                { value: 'CP', text: 'Competência' },
                { value: 'VE', text: 'Vencimento'},
                { value: 'CX', text: 'Recebimento'},
                { value: 'RE', text: 'Resumo'}
            ], 
        }
    },

    methods: {
        label(option){
            return option.text
        },
        value(option){
            return option.value
        },
        salvarPdf(nome, id){
            var element = document.getElementById(id);
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.1,0.1,0.1,0.1],
                filename: `${nome}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },

        loadRoadmap() {

            axios.get(`${baseApiUrl}/consultorias/roadmap/${this.consultoria.id_consultoria}`)
            .then(res => {
                this.timeline = res.data
                this.abaPainel = 'roadmap'
            }) 
            .catch(err => {
                showError(err)
            })
        },

        loadDespesas() {

            axios.get(`${baseApiUrl}/consultorias/despesas`, {
                params: {...this.filtrosDespesas}
            })
            .then(async res => {
                await this.loadFiliais()
                this.despesas = [ ...res.data ]

                this.fieldsDespesas = []
                const t = this.filtrosDespesas.tipoRel 

                const f = this.filiais.find(obj => {
                    return obj.value === parseInt(this.filtrosDespesas.idFilial);
                });
                this.cabecalhoDespesas.filial = f.text             
                
                this.cabecalhoDespesas.dataIni = moment(this.filtrosDespesas.dataIni).format('DD/MM/YYYY')
                this.cabecalhoDespesas.dataFin = moment(this.filtrosDespesas.dataFin).format('DD/MM/YYYY')

                this.cabecalhoDespesas.total = 0
                this.despesas.forEach(i=>{
                    this.cabecalhoDespesas.total = this.cabecalhoDespesas.total + i.valor
                })

                if (t === 'CP') {     
                    this.cabecalhoDespesas.titulo = 'Despesas da Consultoria (Competência)'

                    this.fieldsDespesas.push({key: 'data_competencia', label: 'Competência', sortable: true, 
                        formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                    })
                }
                if (t === 'VE') {   
                    this.cabecalhoDespesas.titulo = 'Despesas da Consultoria (Vencimento)'

                    this.fieldsDespesas.push({key: 'vencimento', label: 'Vencimento', sortable: true, 
                        formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                    })
                }                
                if (t === 'CX') {    
                    this.cabecalhoDespesas.titulo = 'Despesas da Consultoria (Recebimento)'

                    this.fieldsDespesas.push({key: 'data_recebimento', label: 'Recebimento', sortable: true, 
                        formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                    })
                }                
                if (t !== 'RE') { 
                    this.cabecalhoDespesas.titulo = 'Resumo de despesas da Consultoria (Competência)'

                    this.fieldsDespesas.push({key: 'num_docto', label: 'Docto', sortable: true})
                    this.fieldsDespesas.push({key: 'razao_social', label: 'Nome', sortable: true})
                }
                this.fieldsDespesas.push({key: 'desc_plano_contas', label: 'Plano de Contas', sortable: true})
                this.fieldsDespesas.push({key: 'valor', label: 'Valor', sortable: true, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})  
                    }
                })

                this.abaPainel = 'despesas'
            }) 
            .catch(err => {
                showError(err)
                this.despesas = []
            })
        },

        async loadFiliais() {
            const url = `${baseApiUrl}/filiais`
            await axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social}`}
                })
                this.filiais.unshift({value: 0, text: 'TODAS' })
            })
            .catch(err => {
                showError(err)
            }) 
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'A') return 'table-warning'
            if (item.status === 'C') return 'table-success'
        },

        exibirPainel(c){
            this.loadConsultoria(c)
            this.showPainel = true
        },

        alerarStatus(o, sts) {            
            axios['post'](`${baseApiUrl}/orcamentosstatus/${o.id_orcamento}`, {status: sts})
            .then(() => {
                o.status = sts
                showSuccess('Status alterado com secesso.')
            })
            .catch(err => {
                showError(err)
            })  
        },   

        loadModelos(c) { 
            this.idOrca  = c.id_orcamento

            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&tipo=cadmodelo&tela=consultoria` 

            axios.get(url).then(res => {
                this.modalImprimeConsultoria = true   
                this.modelos = res.data.data.map(modelo => {
                    return { ...modelo, value: modelo.id_modelo_relatorio, text: `${modelo.id_modelo_relatorio} | ${modelo.descricao}` }
                })
            }) 
            .catch(showError)           
        }, 

        selectModelo() {
            if (this.idmodelo > 0) {
                
                const url = `${baseApiUrl}/modelos/relatorios/${this.idmodelo}`    
    
                axios.get(url).then(res => {
                    this.modelo = res.data.corpo_modelo
                    this.nomeModelo = res.data.descricao
                    this.imprimeOrcamento(this.idOrca)
                }) 
                .catch(err => {
                    showError(err)
                })
            }
        },

        imprimeOrcamento(id) {

            const url = `${baseApiUrl}/orcamentos/${id}`    

            moment.locale('pt')

            axios.get(url).then(res => {  

                try {
                    const sVT = res.data.servicos_total ? res.data.servicos_total : 0
                    const pVT = res.data.produtos_total ? res.data.produtos_total : 0
                    const sVTL = res.data.servicos_total_liquido ? res.data.servicos_total_liquido : 0
                    const pVTL = res.data.produtos_total_liquido ? res.data.produtos_total_liquido : 0

                    const valorTotal = sVT + pVT
                    const valorTotalLiq = sVTL + pVTL

                    this.nomeModelo = `${this.nomeModelo} (${res.data.razao_social})`
                    this.impresso =  this.modelo.replace(/@nome-cliente@/g, res.data.razao_social);
                    this.impresso = this.impresso.replace(/@id-pessoa@/g, res.data.id_pessoa);
                    this.impresso = this.impresso.replace(/@nome-vendedor@/g, res.data.razao_social_vendedor);
                    this.impresso = this.impresso.replace(/@id-vendedor@/g, res.data.id_vendedor);
                    this.impresso = this.impresso.replace(/@tipo-pessoa@/g, res.data.tipo_pessoa === 'F' ? 'FÍSICA' : 'JURÍDICA');
                    this.impresso = this.impresso.replace(/@cpf@/g, res.data.cpf === null ? '' : res.data.cpf);
                    this.impresso = this.impresso.replace(/@cnpj@/g, res.data.cnpj === null ? '' : res.data.cnpj);
                    this.impresso = this.impresso.replace(/@rg@/g, res.data.rg === null ? '' : res.data.rg); 
                    this.impresso = this.impresso.replace(/@ie@/g, res.data.ie === null ? '' : res.data.ie); 
                    this.impresso = this.impresso.replace(/@endereco@/g, res.data.endereco  === null ? '' : res.data.endereco); 
                    this.impresso = this.impresso.replace(/@numero@/g, res.data.numero === null ? 'S/N' : res.data.numero); 
                    this.impresso = this.impresso.replace(/@bairro@/g, res.data.bairro === null ? '' : res.data.bairro); 
                    this.impresso = this.impresso.replace(/@complemento@/g, res.data.complemento === null ? '' : res.data.complemento);
                    this.impresso = this.impresso.replace(/@uf@/g, res.data.uf === null ? '' : res.data.uf);
                    this.impresso = this.impresso.replace(/@nome-cidade@/g, res.data.nome_cidade === null ? '' : res.data.nome_cidade); 
                    this.impresso = this.impresso.replace(/@cep@/g, res.data.cep === null ? '' : res.data.cep); 
                    this.impresso = this.impresso.replace(/@telefone@/g, res.data.telefone === null ? '' : res.data.telefone);
                    this.impresso = this.impresso.replace(/@celular@/g, res.data.celular === null ? '' : res.data.celular);

                    this.impresso = this.impresso.replace(/@id-forma-recebimento@/g, res.data.celular === null ? '' : res.data.id_forma_pagar_receber);
                    this.impresso = this.impresso.replace(/@desc-forma-recebimento@/g, res.data.celular === null ? '' : res.data.desc_forma_recebimento);
                    this.impresso = this.impresso.replace(/@id-cond-recebimento@/g, res.data.celular === null ? '' : res.data.id_condicao_pagar_receber);
                    this.impresso = this.impresso.replace(/@desc-cond-recebimento@/g, res.data.celular === null ? '' : res.data.desc_forma_recebimento);
                    
                    this.impresso = this.impresso.replace(/@produtos-desconto@/g, res.data.produtos_desconto ? res.data.produtos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-desconto-extenso@/g, res.data.produtos_desconto ? extenso(res.data.produtos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-acrescimo@/g, res.data.produtos_acrescimo ? res.data.produtos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-acrescimo-extenso@/g, res.data.produtos_acrescimo ? extenso(res.data.produtos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-total@/g, res.data.produtos_total ? res.data.produtos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-total-extenso@/g, res.data.produtos_total ? extenso(res.data.produtos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');              
                    this.impresso = this.impresso.replace(/@produtos-total-liquido@/g, res.data.produtos_total_liquido ? res.data.produtos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-total-liquido-extenso@/g, res.data.produtos_total_liquido ? extenso(res.data.produtos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    
                    this.impresso = this.impresso.replace(/@servicos-desconto@/g, res.data.servicos_desconto ? res.data.servicos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-desconto-extenso@/g, res.data.servicos_desconto ? extenso(res.data.servicos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-acrescimo@/g, res.data.servicos_acrescimo ? res.data.servicos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-acrescimo-extenso@/g, res.data.servicos_acrescimo ? extenso(res.data.servicos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-total@/g, res.data.servicos_total ? res.data.servicos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-total-extenso@/g, res.data.servicos_total ? extenso(res.data.servicos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');              
                    this.impresso = this.impresso.replace(/@servicos-total-liquido@/g, res.data.servicos_total_liquido ? res.data.servicos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-total-liquido-extenso@/g, res.data.servicos_total_liquido ? extenso(res.data.servicos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
              
                    this.impresso = this.impresso.replace(/@num-orcamento@/g, res.data.num_orcamento);
                    this.impresso = this.impresso.replace(/@valor-total@/g, valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-total-extenso@/g, extenso(valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }));

                    this.impresso = this.impresso.replace(/@valor-total-liquido@/g, valorTotalLiq.toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-total-liquido-extenso@/g, extenso(valorTotalLiq.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }));

                    this.impresso = this.impresso.replace(/@data@/g, moment(res.data.data).format('DD/MM/YYYY'));
                    this.impresso = this.impresso.replace(/@data-extenso@/g, moment(res.data.data).format('LL') );
                    this.impresso = this.impresso.replace(/@qtde-parcelas@/g, res.data.qtde_parcelas);
                    this.impresso = this.impresso.replace(/@qtde-parcelas-extenso@/g, extenso(res.data.qtd_parcelas, { number: { gender: 'm' } }));
                    this.impresso = this.impresso.replace(/@valor-parcela@/g, (valorTotalLiq/ res.data.qtd_parcelas).toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-parcela-extenso@/g, extenso((valorTotalLiq / res.data.qtd_parcelas), { mode: 'currency' }));
                    this.impresso = this.impresso.replace(/@desc-ate-vencto@/g, res.data.desc_ate_vencto);
                    this.impresso = this.impresso.replace(/@obs@/g, res.data.obs === null ? '' : res.data.obs);
                    this.impresso = this.impresso.replace(/@status@/g, res.data.status === null ? '' : res.data.status);
                   
                    this.impresso = this.impresso.replace(/@razao-social-filial@/g, res.data.razao_social_filial); 
                    this.impresso = this.impresso.replace(/@tipo-pessoa-filial@/g, res.data.tipo_pessoa_filial === 'F' ? 'FÍSICA' : 'JURÍDICA');
                    this.impresso = this.impresso.replace(/@fantasia-filial@/g, res.data.fantasia_filial === null ? '' : res.data.fantasia_filial); 
                    this.impresso = this.impresso.replace(/@cnpj-cpf-filial@/g, res.data.cnpj_cpf_filial); 
                    this.impresso = this.impresso.replace(/@ie-rg-filial@/g, res.data.ie_rg_filial === null ? '' : res.data.ie_rg_filial); 
                    this.impresso = this.impresso.replace(/@im-filial@/g, res.data.im_filial === null ? '' : res.data.im_filial); 
                    this.impresso = this.impresso.replace(/@telefone-filial@/g, res.data.telefone_filial === null ? '' : res.data.telefone_filial); 
                    this.impresso = this.impresso.replace(/@fax-filial@/g, res.data.fax_filial === null ? '' : res.data.fax_filial); 
                    this.impresso = this.impresso.replace(/@celular-filial@/g, res.data.celular_filial === null ? '' : res.data.celular_filial);                   
                    this.impresso = this.impresso.replace(/@email-filial@/g, res.data.email_filial === null ? '' : res.data.email_filial); 
                    this.impresso = this.impresso.replace(/@endereco-filial@/g, res.data.endereco_filial === null ? '' : res.data.endereco_filial); 
                    this.impresso = this.impresso.replace(/@numero-filial@/g, res.data.numero_filial === null ? 'S/N' : res.data.numero_filial); 
                    this.impresso = this.impresso.replace(/@bairro-filial@/g, res.data.bairro_filial === null ? '' : res.data.bairro_filial); 
                    this.impresso = this.impresso.replace(/@nome-cidade-filial@/g, res.data.nome_cidade_filial === null ? '' : res.data.nome_cidade_filial);
                    this.impresso = this.impresso.replace(/@uf-filial@/g, res.data.uf_filial === null ? '' : res.data.uf_filial);
                    this.impresso = this.impresso.replace(/@complemento-filial@/g, res.data.complemento_filial === null ? '' : res.data.complemento_filial); 
                    this.impresso = this.impresso.replace(/@cep-filial@/g, res.data.cep_filial === null ? '' : res.data.cep_filial);
                    this.impresso = this.impresso.replace(/@data-hoje@/g, moment().format('DD/MM/YYYY') );
                    this.impresso = this.impresso.replace(/@data-hoje-extenso@/g, moment().format('LL') ); 
                    
                } catch (error) {
                    throw error
                }                
              
            }) 
            .catch(err => {
                showError(err)
            })
        },

        relatorios() {

        },

        novaConsultoria() {
            this.loadUsuarios()
            this.loadPessoas()
            this.contratoSelecionado = ''
            this.pessoaSelecionada = ''

            this.mode = 'save' 
            this.show = false         
        },

        addTarefa(mode) {
            this.resetTarefa()
            this.tarefa.id_consultoria = this.consultoria.id_consultoria
            this.loadResponsaveisTarefa()
            this.modalAddTarefa = true            
            this.modeTarefa = mode
        },

        addMembro() {
            this.resetMembro()
            this.modalAddMembro = true            
            this.modeMembro = 'new'            
            this.loadUsuarios()
        },

        resetTarefa() { 
            this.enumeraTarefas() 
            
            this.tarefa = {}
            this.tarefa = {
                id_consultoria_tarefa: '',
                id_usuario: '',
                nome: '',
                descricao: '',
                prazo: moment().add(7, 'days').format('yyyy-MM-DD'),
                status: 'P'
            } 

            this.modalAddTarefa = false 
            this.modeTarefa = 'new'
        },

        resetMembro() { 
            this.enumeraMembros() 
            
            this.membro = {}
            this.membro = {
                id_consultoria_membro: '',
                id_usuario: ''
            } 

            this.modalAddMembro = false 
        },

        salvarTarefa() {
            if (this.tarefa.nome.trim().length < 1) {
                showError('Informe o nome da tarefa.')
                return
            }
            if (this.tarefa.id_usuario < 1 || !this.tarefa.id_usuario) {
                showError('Informe o responsável por esta tarefa.')
                return
            } 

            // const m = this.responsaveisTarefa.find(obj => {
            //     return obj.id_usuario === this.tarefa.id_usuario;
            // });
            // this.tarefa.nome_responsavel = m.nome

            // this.modeTarefa === 'edit' ? this.consultoria.tarefas.splice(this.index, 1, {...this.tarefa}) : this.consultoria.tarefas.push({...this.tarefa})  
            
            // if (this.tarefa.tarefas.filter(e => e.nome_responsavel === '(sem responsável)').length > 0) {
            //     showError('Existe ao menos uma tarefa sem responsável')
            //     return
            // }
            
            const method = this.tarefa.id_consultoria_tarefa ? 'put' : 'post'
            const idC = this.tarefa.id_consultoria_tarefa ? `/${this.tarefa.id_consultoria_tarefa}` : ''

            axios[method](`${baseApiUrl}/consultorias/tarefas${idC}`, this.tarefa)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.resetTarefa()
                this.loadConsultoria(this.consultoria)
            })
            .catch(err => {
                showError(err)
            })   

            this.resetTarefa()
                
        },

        salvarMembro() {
            if (this.membro.id_usuario < 1) {
                showError('Selecione o membro antes de gravar.')
                return
            }

            const m = this.membros.find(obj => {
                return obj.idusuario === this.membro.id_usuario;
            });
            this.membro.nome = m.nome

            this.modeMembro === 'edit' ? this.consultoria.membros.splice(this.index, 1, {...this.membro}) : this.consultoria.membros.push({...this.membro})  
            this.resetMembro()   
            this.loadResponsaveisTarefa()             
        },

        loadTarefa(t) {  
            this.modeTarefa = 'edit'
            this.tarefa = { ...t }                     
            this.index = t.seq - 1
                      
            this.modalAddTarefa = true
            this.modeTarefa = 'new'
        },

        loadMembro(m) {  
            this.modeMembro = 'edit'
            this.membro = { ...m }                     
            this.index = m.seq - 1
            this.loadUsuarios()        
            this.modalAddMembro = true
        },

        enumeraTarefas(){ 
            this.consultoria.tarefas.forEach((_, i) =>{                
                this.consultoria.tarefas[i].seq = i+1
                this.consultoria.tarefas[i].prazo = moment(this.consultoria.tarefas[i].prazo).format('yyyy-MM-DD')
            })
        },

        enumeraMembros(){ 
            this.consultoria.membros.forEach((_, i) =>{                
                this.consultoria.membros[i].seq = i+1
            })
        },

        desejaRemoverTarefa(s){
            this.index = Number(s.seq) - 1
            this.modalRemTarefa = true
        },

        desejaRemoverMembro(s){
            this.index = Number(s.seq) - 1
            this.modalRemMembro = true
        },

        removeTarefa() {            
            this.consultoria.tarefas.splice(Number(this.index), 1)
            this.modalRemTarefa = false
            this.resetTarefa()
        },

        removeMembro() {            
            this.consultoria.membros.splice(Number(this.index), 1)
            this.modalRemMembro = false
            this.resetMembro()
            this.loadResponsaveisTarefa()
        },
     
        upperCase(value) {
            return value.toUpperCase()
        },

        lowerCase(value) {
            return value.toLowerCase()
        },

        novaConsulta() {
            this.page = 1 
            this.loadConsultorias()
        },

        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data.map(pessoa => {
                    return { ...pessoa, value: pessoa.idpessoa, text: `| ${pessoa.razao_social}` }
                })
            })
        },
        
          
        loadConsultorias() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&parcial=${this.parcial}&tipo=consultoria&dataInicio=${this.dataInicial}&dataFim=${this.dataFinal}` 
            axios.get(url).then(res => { 
                this.consultorias = res.data.data                
                this.count = res.data.count 
                this.limit = res.data.limit              
            }) 
            .catch(err => {
                showError(err)
                this.consultorias = []
            })         
        },

        loadConsultoria(consultoria) {
            const url = `${baseApiUrl}/consultorias/${consultoria.id_consultoria}`    

            axios.get(url).then(res => {
                this.show = false
                this.mode = 'save'

                this.consultoria = { ...res.data }
                this.membros = [ ...res.data.membros ]
                this.consultoria.data_cad = moment(this.consultoria.data_cad).format('yyyy-MM-DD') 
                this.consultoria.prazo = moment(this.consultoria.prazo).format('yyyy-MM-DD') 
                if (this.consultoria.id_pessoa) this.pessoaSelecionada = {value: this.consultoria.id_pessoa, text: `| ${this.consultoria.razao_social}`}
                if (this.consultoria.id_contrato) this.contratoSelecionado = {
                    value: this.consultoria.id_contrato, 
                    text: `${this.consultoria.e_aditivo === 'S' ? 'ADITIVO' : 'CONTRATO' } ${this.consultoria.num_contrato}-${this.consultoria.sequencia} (${moment(this.consultoria.data).format('DD/MM/yyyy')})`
                }

                this.filtrosDespesas.idConsultoria = this.consultoria.id_consultoria

                this.loadPessoas()
                this.enumeraTarefas()
                this.enumeraMembros()
                this.loadResponsaveisTarefa()
                this.loadContratos() 

            }) 
            .catch(err => {
                showError(err)
            })
        },

        loadUsuarios() {
            axios.get(`${baseApiUrl}/usuarios`)
            .then(res => {
                this.membros = res.data.map(usuario => {
                    return { ...usuario, value: usuario.idusuario, text: `${usuario.idusuario} | ${usuario.nome}` }
                })
            })
            .catch(err => {
                showError(err)
            })           
        }, 
        
        loadResponsaveisTarefa() {
            this.responsaveisTarefa = this.consultoria.membros.map(m => {                
                return { ...m, value: m.id_usuario, text: `${m.id_usuario} | ${m.nome}` }
            })            
        },

        opcoesConsultoria() {

        },

        reset() {
            this.mode = 'new'
            this.show = true
            
            this.consultoria = {
                id_consultoria: '',
                data_cad: moment().format('yyyy-MM-DD'),
                prazo: moment().add(6, 'months').format('yyyy-MM-DD'),
                id_pessoa: '',
                tarefas: [],
                membros: []
            }

            this.contratoSelecionado = ''
            this.contratos = []
            this.pessoaSelecionada = ''
            this.pessoas = []
        },

        save() {
            const method = this.consultoria.id_consultoria ? 'put' : 'post'
            const idC = this.consultoria.id_consultoria ? `/${this.consultoria.id_consultoria}` : ''

            if (this.consultoria.tarefas.filter(e => e.nome_responsavel === '(sem responsável)').length > 0) {
                showError('Existe ao menos uma tarefa sem responsável')
                return
            }
            
            axios[method](`${baseApiUrl}/consultorias${idC}`, this.consultoria)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
                this.loadConsultorias()
            })
            .catch(err => {
                showError(err)
            })           
        },
        
        desejaExcluirConsultoria(o) {
            this.modalExcluiContult = true
            this.orcamento.id_consultoria = o.id_consultoria
        },

        remove() {
            axios.delete(`${baseApiUrl}/consultorias/${this.consultoria.id_consultoria}`)
                .then(() => {
                    showSuccess('Planejamento de consultoria excluído com sucesso.')
                    this.consultoria.id_consultoria = ''
                    this.modalExcluiContult = false
                    this.loadConsultorias()
                })
            .catch(err => {
                showError(err)
                this.modalExcluiContult = false
            }) 
        }, 
        
        loadContratos() {
            const url = `${baseApiUrl}/contratos/pessoa/${this.consultoria.id_pessoa}`
            axios.get(url).then(res => {
                this.contratos = res.data.map(contrato => {
                    return { ...contrato, 
                        value: contrato.id_contrato, 
                        text: `${contrato.e_aditivo === 'S' ? 'ADITIVO' : 'CONTRATO' } ${contrato.num_contrato}-${contrato.sequencia} (${moment(contrato.data).format('DD/MM/yyyy')})`
                    }
                })
            })
        }, 
    
    },
    watch: {
        page() {
            this.loadConsultorias()
        },
        pessoaSelecionada() {
            if (this.pessoaSelecionada) {
                this.consultoria.id_pessoa = this.pessoaSelecionada.value
                this.loadContratos()
            } else {
                this.consultoria.id_pessoa = ''
                this.consultoria.id_contrato = ''
                this.contratoSelecionado = ''
                this.contratos = []
            }
        },
        contratoSelecionado() {
            if (this.contratoSelecionado) {
                this.consultoria.id_contrato = this.contratoSelecionado.value
            } else {
                this.consultoria.id_contrato = ''
            }
        },
        idmodelo() {
            this.selectModelo()
        },
        modalImprimeConsultoria() {
            this.impresso = ''
            this.idmodelo = 0
            this.nomeModelo = 'consultoria'
            this.editaModelo = false
        },
        'filtrosDespesas.tipoRel': function() {
            this.loadDespesas()
        },
        'filtrosDespesas.idFilial': function() {
            this.loadDespesas()
        }
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .opcoes-faturar {
        display: flex;
        align-items: center;
        width: 200px;
        padding: 25px;
        background-color: beige;
    }

    .opcoes-faturar:hover {
        background-color: rgb(197, 197, 197);
    }

    .opcoes-faturar-f {
        width: 30%;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }





    /* roadmap */

    .pop-direita  {
        /* background-color: #3485ff; */
        position: relative;
        margin-top: 30px;
        border-right: 1px dotted #3485ff;
        font-weight: 600;
        text-align: right;
        float: left;
        width: 330px;
        margin-left: -330px;
        
    }
    .pop-direita ul {
        margin: 30px 10px 0 0;
        list-style: none;
        float: right;
        
    }
    .pop-direita hr {
        margin-bottom: 5px; 
        font-size: 1.5rem;       
    }
    .pop-direita p {
        font-size: 1.4rem;       
    }
    .pop-esquerda hr {
        margin-bottom: 5px; 
        font-size: 1.5rem;       
    }
    .pop-esquerda p {
        font-size: 1.4rem;       
    }

    .pop-esquerda  {
        /* background-color: #3485ff; */
        position: relative;
        margin-top: 30px;
        border-left: 1px dotted #3485ff;
        font-weight: 600;
        text-align: left;
        float: right !important;
        width: 330px;
        margin-right: -330px;
        
    }
    .pop-esquerda ul {
        margin: 30px 0px 0 10px;
        list-style: none;
        float: left;
        
    }
    .pop-esquerda h6 {
        margin: -20px 0 10px 0;
        
    }
    .pop-direita h6 {
        margin: -20px 0 10px 0;
        
    }

    .pop-centro {
        display: flex !important;
        align-items: flex-start !important;
        justify-content: center !important;
    }
    .div-centro {
        height: 30px;
        /* background-color: red; */
        padding: 0 30px 0 30px !important;
    }
    .filho-esquerda {
        position: absolute;
        color: #3485ff;
        font-weight: 600;
        float: right;
        margin-top: 2px;
        margin-left: -200px;
    }
    .filho-direita {
        position: absolute;
        color: #3485ff;
        font-weight: 600;
        float: left;
        margin-top: 2px;
        margin-right: -200px;
    }

    .div-principal-road {
        border-left: 4px solid rgb(160, 160, 160);
        /* background-color: #92abff; */
        position: relative;
        width: 1px;
        height: 100%;
        margin-top: 30px;
        margin-left: -45px;
        translate: -50%;
    }




    .road-topo-bg-esquerda {
        float: right; 
        margin: 0 -55px 10px 0;
        /* display: flex;
        justify-content: space-between; */
        padding: 5px 5px 0px 10px;
        width: 330px;
        left: -25px;
        top: 0;
        bottom: 0;
        right: 13px;
        z-index: -999;
        background: #7a7a7a;
        border-radius: 5px 5px 40px 0px;
    } 

    .road-topo-bg-direita {
        float: left; 
        margin: 0 0 10px -25px;
        /* display: flex;
        justify-content: space-between; */
        padding: 5px 5px 0px 10px;
        width: 330px;
        left: -25px;
        top: 0;
        bottom: 0;
        right: 13px;
        z-index: -999;
        background: #7a7a7a;
        border-radius: 5px  5px  0px 40px;
    }  

    .road-topo-bg-esquerda  p {
        float: left; 
        margin: 0 0 5px 10px;         
    }
    .road-topo-bg-direita  p {
        float: right; 
        margin: 0 10px 5px 0;  
    }

    .div-timeline-road {
        min-width: 750px;
        margin-left: -30px;
    }

    .par-roadmap {
        font-size: 1.2rem;
        font-weight: 600;
        color: #226ad6;
        margin: -20px -40px 20px 0;
        text-align: center;
        max-width: 500px;
    }
    .duvida-start {
        font-size: 2.5rem;
        color: #3485ff5e;
        margin-top: -15px;
    }

    .duvida-01 {
        font-size: 1.6rem;
        margin: 10px 5px 0 5px;
        color: #93a42a;
    }
    .duvida-02 {
        font-size: 1.8rem;
        margin: 0 5px 0 5px;
        color: #b32900;
    }
    .duvida-03 {
        font-size: 1.6rem;
        margin: 0 5px 0 5px;
        color: #ecbe13;
    }

</style>